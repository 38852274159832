import React, {useState} from 'react'
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';

export default function Pagination({totalPage, action, firstLastPage, url, currentPage}) {
    const [newpage, setNewPage] = useState(currentPage)
    const navigate = useNavigate()
    const paginationHandler = (event) => {
        const nextPage = event.selected
        setNewPage(currentPage === 0 ? currentPage : nextPage)
        navigate(`${url}?page=${nextPage}`)
    }
    const pageToggle = (target) => {
        setNewPage(target)
        navigate(`${url}?page=${target}`)
    }

    const lastPage = (Number.isInteger(totalPage) ? totalPage : parseInt(totalPage) + 1) - 1
    return (
        <>
            {totalPage > 1 &&
            <button type="button" className='btn btn-outline-secondary' onClick={()=>pageToggle(0)}>
                First
            </button> }
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                containerClassName='pagination'
                pageClassName='page-item'
                pageLinkClassName='page-link'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                activeClassName='active'
                nextClassName='page-item'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextLinkClassName='page-link'
                forcePage={Number(currentPage)}
                pageRangeDisplayed={2}
                pageCount={totalPage}
                previousLabel="<"
                marginPagesDisplayed={0}
                onPageChange={paginationHandler}
            />
            {totalPage > 1 &&
            <button type="button" className='btn btn-outline-secondary' onClick={()=>pageToggle(lastPage)}>
                Last
            </button> }
        </>
    )
}
