import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import GetState from '../../../../../helpers/GetState'
import GetCity from '../../../../../helpers/GetCity'
import InputChip from '../../../../UI/Dashboard/inputChip'


export default function EditLocation() {
    const [infoDetail, setInfoDetail] = useState(null)
    const [states, setStates] = useState(null)
    const [city, setCity] = useState(null)
    const [selectedState, setSelectedState] = useState('')
    const [pocLegalEmail, setPocLegalEmail] = useState([])
    const [pocCrmEmail, setPocCrmEmail] = useState([])
    const [pocFinanceEmail, setPocFinanceEmail] = useState([])
    const urlParams = useParams()

    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
                const stateList = await GetState()
                setStates(stateList)

                const locationData = await Ajax({
                    url: `/setting/getLocation?_id=${urlParams.LocationId}`,
                    token: true
                })
                if(locationData.data.status === 'SUCCESS!'){
                    setInfoDetail(locationData.data.result[0])
                    setSelectedState(locationData.data.result[0].state._id)
                    setPocLegalEmail(locationData.data.result[0]?.pocLegalEmail ?? [])
                    setPocCrmEmail(locationData.data.result[0]?.pocCrmEmail ?? [])
                    setPocFinanceEmail(locationData.data.result[0]?.pocFinanceEmail ?? [])
                    const cityFetch = await GetCity(locationData.data.result[0].state._id)
                    setCity(cityFetch)
                }
            })()
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    //Seelct State for City
    const chooseState = async(e) => {
        var stateId = e.target.value
        setSelectedState(stateId)
        const cityFetch = await GetCity(stateId)
        setCity(cityFetch)
    }

    const updateData = async (values, event) => {
        if(selectedState === ''){
            toast.error('Please select state!',{
                toastId: 'stateError',
                autoClose: 1000
            })
        }
        const data = {
            name: values.title, 
            state: selectedState, 
            city : values.city,
            shortCode:values.shortCode,
            pocCrmEmail:pocCrmEmail ?? [],
            pocFinanceEmail:pocFinanceEmail ?? [],
            pocLegalEmail:pocLegalEmail ?? [],
            active: values.active == 1 ? true : false
        }
        if(values.pocCrmMobile) data.pocCrmMobile = values.pocCrmMobile
        if(values.replyToEmail) data.replyToEmail = values.replyToEmail
        const update = await Ajax({
            url: `/setting/location/${urlParams.LocationId}`,
            method: 'PUT',
            token: true,
            data: data,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Location Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Edit Location">
            <Helmet>
                <title>Edit Location</title>
            </Helmet>
            <DataView title="Infomation">
                {infoDetail === null ? <DataLoader /> :
                <Formik
                    initialValues={{ 
                        city: infoDetail.city ? infoDetail.city._id : '',
                        title: infoDetail.name ? infoDetail.name : '',
                        shortCode: infoDetail.shortCode ? infoDetail.shortCode : '',
                        pocCrmMobile: infoDetail.pocCrmMobile ? infoDetail.pocCrmMobile : '',
                        replyToEmail: infoDetail.replyToEmail ? infoDetail.replyToEmail : '',
                        active: infoDetail.active === true ? 1 : 0
                    }}
                    validationSchema={Yup.object({
                        city: Yup.string()
                        .required('Field is Required'),
                        title: Yup.string()
                        .required('Field is Required'),
                        shortCode: Yup.string()
                        .required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        updateData(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="State" required>
                                <select name="state" className='form-select' value={selectedState} onChange={chooseState}>
                                    <option value="" disabled>Select State</option>
                                    {states === null ? <option value='' disabled>Searching...</option> :  states.map(e=>(
                                        <option key={e._id} value={e._id}>{e.name}</option>
                                    ))}
                                </select>
                                <ErrorField><ErrorMessage name="state"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="City" required>
                                <Field as="select" name="city" className='form-select'>
                                    <option value="" disabled>Select City</option>
                                    {city === null? <option value='' disabled>Select State</option> :  city.map(e=>(
                                            <option key={e._id} value={e._id}>{e.name}</option>
                                    ))}
                                </Field>
                                <ErrorField><ErrorMessage name="city"/></ErrorField>
                            </FormGroup>
                        </div>
                        
                    </div>
                    <div className='row'>
                    <div className='col-md-6'>
                            <FormGroup label="Title" required>
                                <Field placeholder='Title' name="title" className='form-control' />
                                <ErrorField><ErrorMessage name="title"/></ErrorField>
                            </FormGroup>
                    </div>
                    <div className='col-md-6'>
                            <FormGroup label="Short Code" required>
                                <Field placeholder='Short Code' name="shortCode" className='form-control' />
                                <ErrorField><ErrorMessage name="shortCode"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Crm Mobile">
                                <Field placeholder='Crm mobile' name="pocCrmMobile" className='form-control' />
                                <ErrorField><ErrorMessage name="pocCrmMobile"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Reply Email">
                                <Field placeholder='Reply Email' name="replyToEmail" className='form-control' />
                                <ErrorField><ErrorMessage name="replyToEmail"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6' >
                            <FormGroup label="Poc Legal Email">
                            <div className='input-group' style={{border:'solid 1px rgba(0, 0, 0, 0.125)'}}>
                            <InputChip data={pocLegalEmail} action={setPocLegalEmail}/>
                            </div>
                             </FormGroup>
                        </div>
                        <div className='col-md-6' >
                            <FormGroup label="Poc Crm Email">
                            <div className='input-group' style={{border:'solid 1px rgba(0, 0, 0, 0.125)'}}>
                            <InputChip data={pocCrmEmail} action={setPocCrmEmail}/>
                            </div>
                             </FormGroup>
                        </div>
                        <div className='col-md-6' >
                            <FormGroup label="Poc Finance Email">
                            <div className='input-group' style={{border:'solid 1px rgba(0, 0, 0, 0.125)'}}>
                            <InputChip data={pocFinanceEmail} action={setPocFinanceEmail}/>
                            </div>
                             </FormGroup>
                        </div>
                        <div className='col-md-6'>
                        <FormGroup label="Active" required>
                        <Field as="select" type="number" name="active" className='form-select'>
                            <option value={1}>Enable</option>
                            <option value={0}>Disable</option>
                        </Field>
                        <ErrorField><ErrorMessage name="active"/></ErrorField>
                    </FormGroup>
                        </div>
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik> }
            </DataView>
        </ContentLayout>
    )
}
