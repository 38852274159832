import React, { useState } from 'react'
export default function InputChip({ data, action }) {
    const [value, setValue] = useState('');

    const handleEmails = async (index) => {
        action(current =>
            current.filter((data, idx) => {
                return idx !== index;
            })
        )
    }

    return (
        <>
            <div className="chip-container">
                {data && data.map((item, index) => {
                    return (
                        <div class="chip">
                            <p>{item}&nbsp;&nbsp;<i className='fa fa-times ' onClick={() => handleEmails(index)}></i></p>
                        </div>
                    )
                })}
            </div>
            <input placeholder='Poc Email' className='form-control no-hover-effect' value={value} style={{ "border-style": "hidden" }} onChange={(e) => setValue(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && value !== "") {
                        action(current =>
                            [...current, e.target.value]
                        )
                        setValue("")
                        e.preventDefault();
                    };
                    if (e.key === "Tab" && value !== "") {
                        action(current =>
                            [...current, e.target.value]
                        )
                        setValue("")
                    };
                    if (e.key === "Backspace" && value == "") { 
                        action(current =>
                            current.slice(0, -1)
                        )
                    };
                }}
            />
        </>
    )
}