const RandomId = (length = 10, type) => {
    var result           = '';
    var characters
    if(type){
        characters  = '1234567890'
    }else{
        characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()][/~'
    }
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
export default RandomId