import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../UI/Dashboard/DataView'
import Ajax from '../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../UI/Form/FormGroup'
import ErrorField from '../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../UI/Dashboard/DataLoader'
import Button from '../../../UI/Form/Button'
import ContentLayout from '../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import GetBlogType from '../../../../helpers/GetBlogType'
import { CKEditor } from 'ckeditor4-react';

export default function AddNewAward() {
    const [productList,setInfoDetail] = useState(null)
    const urlParams = useParams()
    const navigate = useNavigate()
    const dataIcon = useRef()
    const dataIcon2 = useRef()
    const [blogList, setBlogList] = useState(null)
    const [editorContent, setEditorContent] = useState(null)

    //get property list
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
                const blogTypes = await GetBlogType()
                setBlogList(blogTypes)
            })()
        },100)
      
        return()=>{
            clearInterval(getResponse)
        }
    },[])




    const addProcess = async (values, event) => {
        if(dataIcon.current.value === ''){
            toast.error('Image is Required',{
                toastId: 'iconRequired'
            })
            return false
        }
        const formData = new FormData()
        if(dataIcon.current.value != ''){
            formData.append("file", dataIcon.current.files[0])
        }
        const data = {
            title: values.title, 
            description: values.description, 
            link: values.link, 
            active: values.active == 1 ? true : false, 
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/blog/award`,
            method: 'POST',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Award Added Successfully')
            setTimeout(function(){
                navigate('/admin/awards')
            },500)
        }
    }
    return (
        <ContentLayout title="Add Award">
            <Helmet>
                <title>Add Award</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{ 
                        title: '', 
                        link:'',
                        description: '', 
                        active: '1',
                    }}
                    validationSchema={Yup.object({
                        title: Yup.string().required('Field is Required'),
                       
                    })}
                    onSubmit={(values, event) => {
                        addProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Title" required>
                                <Field placeholder='Title' name="title" className='form-control' />
                                <ErrorField><ErrorMessage name="title"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="External Link (Optional)" >
                                <Field placeholder='Link' name="link" className='form-control' />
                                <ErrorField><ErrorMessage name="link"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-12'>
                            <FormGroup label="Image" required>
                                <input type="file" accept='.jpg, .png, jpeg, .webp' name="nearby_image" ref={dataIcon} className='form-control' />
                            </FormGroup>
                        </div>
                        <div className='col-md-12'>
                            <FormGroup label="Description" required>
                                <Field placeholder='Description' name="description" className='form-control' />
                                <ErrorField><ErrorMessage name="description"/></ErrorField>
                            </FormGroup>
                        </div>
                        <FormGroup label="Active" required>
                            <Field as="select" name="active" className='form-select'>
                                <option value="1">Enable</option>
                                <option value="2">Disable</option>
                            </Field>
                            <ErrorField><ErrorMessage name="type"/></ErrorField>
                        </FormGroup>
                    </div>
                   
                    
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add</Button>
                    </div>
                </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}
