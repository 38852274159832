import React, { useState, useEffect, useRef } from "react";
import DataView from "../../../../UI/Dashboard/DataView";
import Ajax from "../../../../../helpers/Ajax";
import { useParams, useNavigate } from "react-router-dom";
import FormGroup from "../../../../UI/Form/FormGroup";
import ErrorField from "../../../../UI/Form/ErrorField";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DataLoader from "../../../../UI/Dashboard/DataLoader";
import Button from "../../../../UI/Form/Button";
import ContentLayout from "../../../../Common/ContentLayout";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import JourneyAjax from "../../../../../helpers/JourneyAjax";
import GetState from "../../../../../helpers/GetState";
import GetCity from "../../../../../helpers/GetCity";
import axios from "axios";
import CustLoader from "../../../../Common/CustLoader";

export default function AddBroker() {
  const urlParams = useParams();
  const navigate = useNavigate();
  const userValidToken = localStorage.getItem("userToken");
  const dataIcon = useRef();
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [states, setStates] = useState(null);
  const [city, setCity] = useState(null);
  const [cityStates,setCityStates] = useState(null)
  const [aops, setAops] = useState(null);
  const [validDateShow,setValidDateShow] = useState()
  const [ValidTillDate,setValidTillDate] = useState()
  const [relationManager,setRelationManager] = useState([])
  const [selectedRm,setSelectedRm] = useState()
  const aadharRef = useRef(null);
  const panRef = useRef(null);
  const gstRef = useRef(null);
  const cancelledChequeRef = useRef(null);
  const [uploadStatus, setUploadStatus] = useState({});
  const [uploadDocuments, setUploadDocuments] = useState({
    panCardDocUrl: "",
    gstDocUrl: "",
    cancelledChequeDocUrl: "",
    adharCardDocUrl: "",
    adharCardBackDocUrl: ""
  });

 

  const handleDocUpload = async (e, docType) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadStatus((prevStatus) => ({
      ...prevStatus,
      [docType]: "Pending",
    }));

    const formData = new FormData();
    formData.append("file", file);
    // const URL = `https://dev-journey-api.houseeazy.com/images`;
    const URL = `${process.env.REACT_APP_JOURNEY_URL}/images`;
    const reqHeaders = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      const response = await axios.post(URL, formData, reqHeaders);
      const uploadDocRes = response.data[0];

      // Update the appropriate state based on imageType
      if (docType === "panCard") {
        setUploadDocuments((prev) => ({
          ...prev,
          panCardDocUrl: uploadDocRes,
        }));
      } else if (docType === "gst") {
        setUploadDocuments((prev) => ({
          ...prev,
          gstDocUrl: uploadDocRes,
        }));
      } else if (docType === "cancelledCheque") {
        setUploadDocuments((prev) => ({
          ...prev,
          cancelledChequeDocUrl: uploadDocRes,
        }));
      } else if (docType === "adharCard") {
        setUploadDocuments((prev) => ({
          ...prev,
          adharCardDocUrl: uploadDocRes,
        }));
      }else if (docType === "adharCardBack") {
        setUploadDocuments((prev) => ({
          ...prev,
          adharCardBackDocUrl: uploadDocRes,
        }));
      }
      // Set upload status to done
      setUploadStatus((prevStatus) => ({
        ...prevStatus,
        [docType]: "Done",
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const linkBrokerAop = async (data) => {
    const update = await JourneyAjax({
      url: `/brokeraops/admin`,
      "x-api-key": "houseeazy-dev",
      "x-user": userValidToken || null,
      method: "PATCH",
      data: data,
      token: true,
    });
    if (update.data.error) {
      toast.error("Something Went Wrong Please Check");
    }
    if (update.status === 200) {
      toast.success("Aop Linked Successfully");
    }
  };

  const addBroker = async (values, event) => {
    const {
      panCardDocUrl,
      adharCardDocUrl,
      cancelledChequeDocUrl,
      gstDocUrl,
      adharCardBackDocUrl
    } = uploadDocuments;

    const documents = {};
    if (panCardDocUrl) documents.panCard = panCardDocUrl;
    if (adharCardDocUrl) documents.adharCard = adharCardDocUrl;
    if (adharCardBackDocUrl) documents.adharCardBack = adharCardBackDocUrl;
    if (cancelledChequeDocUrl)
      documents.cancelledCheque = cancelledChequeDocUrl;
    if (gstDocUrl) documents.gst = gstDocUrl;
    const data = {
      name: values.name,
      email: values.email,
      mobile: values.mobile,
      firmName: values.firmName,
      firmType: values.firmType,
      linkedRm:selectedRm || "",
      approved: values.approved === "true" ? true : false,
      // otpVerified: values.otpVerified === "true" ? true : false,
      aopSigned: values.aopSigned === "true" ? true : false,
      documents: documents,
      //   shortlistedPropertyId: [values.shortlistedPropertyId],
    };

    if(selectedState&&selectedState.length>0){
      data.state = selectedState
    }
    
    if(selectedCity&& selectedCity.length>0){
      data.city = selectedCity
    } 

    const update = await JourneyAjax({
      url: `/brokers`,
      "x-api-key": "houseeazy-dev",
      "x-user": userValidToken || null,
      method: "POST",
      data: data,
      token: true,
    });
    if (update.data.error) {
      toast.error("Something Went Wrong Please Check");
    }

    console.log("broker add res", update);
    if (update.status === 200) {
      toast.success("Broker Added Successfully");
      if (update.data.aopSigned) {
        const aopData = {
          aopId: values.aopId,
          brokerId: update.data.id,
          value: values.value,
          name: values.brokerAopName,
          status: values.status,
          validTill: ValidTillDate,
        };
        linkBrokerAop(aopData);
      }
      setTimeout(function() {
        navigate("/admin/property-journey/broker");
      }, 500);
    }
  };

  const chooseCity = (e) =>{
    setSelectedCity(e.target.value)
  }
  const chooseState = (e) =>{
    setSelectedState(e.target.value)
    setSelectedCity("")
    for (const [key, value] of Object.entries(cityStates)) {
      if(key===e.target.value){
        setCity(value)
      }
    }
  
  }

  useEffect(() => {
    (async()=>{ 
      let result= await JourneyAjax({
      url: `/appConstants`,
      "x-api-key": "houseeazy-dev",
      token: true,
      });
    setCityStates(result.data.data.cityState)
    setStates(Object.keys(result.data.data.cityState))

    const rmDetails= await JourneyAjax({
      url: `/relation-managers`,
      "x-api-key": "houseeazy-dev",
      "x-user": userValidToken || null,
      token: true,
      });
      if(rmDetails && rmDetails.data && rmDetails.data.data && rmDetails.data.data.relationManagers && rmDetails.data.data.relationManagers.length > 0){
        setRelationManager(rmDetails.data.data.relationManagers)
      }
  }
 
)()

    const getResponse = setTimeout(function() {
      (async () => {
        const response = await JourneyAjax({
          url: `/aops`,
          "x-api-key": "houseeazy-dev",
          "x-user": userValidToken || null,
          token: true,
        });

        if (response && response.status === 200) {
          setAops(response.data.data.aops);
        }
      })();
    }, 100);
    return () => {
      clearInterval(getResponse);
    };
  }, []);

  function isPDFUrl(url) {
    if (typeof url === "string") {
      return url && url.toLowerCase().endsWith(".pdf");
    }
  }

  const handleRelationManager = (e) =>{
       setSelectedRm(e.target.value)
  }
  const handleValidTillDate = (selectedAop) =>{
    let validDate = new Date()
    let formattedDate = new Date()
    if(selectedAop && selectedAop.validTill){
       validDate = new Date(selectedAop.validTill); 
       formattedDate = validDate.toISOString().split('T')[0];
    }else{
    const currentYear = new Date().getFullYear();
    // Get the last date of the current year
     validDate = new Date(currentYear,12,0,23,59,59); // Month is zero-based index, so 11 is December
     formattedDate = validDate.toISOString().split('T')[0];
  }
    setValidDateShow(formattedDate)
    setValidTillDate(validDate)
  }

  return (
    <ContentLayout title="Add New Broker">
      <Helmet>
        <title>Add New Broker</title>
      </Helmet>
      <DataView title="Infomation">
        <Formik
          initialValues={{
            name: "",
            email: "",
            mobile: "",
            firmName: "",
            firmType: "",
            city: "",
            state: "",
            approved: false,
            otpVerified: false,
            aopSigned: false,
            aopId: "",
            validTill:"",
            status: "active",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Broker Name is required"),
            email: Yup.string()
              .email("Invalid email")
              .nullable(),
            mobile: Yup.string()
              .required("Mobile Number is required"),
            firmName: Yup.string().nullable(),
            firmType: Yup.string().required("Firm Type is required"),
            approved: Yup.boolean().required("Approved is required"),
            aopSigned: Yup.boolean().required("AOP Signed is required"),
            panCard: Yup.mixed().nullable(),
            adharCard: Yup.mixed().nullable(),
            adharCardBack: Yup.mixed().nullable(),
            gst: Yup.mixed().nullable(),
            cancelledCheque: Yup.mixed().nullable(),
            state: Yup.string().nullable(),
            city: Yup.string().nullable(),
            aopId: Yup.string().when("aopSigned", {
              is: true,
              then: Yup.string().required(
                "AOP ID is required when AOP Signed is true"
              ),
              otherwise: Yup.string().nullable(),
            }),
            validTill: Yup.date().nullable().when('aopId', {
              is: aopId => !!aopId, // Only when aopId is selected
              // then: Yup.date().required('Valid Till is required').typeError('Valid Till must be a date'),
              otherwise: Yup.date().nullable(),
            }),
          })}
          onSubmit={(values, event) => {
            addBroker(values, event);
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="row">
              
                <div className="col-md-6">
                  <FormGroup label="Broker Name" required>
                    <Field
                      placeholder="Broker Name"
                      name="name"
                      className="form-control"
                    />
                    <ErrorField>
                      <ErrorMessage name="name" />
                    </ErrorField>
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <FormGroup label="Email">
                    <Field
                      placeholder="Email"
                      type="email"
                      name="email"
                      className="form-control"
                    />
                    <ErrorField>
                      <ErrorMessage name="email" />
                    </ErrorField>
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <FormGroup label="Mobile" required>
                    <Field
                      placeholder="Mobile Number"
                      type="string"
                      name="mobile"
                      className="form-control"
                    />
                    <ErrorField>
                      <ErrorMessage name="mobile" />
                    </ErrorField>
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <FormGroup label="Firm Name">
                    <Field
                      placeholder="Firm Name"
                      name="firmName"
                      className="form-control"
                    />
                    <ErrorField>
                      <ErrorMessage name="firmName" />
                    </ErrorField>
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Approved" required>
                    <Field as="select" name="approved" className="form-select">
                      <option value={false}>False</option>
                      <option value={true}>True</option>
                    </Field>
                    <ErrorField>
                      <ErrorMessage name="approved" />
                    </ErrorField>
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup label="Firm Type" required>
                    <Field
                      as="select"
                      placeholder="Firm Type"
                      name="firmType"
                      className="form-select"
                    >
                      <option value={""}>Select</option>
                      <option value={"individual"}>Individual</option>
                      <option value={"Registered Firm"}>Registered Firm</option>
                      {/* <option value={"proprietorship"}>Proprietorship</option>
                      <option value={"partnership"}>Partnership</option>
                      <option value={"company"}>Company</option>
                      <option value={"limitedLiabilityPartners"}>
                        Limited Liability Partners
                      </option> */}
                    </Field>
                    <ErrorField>
                      <ErrorMessage name="firmType" />
                    </ErrorField>
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <FormGroup label="Relation Manager" required>
                  <select name="relationManager"  className='form-select' value={selectedRm} onChange={handleRelationManager}>
                            <option value="" >Select</option>
                            {relationManager === null ? <option disabled>Loading</option> : relationManager.map((data)=>(
                            <option value={data.id}>{`${data.name || ""} - ${data.mobile || ""}`}</option>
                           ))}
                    </select>
                    <ErrorField>
                      <ErrorMessage name="relationManager" />
                    </ErrorField>
                  </FormGroup>
                </div>
                {/* <div className="col-md-6">
                  <FormGroup label="Rera Number">
                    <Field
                      placeholder="Rera Number"
                      name="reraNumber"
                      className="form-control"
                    />
                    <ErrorField>
                      <ErrorMessage name="reraNumber" />
                    </ErrorField>
                  </FormGroup>
                </div> */}

                {/* <div className="col-md-6">
                  <FormGroup label="Approved" required>
                    <Field as="select" name="approved" className="form-select">
                      <option value={false}>False</option>
                      <option value={true}>True</option>
                    </Field>
                    <ErrorField>
                      <ErrorMessage name="approved" />
                    </ErrorField>
                  </FormGroup>
                </div> */}
                {/* <div className="col-md-6">
                  <FormGroup label="OTP Verified" required>
                    <Field
                      as="select"
                      name="otpVerified"
                      className="form-select"
                    >
                      <option value={false}>False</option>
                      <option value={true}>True</option>
                    </Field>
                    <ErrorField>
                      <ErrorMessage name="otpVerified" />
                    </ErrorField>
                  </FormGroup>
                </div> */}

                <div className="col-md-6">
                  <FormGroup label="AOP Signed">
                    <Field as="select" name="aopSigned" onChange={(e)=>{setFieldValue("aopSigned",e.target.value); setFieldValue("aopId","");}} className="form-select">
                      <option value={false}>False</option>
                      <option value={true}>True</option>
                    </Field>
                    <ErrorField>
                      <ErrorMessage name="aopSigned" />
                    </ErrorField>
                  </FormGroup>
                </div>
                {((values.aopSigned === "true") || (values.aopSigned === true)) ? (
                  <div className="col-md-6">
                    <FormGroup label="AOP">
                      <Field
                        as="select"
                        name="aopId"
                        onChange={(e) => {
                          setFieldValue("aopId", e.target.value);
                          let selectedAop = aops.find(
                            (item) => item.id === e.target.value
                          );
                          setFieldValue("value", selectedAop&&selectedAop.value);
                          setFieldValue("brokerAopName", selectedAop&&selectedAop.name);
                          setFieldValue("status", selectedAop&&selectedAop.status);
                          handleValidTillDate(selectedAop)
                        }}
                        className="form-select"
                      >
                        <option value={""}>Select</option>
                        {aops.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Field>

                      <ErrorField>
                        <ErrorMessage name="aopId" />
                      </ErrorField>
                    </FormGroup>
                  </div>
                ) : (
                  ""
                )}
                {((values.aopSigned === "true") || (values.aopSigned === true))&&values.aopId ? (
                  <div className="col-md-12 mt-3">
                    <h3>Broker Aop Details</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup label="Name">
                          <Field
                            placeholder="name"
                            name="brokerAopName"
                            className="form-control"
                          />
                          <ErrorField>
                            <ErrorMessage name="brokerAopName" />
                          </ErrorField>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup label="Value">
                          <Field
                            type="number"
                            placeholder="value"
                            name="value"
                            className="form-control"
                          />
                          <ErrorField>
                            <ErrorMessage name="value" />
                          </ErrorField>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup label="Status">
                          <Field
                            as="select"
                            name="status"
                            className="form-select"
                          >
                            <option value={"active"}>Active</option>
                            <option value={"inactive"}>InActive</option>
                          </Field>
                          <ErrorField>
                            <ErrorMessage name="status" />
                          </ErrorField>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup label="Valid Till" required>
                          <Field
                            type="date"
                            placeholder="Valid Till"
                            name="validTill"
                            className="form-control"
                            value={validDateShow}
                            onChange={(e)=>{
                              const lastDateOfYear = new Date(e.target.value);
                              const formattedDate = lastDateOfYear.toISOString().split('T')[0];
                              setValidTillDate(lastDateOfYear)
                              setValidDateShow(formattedDate)
                            }}
                          />
                          <ErrorField>
                            <ErrorMessage name="validTill" />
                          </ErrorField>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div>
                  <FormGroup label="PAN">
                    <div className="custom-file-upload-wrapper">
                      <span>Choose File</span>
                      <label htmlFor="panCard" className="custom-file-upload">
                        {uploadDocuments.panCardDocUrl
                          ? uploadDocuments.panCardDocUrl
                          : "No File Chosen"}
                      </label>
                      <div className="custom-file-preview">
                        {uploadDocuments && (
                          <a
                            href={
                              uploadDocuments.panCardDocUrl
                                ? uploadDocuments.panCardDocUrl
                                : ""
                            }
                            target="_blank"
                          >
                            {uploadStatus.panCard === "Pending" && (
                              <CustLoader />
                            )}
                            {!isPDFUrl(
                              uploadDocuments.panCardDocUrl
                                ? uploadDocuments.panCardDocUrl
                                : ""
                            ) ? (
                              <img
                                src={
                                  uploadDocuments.panCardDocUrl
                                    ? uploadDocuments.panCardDocUrl
                                    : ""
                                }
                                width={200}
                              />
                            ) : (
                              <img
                                src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                width={40}
                              />
                            )}
                          </a>
                        )}
                      </div>
                    </div>

                    <input
                      id="panCard"
                      type="file"
                      name="panCard"
                      onChange={(e) => handleDocUpload(e, "panCard")}
                      style={{ display: "none" }}
                      accept=".jpg, .jpeg, .png, .webp, application/pdf"
                    />
                    <ErrorField>
                      <ErrorMessage name="token_money_invoice" />
                    </ErrorField>
                  </FormGroup>
                </div>

                <div>
                  <FormGroup label="Adhaar">
                    <div className="custom-file-upload-wrapper">
                      <span>Choose File</span>
                      <label htmlFor="adharCard" className="custom-file-upload">
                        {uploadDocuments.adharCardDocUrl
                          ? uploadDocuments.adharCardDocUrl
                          : "No File Chosen"}
                      </label>
                      <div className="custom-file-preview">
                        {uploadDocuments && (
                          <a
                            href={
                              uploadDocuments.adharCardDocUrl
                                ? uploadDocuments.adharCardDocUrl
                                : ""
                            }
                            target="_blank"
                          >
                            {uploadStatus.adharCard === "Pending" && (
                              <CustLoader />
                            )}

                            {!isPDFUrl(
                              uploadDocuments.adharCardDocUrl
                                ? uploadDocuments.adharCardDocUrl
                                : ""
                            ) ? (
                              <img
                                src={
                                  uploadDocuments.adharCardDocUrl
                                    ? uploadDocuments.adharCardDocUrl
                                    : ""
                                }
                                width={200}
                              />
                            ) : (
                              <img
                                src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                width={40}
                              />
                            )}
                          </a>
                        )}
                      </div>
                    </div>
                    <input
                      id="adharCard"
                      type="file"
                      name="adharCard"
                      onChange={(e) => handleDocUpload(e, "adharCard")}
                      style={{ display: "none" }}
                      accept=".jpg, .jpeg, .png, .webp, application/pdf"
                    />
                    <ErrorField>
                      <ErrorMessage name="adharCard" />
                    </ErrorField>
                  </FormGroup>
                </div>
                <div>
                  <FormGroup label="Adhaar card back">
                    <div className="custom-file-upload-wrapper">
                      <span>Choose File</span>
                      <label htmlFor="adharCardBack" className="custom-file-upload">
                        {uploadDocuments.adharCardBackDocUrl
                          ? uploadDocuments.adharCardBackDocUrl
                          : "No File Chosen"}
                      </label>
                      <div className="custom-file-preview">
                        {uploadDocuments && (
                          <a
                            href={
                              uploadDocuments.adharCardBackDocUrl
                                ? uploadDocuments.adharCardBackDocUrl
                                : ""
                            }
                            target="_blank"
                          >
                            {uploadStatus.adharCardBack === "Pending" && (
                              <CustLoader />
                            )}

                            {!isPDFUrl(
                              uploadDocuments.adharCardBackDocUrl
                                ? uploadDocuments.adharCardBackDocUrl
                                : ""
                            ) ? (
                              <img
                                src={
                                  uploadDocuments.adharCardBackDocUrl
                                    ? uploadDocuments.adharCardBackDocUrl
                                    : ""
                                }
                                width={200}
                              />
                            ) : (
                              <img
                                src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                width={40}
                              />
                            )}
                          </a>
                        )}
                      </div>
                    </div>
                    <input
                      id="adharCardBack"
                      type="file"
                      name="adharCardBack"
                      onChange={(e) => handleDocUpload(e, "adharCardBack")}
                      style={{ display: "none" }}
                      accept=".jpg, .jpeg, .png, .webp, application/pdf"
                    />
                    <ErrorField>
                      <ErrorMessage name="adharCardBack" />
                    </ErrorField>
                  </FormGroup>
                </div>
                <div>
                  <FormGroup label="GST">
                    <div className="custom-file-upload-wrapper">
                      <span>Choose File</span>
                      <label htmlFor="gst" className="custom-file-upload">
                        {uploadDocuments.gstDocUrl
                          ? uploadDocuments.gstDocUrl
                          : "No File Chosen"}
                      </label>
                      <div className="custom-file-preview">
                        {uploadDocuments && (
                          <a
                            href={
                              uploadDocuments.gstDocUrl
                                ? uploadDocuments.gstDocUrl
                                : ""
                            }
                            target="_blank"
                          >
                            {uploadStatus.gst === "Pending" && <CustLoader />}

                            {!isPDFUrl(
                              uploadDocuments.gstDocUrl
                                ? uploadDocuments.gstDocUrl
                                : ""
                            ) ? (
                              <img
                                src={
                                  uploadDocuments.gstDocUrl
                                    ? uploadDocuments.gstDocUrl
                                    : ""
                                }
                                width={200}
                              />
                            ) : (
                              <img
                                src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                width={40}
                              />
                            )}
                          </a>
                        )}
                      </div>
                    </div>
                    <input
                      id="gst"
                      type="file"
                      name="gst"
                      onChange={(e) => handleDocUpload(e, "gst")}
                      style={{ display: "none" }}
                      accept=".jpg, .jpeg, .png, .webp, application/pdf"
                    />
                    <ErrorField>
                      <ErrorMessage name="gst" />
                    </ErrorField>
                  </FormGroup>
                </div>
                <div>
                  <FormGroup label="Cancelled Cheque">
                    <div className="custom-file-upload-wrapper">
                      <span>Choose File</span>
                      <label
                        htmlFor="cancelledCheque"
                        className="custom-file-upload"
                      >
                        {uploadDocuments.cancelledChequeDocUrl
                          ? uploadDocuments.cancelledChequeDocUrl
                          : "No File Chosen"}
                      </label>
                      <div className="custom-file-preview">
                        {uploadDocuments && (
                          <a
                            href={
                              uploadDocuments.cancelledChequeDocUrl
                                ? uploadDocuments.cancelledChequeDocUrl
                                : ""
                            }
                            target="_blank"
                          >
                            {uploadStatus.cancelledCheque === "Pending" && (
                              <CustLoader />
                            )}

                            {!isPDFUrl(
                              uploadDocuments.cancelledChequeDocUrl
                                ? uploadDocuments.cancelledChequeDocUrl
                                : ""
                            ) ? (
                              <img
                                src={
                                  uploadDocuments.cancelledChequeDocUrl
                                    ? uploadDocuments.cancelledChequeDocUrl
                                    : ""
                                }
                                width={200}
                              />
                            ) : (
                              <img
                                src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                                width={40}
                              />
                            )}
                          </a>
                        )}
                      </div>
                    </div>
                    <input
                      id="cancelledCheque"
                      type="file"
                      name="cancelledCheque"
                      onChange={(e) => handleDocUpload(e, "cancelledCheque")}
                      style={{ display: "none" }}
                      accept=".jpg, .jpeg, .png, .webp, application/pdf"
                    />
                    <ErrorField>
                      <ErrorMessage name="cancelledCheque" />
                    </ErrorField>
                  </FormGroup>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup label="State">
                      <select
                        name="state"
                        className="form-select"
                        value={selectedState}
                        onChange={chooseState}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {states === null ? (
                          <option disabled>Loading</option>
                        ) : (
                          states.map((data) => (
                            <option key={data} value={data}>
                              {data}
                            </option>
                          ))
                        )}
                      </select>
                      <ErrorField>
                        <ErrorMessage name="state" />
                      </ErrorField>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <FormGroup label="City">
                      <select
                        name="city"
                        value={selectedCity}
                        className="form-select"
                        onChange={chooseCity}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        {city === null ? (
                          <option >Select State</option>
                        ) : (
                          city.map((data) => (
                            <option key={data} value={data}>
                              {data}
                            </option>
                          ))
                        )}
                      </select>
                      <ErrorField>
                        <ErrorMessage name="city" />
                      </ErrorField>
                    </FormGroup>
                  </div>
                </div>
              </div>

              <div className="col-md-2 d-grid">
                <Button
                  type="submit"
                  // onclick={() => {
                  //   window.scrollTo({ top: 0, behavior: "smooth" });
                  // }}
                >
                  Add
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DataView>
    </ContentLayout>
  );
}
