import React from 'react'
import CounterBlock from '../../UI/Dashboard/CounterBlock'
import { Helmet } from 'react-helmet-async'
import ContentLayout from '../../Common/ContentLayout'
export default function Dashboard() {
    return (
        <ContentLayout title="Dashboard">
        <Helmet>
            <title>Dashboard</title>
        </Helmet>
        <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6">
                <CounterBlock score="95" url="#" icon="fa-shopping-cart">
                    Total Orders
                </CounterBlock>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
                <CounterBlock score="92.8K" url="#" icon="fa-credit-card">
                    Total Sales
                </CounterBlock>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
                <CounterBlock score="5.7K" url="#" icon="fa-user">
                    Total Customers
                </CounterBlock>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6">
                <CounterBlock score="5.7K" url="#" icon="fa-users">
                    People Online
                </CounterBlock>
            </div>
        </div>
        </ContentLayout>
    )
}
