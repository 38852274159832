import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../UI/Dashboard/DataView'
import Ajax from '../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../UI/Form/FormGroup'
import ErrorField from '../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../UI/Dashboard/DataLoader'
import Button from '../../../UI/Form/Button'
import ContentLayout from '../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AmenityDetail() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()
    const dataIcon = useRef()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/team?_id=${urlParams.TeamId}`,
                token: true
              })
              console.log(response)
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }


           
            })()
          },100)    
        
         
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateProcess = async (values, event) => {
        const formData = new FormData()
        if(dataIcon.current.value != ''){
            formData.append("file", dataIcon.current.files[0])
        }
        const data = {
            name: values.name, 
            designation:values.designation,
            linkedin:values.linkedin,
            active: values.active == 1 ? true : false, 
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/team/${urlParams.TeamId}`,
            method: 'PUT',
            token: true,
            data: formData,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Team Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Edit Team">
            <Helmet>
                <title>Edit Team</title>
            </Helmet>
            <DataView title="Infomation">
              
            {infoDetail === null   ? <DataLoader/> : 
                <Formik
               
                    initialValues={{ 
                        name: infoDetail.name ? infoDetail.name : '', 
                        designation: infoDetail.designation ? infoDetail.designation: '', 
                        linkedin: infoDetail.linkedin ? infoDetail.linkedin : '', 
                        active: infoDetail.active === true ? 1 : 2
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required('Field is Required'),
                        designation: Yup.string().required('Field is Required'),
                        linkedin: Yup.string().required('Field is Required'),
                        active: Yup.string().required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        updateProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'> 
                    
                    <div className='col-md-6'>
                            <FormGroup label="Name" required>
                                <Field placeholder='Name' name="name" className='form-control' />
                                <ErrorField><ErrorMessage name="name"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Designation" required>
                                <Field placeholder='Designation' name="designation" className='form-control' />
                                <ErrorField><ErrorMessage name="designation"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Linkedin" required>
                                <Field placeholder='Linkedin' name="linkedin"  className='form-control' />
                                <ErrorField><ErrorMessage name="linkedin"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                        <FormGroup label="Active" required>
                        <Field as="select" name="active" className='form-select'>
                            <option value="1">Enable</option>
                            <option value="2">Disable</option>
                        </Field>
                        <ErrorField><ErrorMessage name="type"/></ErrorField>
                    </FormGroup>
                    </div>
                        <div className='col-md-6'>
                        <img src={infoDetail.files.path} alt="prcess icon" height="100" width="100" className='img-fluid' /> 
                            <FormGroup label=" Image" required>
                            <input type="file" accept='.jpg, .png, jpeg, .webp' name="amenity_image" ref={dataIcon} className='form-control' />
                            </FormGroup>
                        </div>

                       
                   
                    </div>
                   
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik> }
            </DataView>
        </ContentLayout>
    )
}
