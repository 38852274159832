import React, { useEffect, useState } from "react";
import TableLoader from "../../../../UI/Dashboard/TableLoader";
import DataView from "../../../../UI/Dashboard/DataView";
import TableContainer from "../../../../UI/Dashboard/TableContainer";
import { Helmet } from "react-helmet-async";
import ContentLayout from "../../../../Common/ContentLayout";
import EditButton from "../../../../UI/Dashboard/EditButton";
import DeleteButton from "../../../../UI/Dashboard/DeleteButton";
import JourneyAjax from "../../../../../helpers/JourneyAjax";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Popup from "../../../../UI/Dashboard/Popup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormGroup from "../../../../UI/Form/FormGroup";
import ErrorField from "../../../../UI/Form/ErrorField";
import Button from "../../../../UI/Form/Button";
import DataLoader from "../../../../UI/Dashboard/DataLoader";
import PaginationContainer from "../../../../UI/Dashboard/PaginationContainer";
import Pagination from "../../../../UI/Dashboard/Pagination";

export default function RMList() {
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [userToken, setUserToken] = useState(null);
  const [dataList, setDataList] = useState(null);
  const [loader, setLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [totalRow, setTotalRows] = useState(null);
  const [searchName, setSearchName] = useState();
  const [currentPage, setCurrentPage] = useState(searchParams.get("page"));
  const userValidToken = localStorage.getItem("userToken");
  const currentPerPage = 10;

  const userValid = async (values, event) => {
    const data = {
      ...(values.slug && { slug: values.slug }),
      username: values.username,
    };

    const formData = new FormData();

    for (var key in data) {
      formData.append(key, data[key]);
    }

    const checkUser = await JourneyAjax({
      url: `/admin/${data.username}`,
      "x-api-key": "houseeazy-dev",
      "x-user": data.username,
      token: true,
    });

    if (checkUser && checkUser.status === 200) {
      toast.success("Continue your journey");
      setUserToken(checkUser);
      localStorage.setItem("userToken", checkUser.data.username);
      // setTimeout(function() {
      setOpen(false);
      // }, 1000);
    }

    if (checkUser && checkUser.status === 403) {
      toast.error("Username is not valid");
    }
  };

  useEffect(() => {
    const skip =
      searchParams.get("page") === 1
        ? 10
        : searchParams.get("page") * currentPerPage;
    const getResponse = setTimeout(function() {
      (async () => {
        setLoader(true);
        const response = await JourneyAjax({
          url: `/relation-managers?filter={"offset": ${skip},"limit": ${currentPerPage},"order":["createdAt DESC"]}`,
          "x-api-key": "houseeazy-dev",
          "x-user": userValidToken || null,
          token: true,
        });
        if (response && response.status === 200) {
          setDataList(response.data.data.relationManagers);
          setTotalCount(response.data.data.totalCount);
          setTotalRows(response.data.data.totalCount / 10);
          setCurrentPage(searchParams.get("page"));
          setLoader(false);
        }
      })();
    }, 100);
    return () => {
      clearInterval(getResponse);
    };
  }, [searchParams.get("page")]);

  const deleteRecord = async (id) => {
    try{
      let confirm = window.confirm("Are you sure want to delete this record ?");
      if(confirm){
      await JourneyAjax({
        url: `/relation-managers/${id}`,
        "x-api-key": "houseeazy-dev",
        "x-user": userValidToken,
        method:"DELETE",
        token: true,
        loader:true
      });
        toast.success('Relation manager deleted successfully',{
          autoClose: 1000
        })
        setTimeout(function(){
          window.location.reload()
        },500)
      }else{
        return false
      }
    }catch(error){
     console.log(error)
     toast.error('something went wrong',{
      autoClose: 1000
    })
    }
    
  }

  const rmFilter = async (name) =>{
    let filter = {}
    const skip =
      searchParams.get("page") === 1
        ? 10
        : searchParams.get("page") * currentPerPage;
    if(searchName !== ""){
      filter=`{"offset": ${skip},"limit": ${currentPerPage},"order":["createdAt DESC"]}`
    }else{
      filter=`{"where":{"name":{"like":${JSON.stringify(searchName)},"options":"i"}},"offset": ${skip},"limit": ${currentPerPage},"order":["createdAt DESC"]}`
    }
    const response = await JourneyAjax({
      url: `/relation-managers?filter={"where":{"name":{"like":${JSON.stringify(searchName)},"options":"i"}},"offset": ${skip},"limit": ${currentPerPage},"order":["createdAt DESC"]}`,
      "x-api-key": "houseeazy-dev",
      "x-user": userValidToken || null,
      token: true,
      loader:true
    });
    if (response && response.status === 200) {
      setDataList(response.data.data.relationManagers);
      setTotalCount(response.data.data.totalCount);
      setTotalRows(response.data.data.totalCount / 10);
      setCurrentPage(searchParams.get("page"));
      setLoader(false);
    }
  }
  return (
    <>
      <ContentLayout
        title="Relation Managers"
        addNew="/admin/property-journey/relation-manager/add-rm"
      >
        <div className="row">
          <Helmet>
            <title>Relation Managers</title>
          </Helmet>
              <div className='row'>
                  <div className='col-md-6'>
              <FormGroup label="">
                <input type="text" name="search" id="search" onKeyDown={(e) => {
                  if (e.key === "Enter")
                    rmFilter();
                  }}
                  onChange={(e) => setSearchName(e.target.value)} placeholder="relation manager" className="form-control" />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <button type='button' onClick={rmFilter} style={{ "paddingInline": "40px" }} className="btn btn-primary"><i className="fas fa-filter"></i> Filter</button>
              </FormGroup>
                  </div>
              </div>
          {
            loader? (<DataLoader/>):(<DataView title="Relation Managers List">
            <TableContainer>
              <thead>
                <tr>
                  <td className="text-center">S.No</td>
                  <td className="text-start">Name</td>
                  <td className="text-center">Email</td>
                  <td className="text-center">Mobile</td>

                  <td className="text-center">Edit</td>
                  <td className="text-center">Delete</td>
                </tr>
              </thead>
              <tbody>
                {dataList == null ? (
                  <TableLoader cols={6} />
                ) : dataList.length === 0 ? (
                  <tr>
                    <td colSpan={6} className="text-center">
                      Data not available
                    </td>
                  </tr>
                ) : (
                  dataList.map((data, index) => (
                    <tr key={data.id}>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-start text-capitalize">
                        {data.name}
                      </td>
                      <td className="text-center">{data.email}</td>
                      <td className="text-center">{data.mobile}</td>
                      <td className="text-center">
                        {userValidToken ? (
                          <EditButton>{`/admin/property-journey/relation-manager/${data.id}`}</EditButton>
                        ) : (
                          <div
                            className="btn btn-primary"
                            onClick={() => setOpen(true)}
                          >
                            <i className="fas fa-pencil-alt"></i>
                          </div>
                        )}
                      </td>
                      <td className="text-center">
                      {userValidToken ? (
                          <button type="button" onClick={()=>deleteRecord(data.id)} className="btn btn-danger"><i className="fas fa-trash"></i></button>
                        ) : (
                          <div
                            className="btn btn-danger"
                            onClick={() => setOpen(true)}
                          >
                            <i className="fas fa-trash"></i>
                          </div>
                        )}
                        </td>
                    </tr>
                  ))
                )}
              </tbody>
            </TableContainer>
            <PaginationContainer count={totalCount}>
                <Pagination
                  totalPage={totalRow}
                  url="/admin/property-journey/relation-manager"
                  currentPage={searchParams.get("page")}
                />
              </PaginationContainer>
          </DataView>)
          }
        </div>
      </ContentLayout>
      <Popup open={open} close={setOpen}>
        <Formik
          initialValues={{
            username: "",
          }}
          onSubmit={(values, event) => {
            userValid(values, event);
          }}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <FormGroup label="Username" required>
                <Field
                  placeholder="Username"
                  name="username"
                  className="form-control"
                />
                <ErrorField>
                  <ErrorMessage name="username" />
                </ErrorField>
              </FormGroup>

              <div className="d-grid">
                <Button type="submit">Enter User ID</Button>
              </div>
            </Form>
          )}
        </Formik>
      </Popup>
    </>
  );
}
