import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../UI/Dashboard/DataView'
import Ajax from '../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../UI/Form/FormGroup'
import ErrorField from '../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../UI/Dashboard/DataLoader'
import Button from '../../../UI/Form/Button'
import ContentLayout from '../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AddNewAmenity() {
    const [productList,setInfoDetail] = useState(null)
    const urlParams = useParams()
    const navigate = useNavigate()
    const dataIcon = useRef()

    //get property list
    useEffect(()=>{
        const getResponse = setTimeout(function(){
      
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])




    const addProcess = async (values, event) => {
       
        const data = {
            description: values.description, 
            heading : values.heading , 
            type : values.type, 
            active: values.active == 1 ? true : false, 
        }
       
        const update = await Ajax({
            url: `/faq`,
            method: 'POST',
            token: true,
            data: data,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Faq Added Successfully')
            setTimeout(function(){
                navigate('/admin/faq')
            },500)
        }
    }
    return (
        <ContentLayout title="Add Faq">
            <Helmet>
                <title>Add Faq</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{ 
                        description:'',
                        heading:'',
                        active: '1',
                    }}
                    validationSchema={Yup.object({
                        description: Yup.string().required('Field is Required'),
                        heading: Yup.string().required('Field is Required'),
                        type: Yup.string().required('Field is Required'),
                       
                    })}
                    onSubmit={(values, event) => {
                        addProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                   
                        <div className='col-md-6'>
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="heading" className='form-control' />
                                <ErrorField><ErrorMessage name="heading"/></ErrorField>
                            </FormGroup>
                        </div>
                      
                      
                        <div className='col-md-6'>
                            <FormGroup label="Type" required>
                                <Field as="select" name="type"  className='form-select' >
                                <option value='' disabled>Select Type</option>
                                <option value='SELLER'>Seller</option>
                                <option value='BUYER'>Buyer</option>
                                <option value='HOME-LOAN'>VAS - Home Loan</option>
                                <option value='HOME-INTERIOR'>VAS - Home Interior</option>
                                <option value='UNDER-CONTRUCTION'>VAS - Under Construction</option>
                                <option value='BEST-AREA'>VAS - Best Area</option>
                             
                                </Field>
                                <ErrorField><ErrorMessage name="type"/></ErrorField>
                            </FormGroup>
                        </div>
                       
                    </div>
                    <div className='col-md-12'>
                            <FormGroup label="Description" required>
                                <Field as="textarea" name="description"  className='form-control' />
                                <ErrorField><ErrorMessage name="description"/></ErrorField>
                            </FormGroup>
                        </div>
                    
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add</Button>
                    </div>
                </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}
