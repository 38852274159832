import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../../UI/Form/FormGroup'
import ErrorField from '../../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../../UI/Dashboard/DataLoader'
import Button from '../../../../../UI/Form/Button'
import ContentLayout from '../../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AddHomefestVideo() {
    const urlParams = useParams()
    const navigate = useNavigate()

    const dataIcon = useRef()
    const addProcess = async (values, event) => {
        //const formData = new FormData()
        const data = {
            title: values.title, 
            youtube: values.youtube, 
            sortOrder: `${values.sortOrder}`,
            active: values.active == 1 ? true : false, 
        }
        // for(var key in data) {
        //     if(data[key] !== "") {
        //         formData.append(key, data[key])
        //     }
        // }
        const update = await Ajax({
            url: `/landingPage/review`,
            method: 'POST',
            token: true,
            data: data,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Data Added Successfully')
            setTimeout(function(){
                navigate('/admin/page/homefest/videos')
            },500)
        }
    }
    return (
        <ContentLayout title="Add New Video">
            <Helmet>
                <title>Add New Video</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{ 
                        title: '', 
                        youtube: '', 
                        sortOrder: 1, 
                        active: '1',
                    }}
                    validationSchema={Yup.object({
                        title: Yup.string()
                        .required('Field is Required'),
                        youtube: Yup.string()
                        .required('Field is Required'),
                        active: Yup.string()
                        .required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        addProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Title" required>
                                <Field placeholder='Title' name="title" className='form-control' />
                                <ErrorField><ErrorMessage name="title"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Youtube Id" required>
                                <Field placeholder='Youtube Id' name="youtube" className='form-control' />
                                <ErrorField><ErrorMessage name="youtube"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Sort Order" required>
                                <Field type="number" placeholder='Sort Order' name="sortOrder" className='form-control' />
                                <ErrorField><ErrorMessage name="sortOrder"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Active" required>
                                <Field as="select" name="active" className='form-select'>
                                    <option value="1">Enable</option>
                                    <option value="2">Disable</option>
                                </Field>
                                <ErrorField><ErrorMessage name="type"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add</Button>
                    </div>
                </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}
