export function findActiveKey(data) {
    const arr = Object.entries(data)
        .filter(([key, value]) => value && value.order)
        .map(([key, value]) => ({ parentKey: key, data: value }))
        .sort((a, b) => a.data.order - b.data.order);

    const foundItem = arr.find((item) => {
        if (
            item.parentKey === "house_inspection" &&
            item.data.status === "completed"
        ) {
            return item.data.verdict.status !== "completed";
        }
        return item.data.status !== "completed";
    });
    return foundItem ? foundItem.parentKey : "No key found";
}


// function to update the status -->> pending to inprogress || inprogress to completed

export function updateStatus(data) {
    for (let key in data) {
      if (data.hasOwnProperty(key) && data[key] && typeof data[key] === 'object' && data[key].hasOwnProperty('status')) {
        const status = data[key].status;
        if (status === 'pending') {
          data[key].status = 'inprogress';
        }
        else if (status === 'inprogress') {
            data[key].status = 'completed';
        }
      }
    }
    return data;
  }



