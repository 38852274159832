import axios from "axios"
import GetCookie from "./GetCookie";
const JourneyAjax = async (action) => {
    if(action.loader === true) { document.querySelector(".form-loader").classList.add("show") }
    try {
        const resp = await axios({
            headers: {
                ...(action.token === true && {'Authorization': `Bearer ${GetCookie('token')}`}),
                ...(action.token2) && {'Authorization': `Bearer ${action.token2}`},
                ...(action['x-api-key'] && {'x-api-key': action['x-api-key']}),
                ...(action['x-user'] && {'x-user': action['x-user']})
            },
            method: action.method ? action.method : 'GET',
            // url: "https://dev-journey-api.houseeazy.com" + action.url,
            url: process.env.REACT_APP_JOURNEY_URL + action.url,
            ...(action.data && { data: action.data})
        });
        if(action.loader === true) {document.querySelector(".form-loader").classList.remove("show") }
        return resp
    } catch (err) {
        console.log(err)
        if(action.loader === true) { document.querySelector(".form-loader").classList.remove("show") }
        return err.response
    }
}

export default JourneyAjax