import React from 'react'
import { Link } from 'react-router-dom'
export default function CounterBlock({children, score, url, icon}) {
  return (
    <div className="tile tile-primary">
        <div className="tile-heading">{children}</div>
        <div className="tile-body">
            <i className={`fas ${icon}`}></i>
            <h2 className="float-end">{score}</h2>
        </div>
        {Link &&
        <div className="tile-footer">
            <a href={url}>View more...</a>
        </div> }
    </div>
  )
}
