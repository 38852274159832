import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'


export default function HomepageDetail() {
    const [projectDetail, setProjectDetail] = useState(null)
    const GetProjectName = useRef()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
                const response = await Ajax({
                    url: `/project?limit=200`,
                    token: true,
                    loader: true
                  })
                  if(response.data.status === "SUCCESS!"){
                    setProjectDetail(response.data.result)
                  } 
            })()
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateHome = async (values, event) => {
        const data = {
            projectId: GetProjectName.current.value,
            average: values.average, 
            area: values.area,
            active: values.active == 1 ? true : false, 
        }
        const update = await Ajax({
            url: `/project/ProjectArea`,
            method: 'POST',
            token: true,
            data: data,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Project Area Added Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
        console.log(update)
    }
     
    return (
        <ContentLayout title="Add Developer">
            <Helmet>
                <title>Add Developer</title>
            </Helmet>
            <DataView title="Infomation">
        
                <Formik
                    initialValues={{ 
                        projectId: '',
                        name: '',
                        average: '', 
                        area:'',
                        active: 1
                    }}
                    onSubmit={(values, event) => {
                        updateHome(values, event)
                    }}
                >
                <Form>
                    <ul className="nav nav-tabs">
                        <li className="nav-item"><a href="#tab-general" data-bs-toggle="tab" className="nav-link active">General</a></li>
                    </ul>
                    <div className="tab-content">
                    <div className="tab-pane active" id="tab-general">
                        <div className='row'>
                            <div className='col-md-12'>
                                <FormGroup label="Project Name" required>
                                    <select name="name" ref={GetProjectName} defaultValue='' className='form-select'>
                                        <option value="">Project Name</option>
                                        {projectDetail && projectDetail.length > 0 && projectDetail.map((data) => (
                                            <option key={data._id} value={data._id}>{data.name}</option>
                                        ))}
                                        </select>
                                    <ErrorField><ErrorMessage name="sector"/></ErrorField>
                                </FormGroup>
                            </div>

                            <div className='col-md-12'>
                                <FormGroup label="Average (in Lakhs)" required>
                                    <Field placeholder='Average' name="average" className='form-control' />
                                    <ErrorField><ErrorMessage name="average"/></ErrorField>
                                </FormGroup>
                            </div>

                            <div className='col-md-12'>
                                <FormGroup label="Area (sq.ft)" required>
                                    <Field placeholder='Area' name="area" className='form-control' />
                                    <ErrorField><ErrorMessage name="area"/></ErrorField>
                                </FormGroup>
                            </div>
                        </div>
                            
                        <FormGroup label="Active" required>
                            <Field as="select" type="number" name="active" className='form-select'>
                                <option value={1}>Enable</option>
                                <option value={0}>Disable</option>
                            </Field>
                            <ErrorField><ErrorMessage name="active"/></ErrorField>
                        </FormGroup>     
                        </div> 
                        </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add</Button>
                    </div>
                </Form>
                </Formik> 
            </DataView>
        </ContentLayout>
    )
}
