import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AddNewProcess() {
    const urlParams = useParams()
    const navigate = useNavigate()

    const [processIcon, setProcessIcon] = useState(null)
    const addProcess = async (values, event) => {
        const formData = new FormData()
        if(processIcon != null){
            formData.append("file", processIcon)
        }
        const data = {
            name: values.process_title, 
            description: values.process_description, 
            sortOrder: values.sort_order, 
            type: values.type == 1 ? 'SELLER' : 'BUYER', 
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
            if(data[key] === "") {
                toast.error('Some Fields are Empty please check',{
                    toastId: 'EmptyFields'
                })
                return false
            }
        }
        const update = await Ajax({
            url: `/home/step`,
            method: 'POST',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Process Added Successfully')
            setTimeout(function(){
                navigate('/admin/home/process')
            },500)
        }
    }
    return (
        <ContentLayout title="Add New Process">
            <Helmet>
                <title>Add New Process</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{ 
                        process_title: '', 
                        process_description: '', 
                        sort_order: '',
                        type: ''
                    }}
                    validationSchema={Yup.object({
                        process_title: Yup.string()
                        .required('Field is Required'),
                        process_description: Yup.string()
                        .required('Field is Required'),
                        sort_order: Yup.string()
                        .required('Field is Required'),
                        type: Yup.string()
                        .required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        addProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                        <div className='col-md-8'>
                            <FormGroup label="Process Title" required>
                                <Field placeholder='Process Title' name="process_title" className='form-control' />
                                <ErrorField><ErrorMessage name="process_title"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Process Image" required>
                                <input type="file" name="process_image" onChange={(e)=>setProcessIcon(e.target.files[0])} className='form-control' />
                            </FormGroup>
                        </div>
                    </div>
                    <FormGroup label="Process Description" required>
                        <Field as="textarea" placeholder='Process Description' name="process_description" className='form-control' />
                        <ErrorField><ErrorMessage name="process_description"/></ErrorField>
                    </FormGroup>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Type" required>
                                <Field as="select" name="type" className='form-select'>
                                    <option value="" disabled>Select</option>
                                    <option value="1">Seller</option>
                                    <option value="2">Buyer</option>
                                </Field>
                                <ErrorField><ErrorMessage name="type"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Sort Order" required>
                                <Field type="number" placeholder='Sort Order' name="sort_order" className='form-control' />
                                <ErrorField><ErrorMessage name="sort_order"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add</Button>
                    </div>
                </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}
