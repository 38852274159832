import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import GetState from '../../../../../helpers/GetState'
import GetCity from '../../../../../helpers/GetCity'
import GetLocation from '../../../../../helpers/GetLocation'

export default function AddSector() {
    const [states, setStates] = useState(null)
    const [city, setCity] = useState(null)
    const [location, setLocation] = useState(null)
    const [selectedState, setSelectedState] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const navigate = useNavigate()

    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
                const stateList = await GetState()
                setStates(stateList)
            })()
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    //Seelct State for City
    const chooseState = async(e) => {
        var stateId = e.target.value
        setSelectedState(stateId)
        const cityFetch = await GetCity(stateId)
        setCity(cityFetch)
    }

    //Select City for Location
    const chooseCity = async(e) => {
        var cityId = e.target.value
        setSelectedCity(cityId)
        const locationFetch = await GetLocation(cityId)
        setLocation(locationFetch)
    }

    const addData = async (values, event) => {
        if(selectedState === ''){
            toast.error('Please select state!',{
                toastId: 'stateError',
                autoClose: 1000
            })
        }
        const data = {
            name: values.title, 
            state: selectedState, 
            city : selectedCity,
            location : values.location,
        }
        const update = await Ajax({
            url: `/setting/sector`,
            method: 'POST',
            token: true,
            data: data,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Sector Added Successfully')
            setTimeout(function(){
                navigate('/admin/property-management/sector')
            },500)
        }
    }
    return (
        <ContentLayout title="Add Sector">
            <Helmet>
                <title>Add Sector</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{ 
                        location:'',
                        title:''
                    }}
                    validationSchema={Yup.object({
                        location: Yup.string()
                        .required('Field is Required'),
                        title: Yup.string()
                        .required('Field is Required')
                       
                    })}
                    onSubmit={(values, event) => {
                        addData(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                        <div className='col-md-4'>
                            <FormGroup label="State" required>
                                <select name="state" className='form-select' value={selectedState} onChange={chooseState}>
                                    <option value="" disabled>Select State</option>
                                    {states === null ? <option value='' disabled>Searching...</option> :  states.map(e=>(
                                        <option key={e._id} value={e._id}>{e.name}</option>
                                    ))}
                                </select>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="City" required>
                                <select as="select" name="city" className='form-select' value={selectedCity} onChange={chooseCity}>
                                    <option value="" disabled>Select City</option>
                                    {city === null? <option value='' disabled>Select State</option> :  city.map(e=>(
                                            <option key={e._id} value={e._id}>{e.name}</option>
                                    ))}
                                </select>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Location" required>
                                <Field as="select" name="location" className='form-select'>
                                    <option value="" disabled>Select Location</option>
                                    {location === null? <option value='' disabled>Select City</option> :  location.map(e=>(
                                            <option key={e._id} value={e._id}>{e.name}</option>
                                    ))}
                                </Field>
                                <ErrorField><ErrorMessage name="location"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Title" required>
                                <Field placeholder='Title' name="title" className='form-control' />
                                <ErrorField><ErrorMessage name="title"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add</Button>
                    </div>
                </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}
