import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from "react-router-dom"
import EditButton from '../../../../UI/Dashboard/EditButton'

export default function HomeInteriorUpdate() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()
    const dataIcon = useRef()
    const navigate = useNavigate()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/setting/meta?link=home-interiors`,
                token: true
              })
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }
            })()
          },100)    
        
         
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateData = async (values, event) => {

        const data = {
            name: infoDetail.name,
            title: values.title,
            description: values.description,
            title1: values.title1,
            description1: values.description1,
            title2: values.title2,
            title3: values.title3,
            description3: values.description3
        }

        const update = await Ajax({
            url: `/setting/meta/${infoDetail._id}`,
            method: 'PUT',
            token: true,
            data: data,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
            return false
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Data Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Edit Home Interior">
            <Helmet>
                <title>Edit Home Interior</title>
            </Helmet>
            <DataView title="Infomation">
              
            {infoDetail === null   ? <DataLoader/> : 
                <Formik
                initialValues={{ 
                    title: infoDetail.title ? infoDetail.title : '',
                    description: infoDetail.description ? infoDetail.description : '',
                    title1: infoDetail.title1 ? infoDetail.title1 : '',
                    description1: infoDetail.description1 ? infoDetail.description1 : '',
                    title2: infoDetail.title2 ? infoDetail.title2 : '',
                    title3: infoDetail.title3 ? infoDetail.title3 : '',
                    description3: infoDetail.description3 ? infoDetail.description3 : '',
                }}
                validationSchema={Yup.object({
                    title: Yup.string().required('Field is Required'),
                    title1: Yup.string().required('Field is Required'),
                    title1: Yup.string().required('Field is Required'),
                    title2: Yup.string().required('Field is Required'),
                    title3: Yup.string().required('Field is Required'),
                })}
                onSubmit={(values) => {
                    updateData(values)
                }}
            >
            <Form>
                <FormGroup label="Title" full required>
                    <Field placeholder='Title' name="title" className='form-control' />
                    <ErrorField><ErrorMessage name="title"/></ErrorField>
                </FormGroup>
                <FormGroup label="Description" full>
                    <Field as="textarea" placeholder='Description' name="description" className='form-control' />
                    <ErrorField><ErrorMessage name="description"/></ErrorField>
                </FormGroup>
                <FormGroup label="How It Work Title" full required>
                    <Field placeholder='How It Work Title"' name="title1" className='form-control' />
                    <ErrorField><ErrorMessage name="title1"/></ErrorField>
                </FormGroup>
                <FormGroup label="How It Work Description" full>
                    <Field as="textarea" placeholder='How It Work Description' name="description1" className='form-control' />
                    <ErrorField><ErrorMessage name="description1"/></ErrorField>
                </FormGroup>
                <FormGroup label="Why Choose Title" full required>
                    <Field placeholder='Why Choose Title' name="title2" className='form-control' />
                    <ErrorField><ErrorMessage name="title2"/></ErrorField>
                </FormGroup>
                <FormGroup label="Faq Title" full required>
                    <Field placeholder='Faq Title' name="title3" className='form-control' />
                    <ErrorField><ErrorMessage name="title3"/></ErrorField>
                </FormGroup>
                <FormGroup label="Faq Description" full>
                    <Field as="textarea" placeholder='Faq Description' name="description3" className='form-control' />
                    <ErrorField><ErrorMessage name="description3"/></ErrorField>
                </FormGroup>
                <div className='col-md-2 offset-md-2 d-grid'>
                    <Button type="submit">Update</Button>
                </div>
            </Form>
            </Formik> }
            </DataView>
        </ContentLayout>
    )
}