import React, {useEffect, useState} from 'react'
import Ajax from '../../../../helpers/Ajax'
import TableLoader from '../../../UI/Dashboard/TableLoader';
import DataView from '../../../UI/Dashboard/DataView';
import TableContainer from '../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../Common/ContentLayout'
import EditButton from '../../../UI/Dashboard/EditButton';
import DeleteButton from '../../../UI/Dashboard/DeleteButton';
import PaginationContainer from '../../../UI/Dashboard/PaginationContainer';
import Pagination from '../../../UI/Dashboard/Pagination';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import moment from 'moment';

export default function BuyerList() {
    const [dataList, setDataList] = useState(null)
    const [totalResult, setTotalResult] = useState(null)
    const [totalRow, setTotalRows] = useState(null)
    const [newpage, setNewPage] = useState(false)
    const [initialPage, setInitialPage] = useState(0)
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(searchParams.get('page'))
    const pageParams = searchParams.get('page')
    const navigate = useNavigate()
    useEffect(()=>{
    const getResponse = setTimeout(function(){  
        (async()=>{ 
        const response = await Ajax({
            url: `/user/getUserList?role=636c96b0b7ee354a32f2914c${newpage !== false ? `&pagination=${newpage}` : ''}${pageParams && newpage === false ? `&pagination=${pageParams}` : ''}`,
            token: true,
            ...(pageParams && {loader: true})
        })
        if(response.data.status === "SUCCESS!"){
            setDataList(response.data.result)
            setTotalResult(response.data.countData)
            setTotalRows(response.data.countData / 10)
            setInitialPage(pageParams)
            setCurrentPage(searchParams.get('page'))
        }
       
        })()
    },100) 
    return()=>{
        clearInterval(getResponse)
    }
    },[location])

    return (
    <ContentLayout title='Buyer List' >
        <div className="row">
        <Helmet>
            <title>Buyer List</title>
        </Helmet>
        <DataView title="Buyer List">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-start">Name</td>
                        <td className="text-start">Email</td>
                        <td className="text-start">Phone</td>
                        <td className="text-center">Date / Time</td>
                        <td className="text-center">Status</td>
                        <td className="text-center">Upload Document</td>
                    </tr>
                </thead>
                <tbody>
                    {dataList == null ? 
                    <TableLoader cols={7}/> : dataList.length === 0 ? 
                    <tr>
                        <td colSpan={7} className="text-center">Data not available</td>
                    </tr> : dataList.map((data, index)=>(
                    <tr key={data._id} >
                        <td className="text-center">{currentPage ? ((index + 1) + Number(currentPage) * 1 * 10) : index + 1}</td>
                        <td className="text-start ">{data.name?data.name:'NA'}</td>
                        <td className="text-start">{data.email?data.email:'NA'}</td>
                         <td className="text-start ">{data.phoneNumber?data.phoneNumber:'NA'}</td>
                         <td className="text-center ">{moment(data.createdAt).format('DD MMM YYYY')}<br/>{moment(data.createdAt).format('HH:mm')}</td>
                        <td className="text-center">{data.active === true ? <span className="badge bg-success">Enable</span> : <span className="badge bg-danger">Disable</span>}</td>
                        <td className="text-center">
                            <EditButton>{`/admin/buyer/${data._id}`}</EditButton>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
            <PaginationContainer count={totalResult}>
                <Pagination totalPage={totalRow} url='/admin/buyer-list' currentPage={initialPage}  />
            </PaginationContainer>
        </DataView>
    </div>
    </ContentLayout>
    )
}
