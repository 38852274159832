import React, {useEffect, useState} from 'react'
import Ajax from '../../../../helpers/Ajax'
import TableLoader from '../../../UI/Dashboard/TableLoader';
import DataView from '../../../UI/Dashboard/DataView';
import TableContainer from '../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../Common/ContentLayout';
import EditButton from '../../../UI/Dashboard/EditButton';
import DeleteButton from '../../../UI/Dashboard/DeleteButton';
import PaginationContainer from '../../../UI/Dashboard/PaginationContainer';
import Pagination from '../../../UI/Dashboard/Pagination';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import ViewButton from '../../../UI/Dashboard/ViewButton';
import PopupViewButton from '../../../UI/Dashboard/PopupViewButton';
import * as XLSX from 'sheetjs-style'
import { toast } from 'react-toastify'

export default function ContactUsEnquiry() {
    const [dataList, setDataList] = useState(null)
    const [totalResult, setTotalResult] = useState(null)
    const [totalRow, setTotalRows] = useState(null)
    const [newpage, setNewPage] = useState(false)
    const [search, setSearch] = useState(false)
    const [initialPage, setInitialPage] = useState(0)
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(searchParams.get('page'))
    const pageParams = searchParams.get('page')
    useEffect(()=>{
        const getResponse = setTimeout(function(){  
            (async()=>{ 
            const response = await Ajax({
                url: `/enquiry/getContact${newpage !== false ? `?pagination=${newpage}` : ''}${pageParams && newpage === false ? `?pagination=${pageParams}` : ''}`,
                token: true,
                ...(pageParams && {loader: true})
            })
            if(response.data.status === "SUCCESS!"){
                setDataList(response.data.result)
                setTotalResult(response.data.count)
                setTotalRows(response.data.count / 10)
                setInitialPage(pageParams)
                setCurrentPage(searchParams.get('page'))
            }
            })()
        },100) 
        return()=>{
            clearInterval(getResponse)
        }
    },[location])

    const [enquiryData, setEnquiryData] = useState(null)
    const viewDetail = (data) => {
        setEnquiryData({
            name: data.firstName+' '+data.lastName,
            email: data.email,
            phone: data.phone,
            intro: data.youAre,
            message: data.message
        })
    }

    const exportCSV = async () => {
        const records = await Ajax({
          url: '/enquiry/getContact?limit=false',
          token: true,
          loader: true
        })
        if(records.data.result.length === 0) {
            toast.error("Record not found!",{
              autoClose:1500,
            })
            return false
        }
        const excelData = []
        const recordData = records.data.result
        for(var e in recordData){
            excelData.push(
                {
                    "Date": recordData[e].createdAt ? moment(recordData[e].createdAt).format('YYYY-MM-DD / HH:mm') : '',
                    "First Name": recordData[e].firstName ? recordData[e].firstName : '',
                    "Last Name": recordData[e].lastName ? recordData[e].lastName : '',
                    "Email": recordData[e].email ? recordData[e].email : '',
                    "Phone": recordData[e].phone ? recordData[e].phone : '',
                    "You are a": recordData[e].youAre ? recordData[e].youAre : '',
                    "Message": recordData[e].message ? recordData[e].message : ''
                }
            )
        }
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Contact-Us-Enquiry.xlsx");
            
    }

    return (
    <ContentLayout title='Contact Us Enquiry' exportExcel={['Export Excel', exportCSV]}>
        <div className="row">
        <Helmet>
            <title>Contact Us Enquiry</title>
        </Helmet>
        <DataView title="Contact Us Enquiry List">
            <TableContainer>
                <thead>
                    <tr>
                        <td width={80} className="text-center">S.No</td>
                        <td className="text-start">Name</td>
                        <td className="text-start">Email</td>
                        <td className="text-start">Phone</td>
                        <td className="text-start">Intro</td>
                        <td className="text-start">Date / Time</td>
                        <td className="text-center">View</td>
                        <td className="text-center">Delete</td>
                    </tr>
                </thead>
                <tbody>
                    {dataList == null ? 
                    <TableLoader cols={8}/> : dataList.length === 0 ? 
                    <tr>
                        <td colSpan={8} className="text-center">Data not available</td>
                    </tr> : dataList.map((data, index)=>(
                    <tr key={data._id}>
                        <td className="text-center">{currentPage ? ((index + 1) + Number(currentPage) * 1 * 10) : index + 1}</td>
                        <td className="text-start">{data.firstName+' '+data.lastName}</td>
                        <td className="text-start">{data.email}</td>
                        <td className="text-start">{data.phone}</td>
                        <td className="text-start">{data.youAre}</td>
                        <td className="text-start">{moment(data.createdAt).format('DD MMM YYYY')} / {moment(data.createdAt).format('HH:mm')}</td>
                        <td className="text-center">
                            <PopupViewButton handler={()=>viewDetail(data)} popup='viewContactDetail' />
                        </td>
                        <td className="text-center">
                            <DeleteButton url="/enquiry/contact" id={data._id} />
                        </td>
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
            <PaginationContainer count={totalResult}>
                <Pagination totalPage={totalRow} url='/admin/enquiry/contact-us' currentPage={initialPage}  />
            </PaginationContainer>
        </DataView>
    </div>

    <div className="modal fade" id="viewContactDetail" tabIndex="-1" ariaHidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Enquiry Detail</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <th width={100}>Name</th>
                                <td>{enquiryData && enquiryData.name}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{enquiryData && enquiryData.email}</td>
                            </tr>
                            <tr>
                                <th>Phone</th>
                                <td>{enquiryData && enquiryData.phone}</td>
                            </tr>
                            <tr>
                                <th>Intro</th>
                                <td>{enquiryData && enquiryData.intro}</td>
                            </tr>
                            <tr>
                                <th>Message</th>
                                <td>{enquiryData && <div dangerouslySetInnerHTML={{__html: enquiryData.message}} /> }</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    </ContentLayout>
    )
}
