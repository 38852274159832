import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from "react-router-dom"
import EditButton from '../../../../UI/Dashboard/EditButton'

export default function HomeLoanUpdate() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()
    const dataIcon = useRef()
    const navigate = useNavigate()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/finance/heading`,
                token: true
              })
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }
            })()
          },100)    
        
         
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateData = async (values, event) => {

        const data = {
            whyChooseTitle: values.whyChooseTitle,
            whyChooseDescription: values.whyChooseDescription,
            homeLoanTitle: values.homeLoanTitle,
            homeLoanDescription: values.homeLoanDescription,
            applyusTitle: values.applyusTitle,
            applyusDescription: values.applyusDescription,
            emiTitle: values.emiTitle,
            emiDescription: values.emiDescription,
            loanTipsTitle: values.loanTipsTitle,
            loanTipsDescription: values.loanTipsDescription,
        }

        const update = await Ajax({
            url: `/finance/heading/637f0fe2cf1a2f0c24729651`,
            method: 'PUT',
            token: true,
            data: data,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
            return false
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Data Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Edit Home Loan">
            <Helmet>
                <title>Edit Home Loan</title>
            </Helmet>
            <DataView title="Infomation">
              
            {infoDetail === null   ? <DataLoader/> : 
                <Formik
                initialValues={{ 
                    whyChooseTitle: infoDetail.whyChooseTitle ? infoDetail.whyChooseTitle : '',
                    whyChooseDescription: infoDetail.whyChooseDescription ? infoDetail.whyChooseDescription : '',
                    homeLoanTitle: infoDetail.homeLoanTitle ? infoDetail.homeLoanTitle : '',
                    homeLoanDescription: infoDetail.homeLoanDescription ? infoDetail.homeLoanDescription : '',
                    applyusTitle: infoDetail.applyusTitle ? infoDetail.applyusTitle : '',
                    applyusDescription: infoDetail.applyusDescription ? infoDetail.applyusDescription : '',
                    emiTitle: infoDetail.emiTitle ? infoDetail.emiTitle : '',
                    emiDescription: infoDetail.emiDescription ? infoDetail.emiDescription : '',
                    loanTipsTitle: infoDetail.loanTipsTitle ? infoDetail.loanTipsTitle : '',
                    loanTipsDescription: infoDetail.loanTipsDescription ? infoDetail.loanTipsDescription : '',
                }}
                validationSchema={Yup.object({
                    whyChooseTitle: Yup.string().required('Field is Required'),
                    homeLoanTitle: Yup.string().required('Field is Required'),
                    applyusTitle: Yup.string().required('Field is Required'),
                    emiTitle: Yup.string().required('Field is Required'),
                    loanTipsTitle: Yup.string().required('Field is Required'),
                })}
                onSubmit={(values) => {
                    updateData(values)
                }}
            >
            <Form>
                <FormGroup label="Title" full required>
                    <Field placeholder='Title' name="whyChooseTitle" className='form-control' />
                    <ErrorField><ErrorMessage name="whyChooseTitle"/></ErrorField>
                </FormGroup>
                <FormGroup label="Description" full>
                    <Field as="textarea" placeholder='Description' name="whyChooseDescription" className='form-control' />
                    <ErrorField><ErrorMessage name="whyChooseDescription"/></ErrorField>
                </FormGroup>
                <FormGroup label="Loan Title" full required>
                    <Field placeholder='Loan Title' name="homeLoanTitle" className='form-control' />
                    <ErrorField><ErrorMessage name="homeLoanTitle"/></ErrorField>
                </FormGroup>
                <FormGroup label="Loan Description" full>
                    <Field as="textarea" placeholder='Loan Description' name="homeLoanDescription" className='form-control' />
                    <ErrorField><ErrorMessage name="homeLoanDescription"/></ErrorField>
                </FormGroup>
                <FormGroup label="Apply Title" full required>
                    <Field placeholder='Apply Title' name="applyusTitle" className='form-control' />
                    <ErrorField><ErrorMessage name="applyusTitle"/></ErrorField>
                </FormGroup>
                <FormGroup label="Apply Description" full>
                    <Field as="textarea" placeholder='Apply Description' name="applyusDescription" className='form-control' />
                    <ErrorField><ErrorMessage name="applyusDescription"/></ErrorField>
                </FormGroup>
                <FormGroup label="EMI Title" full required>
                    <Field placeholder='EMI Title' name="emiTitle" className='form-control' />
                    <ErrorField><ErrorMessage name="emiTitle"/></ErrorField>
                </FormGroup>
                <FormGroup label="EMI Description" full>
                    <Field as="textarea" placeholder='EMI Description' name="emiDescription" className='form-control' />
                    <ErrorField><ErrorMessage name="emiDescription"/></ErrorField>
                </FormGroup>
                <FormGroup label="Tip Title" full required>
                    <Field placeholder='Tip Title' name="loanTipsTitle" className='form-control' />
                    <ErrorField><ErrorMessage name="loanTipsTitle"/></ErrorField>
                </FormGroup>
                <FormGroup label="Tip Description" full>
                    <Field as="textarea" placeholder='Tip Description' name="loanTipsDescription" className='form-control' />
                    <ErrorField><ErrorMessage name="loanTipsDescription"/></ErrorField>
                </FormGroup>
                <div className='col-md-2 offset-md-2 d-grid'>
                    <Button type="submit">Update</Button>
                </div>
            </Form>
            </Formik> }
            </DataView>
        </ContentLayout>
    )
}
