import React, {useEffect, useState} from 'react'
import Ajax from '../../../../../../helpers/Ajax';
import TableLoader from '../../../../../UI/Dashboard/TableLoader';
import DataView from '../../../../../UI/Dashboard/DataView';
import TableContainer from '../../../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../../../Common/ContentLayout';
import EditButton from '../../../../../UI/Dashboard/EditButton';
import DeleteButton from '../../../../../UI/Dashboard/DeleteButton';

export default function HomefestFeaturesList() {
    const [dataList, setDataList] = useState(null)
    useEffect(()=>{
    const getResponse = setTimeout(function(){  
        (async()=>{ 
        const response = await Ajax({
            url: `/landingPage/bonanza?limit=false`,
            token: true,
        })
        if(response.data.status === "SUCCESS!"){
            setDataList(response.data.result)
        }
        })()
    },100) 
    return()=>{
        clearInterval(getResponse)
    }
    },[])

    return (
    <ContentLayout title='Features List' addNew='/admin/page/homefest/feature/add-new'>
        <div className="row">
        <Helmet>
            <title>Features List</title>
        </Helmet>
        <DataView title="Features List">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-start">Title</td>
                        <td className="text-start">Icon</td>
                        <td className="text-center">Active</td>
                        <td className="text-center">Edit</td>
                        <td className="text-center">Delete</td>
                    </tr>
                </thead>
                <tbody>
                    {dataList == null ? 
                    <TableLoader cols={6}/> : dataList.length === 0 ? 
                    <tr>
                        <td colSpan={6} className="text-center">Data not available</td>
                    </tr> : dataList.map((data, index)=>(
                    <tr key={data._id}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-start text-capitalize">{data.name}</td>
                        <td className="text-start"><img src={data.file.path} alt="icon" /></td>
                        <td className="text-center">{data.active === true ? <span className="badge bg-success">Enable</span> : <span className="badge bg-danger">Disable</span>}</td>
                        <td className="text-center">
                            <EditButton>{`/admin/page/homefest/feature/${data._id}`}</EditButton>
                        </td>
                        <td className="text-center">
                            <DeleteButton url="/landingPage/bonanza" id={data._id} />
                        </td>
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
        </DataView>
    </div>
    </ContentLayout>
    )
}
