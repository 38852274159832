import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../UI/Dashboard/DataView'
import Ajax from '../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../UI/Form/FormGroup'
import ErrorField from '../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../UI/Dashboard/DataLoader'
import Button from '../../../UI/Form/Button'
import ContentLayout from '../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import GetBlogType from '../../../../helpers/GetBlogType'
import { CKEditor } from 'ckeditor4-react';

export default function BlogEdit() {
    const [blogInfo,setInfoDetail] = useState(null)
    const urlParams = useParams()
    const navigate = useNavigate()
    const dataIcon = useRef()
    const dataIcon2 = useRef()
    const [blogList, setBlogList] = useState(null)
    const [editorContent, setEditorContent] = useState(null)

    useEffect(()=>{
        const getResponse = setTimeout(function(){
        (async()=>{
            const response = await Ajax({
                url: `/blog?_id=${urlParams.BlogId}`,
                token: true
            })
            if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
                setEditorContent(response.data.result[0].description)
            }

            const blogTypes = await GetBlogType()
            setBlogList(blogTypes)
        })()
        },100)
        return()=>{
            clearInterval(getResponse)
        }
    },[])

    const addProcess = async (values, event) => {
        const formData = new FormData()
        if(dataIcon.current.value != ''){
            formData.append("file", dataIcon.current.files[0])
        }
        if(dataIcon2.current.value != ''){
            formData.append("blog", dataIcon2.current.files[0])
        }
        const data = {
            title: values.title, 
            shortDescription: values.shortDescription, 
            ...(editorContent !== null && {description: editorContent}), 
            link: values.link, 
            metaTag : values.metaTag , 
            metaTitle : values.metaTitle , 
            metaDescription : values.metaDescription , 
            blogType: values.blogType,
            active: values.active == 1 ? true : false, 
            popular: values.popular
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/blog/${urlParams.BlogId}`,
            method: 'PUT',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Blog Updated Successfully')
            setTimeout(function(){
                navigate('/admin/blog')
            },500)
        }
    }
    return (
        <ContentLayout title="Edit Blog">
            <Helmet>
                <title>Edit Blog</title>
            </Helmet>
            <DataView title="Infomation">
                {blogInfo === null ? <DataLoader/> :
                <Formik
                    initialValues={{ 
                        title: blogInfo.title ? blogInfo.title : '', 
                        link: blogInfo.link ? blogInfo.link : '',
                        shortDescription:  blogInfo.shortDescription ? blogInfo.shortDescription : '', 
                        metaTag : blogInfo.metaTag ? blogInfo.metaTag : '' , 
                        metaTitle : blogInfo.metaTitle ? blogInfo.metaTitle : '' , 
                        metaDescription : blogInfo.metaDescription ? blogInfo.metaDescription : '' , 
                        blogType: blogInfo.blogType ? blogInfo.blogType._id : '',
                        active: blogInfo.active === true ? '1' : '2',
                        popular: blogInfo.popular ? blogInfo.popular : '0'
                    }}
                    validationSchema={Yup.object({
                        title: Yup.string().required('Field is Required'),
                        metaTitle: Yup.string().required('Field is Required'),
                        blogType: Yup.string().required('Field is Required'),
                        shortDescription: Yup.string().required('Field is Required'),
                       
                    })}
                    onSubmit={(values, event) => {
                        addProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Select Blog Type" required>
                                <Field as="select" name="blogType" className='form-select'>
                                    <option value="" disabled>Select</option>
                                    {blogList !== null && blogList.map((data)=>(
                                        <option key={data._id} value={data._id}>{data.name}</option>
                                    ))}
                                </Field>
                                <ErrorField><ErrorMessage name="title"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Popular" required>
                                <Field as="select" name="popular" className='form-select'>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </Field>
                                <ErrorField><ErrorMessage name="type"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Title" required>
                                <Field placeholder='Title' name="title" className='form-control' />
                                <ErrorField><ErrorMessage name="title"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="External Link (Optional)" >
                                <Field placeholder='Link' name="link" className='form-control' />
                                <ErrorField><ErrorMessage name="link"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-12'>
                            <FormGroup label="Short Description" required>
                                <Field placeholder='Short Description' name="shortDescription" className='form-control' />
                                <ErrorField><ErrorMessage name="shortDescription"/></ErrorField>
                            </FormGroup>
                        </div>
                       
                        <div className='col-md-6'>
                            <FormGroup label="Full Image" required>
                                <input type="file" accept='.jpg, .png, jpeg, .webp' name="nearby_image" ref={dataIcon} className='form-control' />
                            </FormGroup>
                            {blogInfo.file &&
                                <img src={blogInfo.file.smallFile} width="80px" alt="image" />
                            }
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Thumbnail Image" required>
                                <input type="file" accept='.jpg, .png, jpeg, .webp' name="nearby_image" ref={dataIcon2} className='form-control' />
                            </FormGroup>
                            {blogInfo.thumbnail &&
                                <img src={blogInfo.thumbnail.smallFile} width="80px" alt="image" />
                            }
                        </div>
                        
                        <div className='col-md-12'>
                            <FormGroup label="Description" >
                                <CKEditor initData={editorContent} onChange={(e)=>setEditorContent(e.editor.getData())} />
                            </FormGroup>
                        </div>
                        <div className='col-md-12'>
                            <FormGroup label="Meta Title" required>
                                <Field placeholder='Meta Title' name="metaTitle" className='form-control' />
                                <ErrorField><ErrorMessage name="metaTitle"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-12'>
                            <FormGroup label="Meta Description" >
                                <Field as="textarea" placeholder='Meta Description' name="metaDescription" className='form-control' />
                                <ErrorField><ErrorMessage name="metaDescription"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-12'>
                            <FormGroup label="Meta Keyword" >
                                <Field placeholder='Meta Keyword' name="metaTag" className='form-control' />
                                <ErrorField><ErrorMessage name="metaTag"/></ErrorField>
                            </FormGroup>
                        </div>
                        <FormGroup label="Active" required>
                            <Field as="select" name="active" className='form-select'>
                                <option value="1">Enable</option>
                                <option value="2">Disable</option>
                            </Field>
                            <ErrorField><ErrorMessage name="type"/></ErrorField>
                        </FormGroup>
                    </div>
                   
                    
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik> }
            </DataView>
        </ContentLayout>
    )
}
