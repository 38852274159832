import React, {useContext, useEffect} from 'react'
import {Routes, Route, Navigate, useNavigate, useLocation} from "react-router-dom";
import Login from '../Pages/Account/Login';
import ForgotPassword from '../Pages/Account/ForgotPassword';
import MainLayout from '../Pages/Admin';
import Dashboard from '../Pages/Admin/Dashboard';

//Home
import HomeContent from '../Pages/Admin/Home/Content/Index';
import HomepageDetail from '../Pages/Admin/Home/Content/[HomepageId]';

//Home Process
import ProcessList from '../Pages/Admin/Home/Process';
import ProcessDetail from '../Pages/Admin/Home/Process/[ProcessId]';
import AddNewProcess from '../Pages/Admin/Home/Process/AddNew';

//Home Why Choose
import WhyChooseList from '../Pages/Admin/Home/WhyChoose';
import WhyChooseDetail from '../Pages/Admin/Home/WhyChoose/[WhyChooseId]';
import AddNewWhy from '../Pages/Admin/Home/WhyChoose/AddNew';

//Value Added
import ValueAddedList from '../Pages/Admin/Home/ValueAdded';
import AddValue from '../Pages/Admin/Home/ValueAdded/AddNew';
import ValueDetail from '../Pages/Admin/Home/ValueAdded/[ValueAddedId]';

//About Content
import AboutContent from '../Pages/Admin/AboutUs/Content/Index';
import AboutDetail from '../Pages/Admin/AboutUs/Content/[AboutPageId]';

//import About Gallery
import AddNewAboutGallery from '../Pages/Admin/AboutUs/Gallery/AddNewGallery';
import AboutGalleryList from '../Pages/Admin/AboutUs/Gallery/GalleryList';
import AboutGalleryDetail from '../Pages/Admin/AboutUs/Gallery/[GalleryId]';

//import About Solution 
import AddNewSolution from '../Pages/Admin/AboutUs/Solution/AddNewSolution';
import SolutionList from '../Pages/Admin/AboutUs/Solution/SolutionList';
import SolutionDetail from '../Pages/Admin/AboutUs/Solution/[SolutionId]';

//Property 
import AddNewAmenity from '../Pages/Admin/Property/Amenity/AddNewAmenity';
import AmenityList from '../Pages/Admin/Property/Amenity/AmenityList';
import AmenityDetail from '../Pages/Admin/Property/Amenity/[AmenityId]';
import PropertyList from '../Pages/Admin/Property';
import AddNewProperty from '../Pages/Admin/Property/AddNew';
import EditProperty from '../Pages/Admin/Property/[PropertyId]';
import LocationList from '../Pages/Admin/Property/Location/LocationList';
import AddLocation from '../Pages/Admin/Property/Location/AddLocation';
import EditLocation from '../Pages/Admin/Property/Location/[LocationId]';
import SectorList from '../Pages/Admin/Property/Sector/SectorList';
import AddSector from '../Pages/Admin/Property/Sector/AddSector';
import EditSector from '../Pages/Admin/Property/Sector/[SectorId]';
import ProjectList from '../Pages/Admin/Property/Projects/ProjectList';
import EditProject from '../Pages/Admin/Property/Projects/[ProjectId]';
import AddProject from '../Pages/Admin/Property/Projects/AddProject';

//PropertyJourney
import SellerList from '../Pages/Admin/PropertyJourney/Seller/SellerList';
import SellerDetail from '../Pages/Admin/PropertyJourney/Seller/[JourneyId]';

//Seller Property
import SellerPropertyList from '../Pages/Admin/SellerProperty/PropertList';
import EditSellerProperty from '../Pages/Admin/SellerProperty/[PropertyId]';

//Buyer Sortlist
import ShortlistPropertyUsers from '../Pages/Admin/ShortlistPropertyUsers/ShortlistUserId';
import EditShortlistProperty from '../Pages/Admin/ShortlistPropertyUsers/[ShortlistId]';

//Broker List
import BrokerList from '../Pages/Admin/PropertyJourney/Broker/BrokerList';
import AddBroker from '../Pages/Admin/PropertyJourney/Broker/AddBroker';
import BrokerDetails from '../Pages/Admin/PropertyJourney/Broker/[BrokerId]';

//Broker Lead
import BrokerLeadList from '../Pages/Admin/PropertyJourney/BrokerLead/BrokerLeadList';
import AddBrokerLead from '../Pages/Admin/PropertyJourney/BrokerLead/AddBrokerLead';
import BrokerLeadDetails from '../Pages/Admin/PropertyJourney/BrokerLead/[BrokerLeadId]';

//Relationship Manager List
import RMList from '../Pages/Admin/PropertyJourney/RM/RMList';
import AddNewRM from '../Pages/Admin/PropertyJourney/RM/AddNewRM';
import RMDetails from '../Pages/Admin/PropertyJourney/RM/[RM_Id]'

//Aops
import AopList from '../Pages/Admin/PropertyJourney/Aops/AopList';
import AddAop from '../Pages/Admin/PropertyJourney/Aops/AddAop';
import AopDetails from '../Pages/Admin/PropertyJourney/Aops/[AopId]';

//Buyer List
import BuyerList from '../Pages/Admin/BuyerList/BuyerList';
import EditBuyer from '../Pages/Admin/BuyerList/[BuyerId]';

//Property BHK
import AddNewBhk from '../Pages/Admin/Property/Bhk/AddNewBhk';
import BhkList from '../Pages/Admin/Property/Bhk/BhkList';
import BhkDetail from '../Pages/Admin/Property/Bhk/[BhkId]';

//Property Developer
import AddNewDeveloper from '../Pages/Admin/Property/Developer/AddNewDeveloper';
import DeveloperList from '../Pages/Admin/Property/Developer/DeveloperList';
import DeveloperDetail from '../Pages/Admin/Property/Developer/[DeveloperId]';

//Project Area
import AddNewProjectArea from '../Pages/Admin/Property/ProjectArea/AddNewProjectArea';
import ProjectAreaList from '../Pages/Admin/Property/ProjectArea/ProjectAreaList';
import EditProjectArea from '../Pages/Admin/Property/ProjectArea/[ProjectAreaId]';

//Property Nearby Infra
import AddNewNearby from '../Pages/Admin/Property/Nearby/AddNewNearby';
import NearbyList from '../Pages/Admin/Property/Nearby/NearbyList';
import NearbyDetail from '../Pages/Admin/Property/Nearby/[NearbyId]';

//buyer property
import ScheduleList from '../Pages/Admin/Property/Schedule/ScheduleList';

//import Testimonials
import AddNewTestimonial from '../Pages/Admin/Testimonial/AddNewTestimonial';
import TestimonialList from '../Pages/Admin/Testimonial/TestimonialList';
import TestimonialDetail from '../Pages/Admin/Testimonial/[TestimonialId]';
 
//import Faq
import AddNewFaq from '../Pages/Admin/Faq/AddNewFaq';
import FaqList from '../Pages/Admin/Faq/FaqList';
import FaqDetail from '../Pages/Admin/Faq/[FaqId]';

//import Team
import AddNewTeam from '../Pages/Admin/Team/AddNewTeam';
import TeamList from '../Pages/Admin/Team/TeamList';
import TeamDetail from '../Pages/Admin/Team/[TeamId]';
 
//import News and Media
import AddNewBlog from '../Pages/Admin/Blog/AddNewBlog';
import BlogList from '../Pages/Admin/Blog/BlogList';
import BlogEdit from '../Pages/Admin/Blog/[BlogId]';
import EventList from '../Pages/Admin/Blog/EventList';
import AddNewEvent from '../Pages/Admin/Blog/AddNewEvent';
import EditEvent from '../Pages/Admin/Blog/[EventId]';
import AwardsList from '../Pages/Admin/Blog/AwardList';
import AddNewAward from '../Pages/Admin/Blog/AddAward';
import EditAward from '../Pages/Admin/Blog/[AwardId]';
 
//import career job opening
import AddNewJob from '../Pages/Admin/Career/Job/AddNewJob';
import JobList from '../Pages/Admin/Career/Job/JobList';
import JobDetail from '../Pages/Admin/Career/Job/[JobId]';
import JobEnquiryList from '../Pages/Admin/Career/Job/JobEnquiryList';
import JobApplyList from '../Pages/Admin/Career/Job/JobApplyList';
import ChannelPartnerEnquiry from '../Pages/Admin/Enquiry/ChannelPartner';
import HomepageEnquiry from '../Pages/Admin/Enquiry/Homepage';

//import career Gallery
import AddNewGallery from '../Pages/Admin/Career/Gallery/AddNewGallery';
import GalleryList from '../Pages/Admin/Career/Gallery/GalleryList';
import GalleryDetail from '../Pages/Admin/Career/Gallery/[GalleryId]';

//import career Heading
import HeadingList from '../Pages/Admin/Career/Heading/HeadingList';
import HeadingDetail from '../Pages/Admin/Career/Heading/[HeadingId]';

//Enquiry
import NewsletterEnquiry from '../Pages/Admin/Enquiry/Newsletter';
import ContactUsEnquiry from '../Pages/Admin/Enquiry/ContactUs';
import PropertyEnquiry from '../Pages/Admin/Enquiry/Property';
import HomeLoanEnquiry from '../Pages/Admin/Enquiry/HomeLoan';
import LeadEnquiry from '../Pages/Admin/Enquiry/LeadEnquiry';
import HomefestEnquiry from '../Pages/Admin/Enquiry/Homefest';
import PopupCtaEnquiry from '../Pages/Admin/Enquiry/PopupCTA';
import TakeTour from '../Pages/Admin/Enquiry/TakeTour';

//Website Setting,appConfig and Meta 
import Setting from '../Pages/Admin/Setting/WebsiteSetting/Setting';
import MetaList from '../Pages/Admin/Setting/Meta/List';
import AddNewMeta from '../Pages/Admin/Setting/Meta/AddNew';
import EditMeta from '../Pages/Admin/Setting/Meta/[MetaId]';
import AppConfig from '../Pages/Admin/Setting/AppConfig/appConfig';
import ConfigList from '../Pages/Admin/Setting/AppConfig/configList';

//data analytics
import DataAnalytics from '../Pages/Admin/Setting/DataAnalytics/dataList';

//User
import UserList from '../Pages/Admin/Users/UserList';
import UserDetail from '../Pages/Admin/Users/[UserId]'
import SellerScheduleList from '../Pages/Admin/Users/SellerSchedule';
import BuyerScheduleList from '../Pages/Admin/Users/BuyerSchedule';

//VAS
import AddNewHowItWork from '../Pages/Admin/VAS/HowItWork/AddNew';
import HowItWorkList from '../Pages/Admin/VAS/HowItWork/List';
import HowItWorkEdit from '../Pages/Admin/VAS/HowItWork/[HowItWorkId]';
import AddNewWhyChoose from '../Pages/Admin/VAS/WhyChoose/AddNew';
import WhyChooseUsList from '../Pages/Admin/VAS/WhyChoose/List';
import WhyChooseEdit from '../Pages/Admin/VAS/WhyChoose/[WhyChooseId]';
import HomeLoanUpdate from '../Pages/Admin/VAS/Pages/HomeLoan';
import HomeLoanOfferList from '../Pages/Admin/VAS/Pages/HomeLoanOffers/List';
import AddNewOffer from '../Pages/Admin/VAS/Pages/HomeLoanOffers/AddNewOffer';
import LoanOfferEdit from '../Pages/Admin/VAS/Pages/HomeLoanOffers/[LoanOfferId]';
import HomeInteriorUpdate from '../Pages/Admin/VAS/Pages/HomeInterior';
import UnderConstructionUpdate from '../Pages/Admin/VAS/Pages/UnderConstruction';
import BestAreaUpdate from '../Pages/Admin/VAS/Pages/BestArea';

//Pages
import Homefest from '../Pages/Admin/Pages/Homefest/Homefest';
import HomefestFeaturesList from '../Pages/Admin/Pages/Homefest/Features/HomefestFeaturesList';
import AddHomefestFeature from '../Pages/Admin/Pages/Homefest/Features/AddHomefestFeature';
import EditHomefestFeature from '../Pages/Admin/Pages/Homefest/Features/[FeatureId]';

import HomefestVideoList from '../Pages/Admin/Pages/Homefest/Videos/HomefestVideoList';
import AddHomefestVideo from '../Pages/Admin/Pages/Homefest/Videos/AddHomefestVideo';
import EditHomefestVideo from '../Pages/Admin/Pages/Homefest/Videos/[VideoId]';

import AuthContext from '../../store/auth';

export default function PageRoutes() {
  const auth = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(()=>{
    if(auth.isLoggedIn === false && location.pathname !== '/' && location.pathname !== '/forgot-password'){
      navigate('/')
    }
    if(location.pathname === '/admin'){
      navigate('/admin/dashboard')
    }
  },[])
  return (
    <Routes>
      <Route path='/' element={<Login/>} />
      <Route path="/forgot-password" element={<ForgotPassword/>} />
      <Route path="admin" element={<MainLayout/>} >
        <Route path='dashboard' element={<Dashboard/>} />

        
        {/* Home Content */}
        <Route path='home/content' element={<HomeContent/>} />
        <Route path='home/:HomepageId' element={<HomepageDetail/>} />

        {/* Home Process */}
        <Route path='home/process' element={<ProcessList/>} />
        <Route path='home/process/:ProcessId' element={<ProcessDetail/>} />
        <Route path='home/process/add-new' element={<AddNewProcess/>} />

        {/* Home Why Choose */}
        <Route path='home/why-choose' element={<WhyChooseList/>} />
        <Route path='home/why-choose/:WhyChooseId' element={<WhyChooseDetail/>} />
        <Route path='home/why-choose/add-new' element={<AddNewWhy/>} />
        

        {/* Home Service */}
        <Route path='home/value-added' element={<ValueAddedList/>} />
        <Route path='home/value-added/add-new' element={<AddValue/>} />
        <Route path='home/value-added/:ValueAddedId' element={<ValueDetail/>} />

        {/* About Content */}
        <Route path='about/content' element={<AboutContent/>} />
        <Route path='about/:AboutPageId' element={<AboutDetail/>} />

        {/* Property */}
        <Route path='property-management/properties' element={<PropertyList/>} />
        <Route path='property-management/property/add-new' element={<AddNewProperty/>} />
        <Route path='property-management/property/:PropertyId' element={<EditProperty/>} />
        <Route path='property-management/add-amenity' element={<AddNewAmenity/>} />
        <Route path='property-management/amenities' element={<AmenityList/>} />
        <Route path='property-management/amenity/:AmenityId' element={<AmenityDetail/>} />

        {/* PropertyJourney */}
        <Route path='property-journey/buyer' element={<PropertyList/>} />
        <Route path='property-journey/buyer/:JourneyId' element={<EditProperty/>} />
        <Route path='property-journey/seller' element={<SellerList/>} />
        <Route path='property-journey/seller/:JourneyId' element={<SellerDetail />} />
        <Route path='property-journey/broker' element={<BrokerList/>}/>
        <Route path='property-journey/broker/:BrokerId' element={<BrokerDetails/>}/>
        <Route path='property-journey/broker/add-broker' element={<AddBroker/>}/>
        <Route path='property-journey/broker-lead' element={<BrokerLeadList/>}/>
        <Route path='property-journey/broker-lead/:BrokerId/:BrokerLeadId' element={<BrokerLeadDetails/>}/>
        <Route path='property-journey/broker-lead/add-broker-lead' element={<AddBrokerLead/>}/>
        <Route path='property-journey/relation-manager' element={<RMList/>}/>
        <Route path='property-journey/relation-manager/:RM_Id' element={<RMDetails/>}/>
        <Route path='property-journey/relation-manager/add-rm' element={<AddNewRM/>}/>
        <Route path='property-journey/aops' element={<AopList/>}/>
        <Route path='property-journey/aops/:AopId' element={<AopDetails/>}/>
        <Route path='property-journey/aops/add-aop' element={<AddAop/>}/>

        {/* Projects */}
        <Route path='property-management/projects' element={<ProjectList/>} />
        <Route path='property-management/project/:ProjectId' element={<EditProject/>} />
        <Route path='property-management/project/add' element={<AddProject/>} />

        {/* Project Area */}
        <Route path='property-management/projectsarea' element={<ProjectAreaList/>} />
        <Route path='property-management/projectsarea/:ProjectAreaId' element={<EditProjectArea/>} />
        <Route path='property-management/projectsarea/add' element={<AddNewProjectArea/>} />

        {/* Location */}
        <Route path='property-management/location' element={<LocationList/>} />
        <Route path='property-management/add-location' element={<AddLocation/>} />
        <Route path='property-management/location/:LocationId' element={<EditLocation/>} />

        {/* Sector */}
        <Route path='property-management/sector' element={<SectorList/>} />
        <Route path='property-management/add-sector' element={<AddSector/>} />
        <Route path='property-management/sector/:SectorId' element={<EditSector/>} />

        
        {/* Seller Property */}
        <Route path='seller-property' element={<SellerPropertyList/>} />
        <Route path='seller/property/:PropertyId' element={<EditSellerProperty/>} />

        {/* Seller Property */}
        <Route path='shortlist-users' element={<ShortlistPropertyUsers/>} />
        <Route path='shortlist-users/:ShortlistId' element={<EditShortlistProperty/>} />
        

        {/* Buyer List */}
        <Route path='buyer-list' element={<BuyerList/>} />
        <Route path='buyer/:BuyerId' element={<EditBuyer/>} />

          {/* Property BHK */}
        <Route path='property-management/add-bhk' element={<AddNewBhk/>} />
        <Route path='property-management/bhk' element={<BhkList/>} />
        <Route path='property-management/bhk/:BhkId' element={<BhkDetail/>} />

         {/* Property BHK */}
         <Route path='property-management/add-developer' element={<AddNewDeveloper/>} />
        <Route path='property-management/developer' element={<DeveloperList/>} />
        <Route path='property-management/developer/:DeveloperId' element={<DeveloperDetail/>} />
        
          {/* Property NearBy */}
        <Route path='property-management/add-nearby' element={<AddNewNearby/>} />
        <Route path='property-management/nearby' element={<NearbyList/>} />
        <Route path='property-management/nearby/:NearbyId' element={<NearbyDetail/>} /> 
            {/* Property schedule */}
        <Route path='property-management/schedule' element={<ScheduleList/>} />
        

        {/* TESTIMONIAL */}
        <Route path='add-testimonials' element={<AddNewTestimonial/>} />
        <Route path='testimonials' element={<TestimonialList/>} />
        <Route path='testimonials/:TestimonialId' element={<TestimonialDetail/>} /> 

           {/* FAQ */}
        <Route path='add-faq' element={<AddNewFaq/>} />
        <Route path='faq' element={<FaqList/>} />
        <Route path='faq/:FaqId' element={<FaqDetail/>} /> 

        {/* VAS */}
        <Route path='vas/add-how-it-work' element={<AddNewHowItWork/>} />
        <Route path='vas/how-it-work' element={<HowItWorkList/>} />
        <Route path='vas/how-it-work/:HowItWorkId' element={<HowItWorkEdit/>} />

        <Route path='vas/add-why-choose-us' element={<AddNewWhyChoose/>} />
        <Route path='vas/why-choose-us' element={<WhyChooseUsList/>} />
        <Route path='vas/why-choose-us/:WhyChooseId' element={<WhyChooseEdit/>} />

        <Route path='vas/home-loan' element={<HomeLoanUpdate/>} />
        <Route path='vas/home-loan-offers' element={<HomeLoanOfferList/>} />
        <Route path='vas/add-home-loan-offer' element={<AddNewOffer/>} />
        <Route path='vas/home-loan-offer/:LoanOfferId' element={<LoanOfferEdit/>} />
        <Route path='vas/home-interior' element={<HomeInteriorUpdate/>} />
        <Route path='vas/under-construction-home' element={<UnderConstructionUpdate/>} />
        <Route path='vas/best-area' element={<BestAreaUpdate/>} />
        
           {/* Team */}
        <Route path='add-team' element={<AddNewTeam/>} />
        <Route path='team' element={<TeamList/>} />
        <Route path='team/:TeamId' element={<TeamDetail/>} /> 

        {/* Blog */}
        <Route path='add-blog' element={<AddNewBlog/>} />
        <Route path='blog' element={<BlogList/>} />
        <Route path='blog/:BlogId' element={<BlogEdit/>} /> 
        <Route path='events' element={<EventList/>} />
        <Route path='add-event' element={<AddNewEvent/>} />
        <Route path='event/:EventId' element={<EditEvent/>} />
        <Route path='awards' element={<AwardsList/>} />
        <Route path='add-award' element={<AddNewAward/>} />
        <Route path='award/:AwardId' element={<EditAward/>} />
        
        

         {/* Carrer */}
         <Route path='career/add-job' element={<AddNewJob/>} />
        <Route path='career/job' element={<JobList/>} />
        <Route path='career/job/:JobId' element={<JobDetail/>} /> 
        <Route path='career/job-enquiry' element={<JobEnquiryList/>} />
        <Route path='career/job-apply' element={<JobApplyList/>} />

        {/*Career Gallery */}
        <Route path='career/add-gallery' element={<AddNewGallery/>} />
        <Route path='career/gallery' element={<GalleryList/>} />
        <Route path='career/gallery/:GalleryId' element={<GalleryDetail/>} /> 

        {/*Career Heading */}
        <Route path='career/heading' element={<HeadingList/>} />
        <Route path='career/heading/:HeadingId' element={<HeadingDetail/>} /> 


        {/*About Gallery */}
        <Route path='about/add-gallery' element={<AddNewAboutGallery/>} />
        <Route path='about/gallery' element={<AboutGalleryList/>} />
        <Route path='about/gallery/:GalleryId' element={<AboutGalleryDetail/>} /> 

        {/*About Solution Superior */}
        <Route path='about/add-solutions-superior' element={<AddNewSolution/>} />
        <Route path='about/solutions-superior' element={<SolutionList/>} />
        <Route path='about/solutions-superior/:GalleryId' element={<SolutionDetail/>} /> 

        {/* Enquiry */}
        <Route path='enquiry/newsletter' element={<NewsletterEnquiry/>} />
        <Route path='enquiry/contact-us' element={<ContactUsEnquiry/>} />
        <Route path='enquiry/property' element={<PropertyEnquiry/>} />
        <Route path='enquiry/home-loan' element={<HomeLoanEnquiry/>} />
        <Route path='enquiry/channel-partner' element={<ChannelPartnerEnquiry/>} />
        <Route path='enquiry/homepage' element={<HomepageEnquiry/>} />
        <Route path='enquiry/lead' element={<LeadEnquiry/>} />
        <Route path='enquiry/homefest' element={<HomefestEnquiry/>} />
        <Route path='enquiry/popup-cta' element={<PopupCtaEnquiry/>} />
        <Route path='enquiry/take-tour' element={<TakeTour/>} />
        
        {/* Setting */}
        <Route path='setting/general' element={<Setting/>} />
        <Route path='setting/meta' element={<MetaList/>} />
        <Route path='setting/meta/add-new' element={<AddNewMeta/>} />
        <Route path='setting/meta/:MetaId' element={<EditMeta/>} />
        <Route path='setting/appConfig' element={<AppConfig/>} />
        <Route path='setting/configList' element={<ConfigList/>} />

        
        {/* data analytics*/}
        <Route path='setting/dataAnalytics' element={<DataAnalytics/>} />
        
        {/*User List */}
        <Route path='user/list' element={<UserList/>} />
        <Route path='user/:UserId' element={<UserDetail/>} />
        <Route path='user/seller/schedule' element={<SellerScheduleList/>} />
        <Route path='user/buyer/schedule' element={<BuyerScheduleList/>} />

        {/* Pages */}
        <Route path='page/homefest/headings' element={<Homefest/>} />
        <Route path='page/homefest/features' element={<HomefestFeaturesList/>} />
        <Route path='page/homefest/feature/add-new' element={<AddHomefestFeature/>} />
        <Route path='page/homefest/feature/:FeatureId' element={<EditHomefestFeature/>} />
        <Route path='page/homefest/videos' element={<HomefestVideoList/>} />
        <Route path='page/homefest/video/add-new' element={<AddHomefestVideo/>} />
        <Route path='page/homefest/video/:VideoId' element={<EditHomefestVideo/>} />
        
        
      </Route>
    <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}
