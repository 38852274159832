import React, {useEffect, useState} from 'react'
import Ajax from '../../../../../helpers/Ajax'
import TableLoader from '../../../../UI/Dashboard/TableLoader';
import DataView from '../../../../UI/Dashboard/DataView';
import TableContainer from '../../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../../Common/ContentLayout';
import EditButton from '../../../../UI/Dashboard/EditButton';

export default function HomeContent() {
    const [homepage, setHomepage] = useState(null)
    useEffect(()=>{
    const getResponse = setTimeout(function(){  
        (async()=>{ 
        const response = await Ajax({
            url: `/home/heading`,
            token: true,
        })
        if(response.data.status === "SUCCESS!"){
          setHomepage(response.data.result)
        }
        
        })()
    },100) 
    return()=>{
        clearInterval(getResponse)
    }
    },[])

    return (
    <ContentLayout title='Homepage'>
        <div className="row">
        <Helmet>
            <title>Homepage</title>
        </Helmet>
        <DataView title="Homepage List">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-start">Type</td>
                        <td className="text-start">Banner Title</td>
                        <td className="text-start">Banner Desp</td>
                        <td className="text-center">Action</td>
                    </tr>
                </thead>
                <tbody>
                    {homepage == null ? 
                    <TableLoader cols={5}/> : homepage.length === 0 ? 
                    <tr>
                        <td colSpan={5} className="text-center">Data not available</td>
                    </tr> : homepage.map((data, index)=>(
                    <tr key={data._id}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-start text-capitalize">{data.type}</td>
                        <td className="text-start">{data.buyerTitle}</td>
                        <td className="text-start">{data.bannerDescription}</td>
                        <td className="text-center">
                            <EditButton>{`/admin/home/${data._id}`}</EditButton>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
        </DataView>
    </div>
    </ContentLayout>
    )
}
