import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { Helmet } from 'react-helmet-async'
import GetState from '../../../../../helpers/GetState'
import GetCity from '../../../../../helpers/GetCity'
import GetLocation from '../../../../../helpers/GetLocation'
import GetSector from '../../../../../helpers/GetSector'
import GetAmenity from '../../../../../helpers/GetAmenity'

export default function HomepageDetail() {
    const [infoDetail, setInfoDetail] = useState(null)
    const [getStateList, setStateList] = useState(null)
    const urlParams = useParams()
    const [homeBanner, setHomeBanner] = useState(null)
    const [states, setStates] = useState(null)
    const [city, setCity] = useState(null)
    const [location, setLocation] = useState(null)
    const [sector, setSector] = useState(null)
    const [selectedState, setSelectedState] = useState('')
    const [selectedCity, setSelectedCity] = useState('')
    const [selectedLocation, setSelectedLocation] = useState('')
    const [selectedSector, setSelectedSector] = useState()
    const [productDetail, setProductDetail] = useState(null)
    const [amenities, setAmenities] = useState(null)
    const [addedAmenity, setaddedAmenity] = useState([])
    const [addedProjectPhotos, setAddedProjectPhotos] = useState([])
    const [thumbnailBanner, setThumbanailBanner] = useState()

    const dataIcon = useRef()
    const dataIcon2 = useRef()
    const getState = useRef()
    const getCity = useRef()
    const getLocation = useRef()
    const projectImage = useRef()
    const GetSectorrrr = useRef()
    const nearByIcon1 = useRef()
    const nearByIcon2 = useRef()
    const nearByIcon3 = useRef()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
                (async()=>{
                    const amenity = await GetAmenity()
                    const amenityArray = []
                    for(let i in amenity){
                        amenityArray.push({
                            label: `${amenity[i].name}`,
                            value: amenity[i]._id,
                        })
                    }
                    setAmenities(amenityArray)
                    })();
                const stateList = await GetState()
                setStates(stateList)
                const response = await Ajax({
                    url: `/developer/getDeveloperList?_id=${urlParams.DeveloperId}`,
                    token: true
                  })
                  if(response.data.status === "SUCCESS!"){
                    if(response.data.result[0].projectPhotos && response.data.result[0].projectPhotos.length > 0){
                        let projectPhotosId = []
                        response.data.result[0].projectPhotos.map((item)=>{
                            projectPhotosId.push(item)
                        })
                        setAddedProjectPhotos(projectPhotosId)
                    }
                    if(response.data.result[0]?.banner){
                        setThumbanailBanner(response.data.result[0]?.banner)
                    }
                    setInfoDetail(response.data.result[0])
                    setSelectedState(response.data.result[0].state._id)
                    setProductDetail(response.data.result[0])
                    const cityFetch = await GetCity(response.data.result[0].state._id)
                    setCity(cityFetch)
                    const amenityData = (response && response.data && response.data.result[0] && response.data.result[0].amenities) ? response.data.result[0].amenities : [];
                    if(amenityData && amenityData.length > 0){
                        const addedAmenityArray = []
                        amenityData.forEach((e)=>{
                            addedAmenityArray.push({
                                label: e.name,
                                value: e._id,
                            })
                    })
                    setaddedAmenity(addedAmenityArray)
                    }
                    setSelectedCity(response.data.result[0].city._id)

                    const locationFetch = await GetLocation(response.data.result[0].city._id)
                    setLocation(locationFetch)
                    let location =  response.data.result[0].location
                    if(location){
                        setSelectedLocation(location._id)
                        const sectorFetch = await GetSector(location._id)
                        setSector(sectorFetch)
                    }else{
                        setSelectedLocation("")
                    }
                    let sector =  response.data.result[0]?.sector
                    if(sector){
                        setSelectedSector(sector._id ? sector._id : "")
                    }else{
                        setSelectedSector("")
                    }
                  }

            })()
          },100)
           
          return()=>{
            clearInterval(getResponse)
          }
    },[])
    const updateHome = async (values, event) => {
        
        const formData = new FormData()
        if(dataIcon.current.value != ''){
            formData.append("file", dataIcon.current.files[0])
        }

        // if(dataIcon2.current.value != ''){
        //     formData.append("blog", dataIcon2.current.files[0])
        // }
        let addedAmenities = []
        if(addedAmenity.length <= 0){
            toast.error('Amenities are required',{
                toastId: 'iconRequired'
            })
            return false
        }else{
            for(let i = 0; i < addedAmenity.length; i++){
                addedAmenities.push(addedAmenity[i].value)
              }
        }
        const data = {
            name: values.name, 
            description: values.description, 
            bannerTitle: values.bannerTitle, 
            metaDescription: values.metaDescription, 
            metaKeyword: values.metaKeyword, 
            metaTitle: values.metaTitle, 
            title: values.title, 
            location: selectedLocation, 
            city: selectedCity, 
            about: values.about,
            youtube: values.youtube ? values.youtube : "",
            map: values.map, 
            state: selectedState, 
            sector: selectedSector, 
            sortOrder: values.sortOrder,
            active: values.active == 1 ? true : false,
            projectPhotos:addedProjectPhotos,
            banner:thumbnailBanner,
            nearbyTitle1: values.nearbyTitle1,
            nearbySubTitle1: values.nearbySubTitle1,
            nearbyTitle2: values.nearbyTitle2,
            nearbySubTitle2: values.nearbySubTitle2,
            nearbyTitle3: values.nearbyTitle3,
            nearbySubTitle3: values.nearbySubTitle3,
            drone: values.drone,
            amenities: addedAmenities,
            landmark: values.landmark
        }
        for(var key in data) {
            if(data[key] === "" && key != 'youtube' && key != "drone" &&  key != 'nearbyTitle1' &&  key != 'nearbySubTitle1' &&  key != 'nearbyTitle2' &&  key != 'nearbySubTitle2' &&  key != 'nearbyTitle3' &&  key != 'nearbySubTitle3' && key != "landmark" && key != "sector") {
                toast.error(`Some Fields are Empty please check ${key}`,{
                    toastId: 'EmptyFields'
                })
                return false
            }
            if(data[key] !== "" || key == 'youtube' || key == "drone" ||  key == 'nearbyTitle1' ||  key == 'nearbySubTitle1' ||  key == 'nearbyTitle2' ||  key == 'nearbySubTitle2' ||  key == 'nearbyTitle3' ||  key == 'nearbySubTitle3' || key == "landmark" || key == "sector") {
                
                if(key == "amenities"){
                    formData.append(key, JSON.stringify(data[key]))
                }else if(key == "projectPhotos"){
                    formData.append(key,JSON.stringify(data[key])) 
                }else if(key == "banner"){
                    formData.append(key,JSON.stringify(data[key])) 
                }else{
                    formData.append(key, data[key])
                }
            }
        } 
        if(addedProjectPhotos.length <=0 && projectImage.current.value === ''){
            toast.error('Project Photos are required',{
                toastId: 'iconRequired'
            })
            return false
        }
        // if(projectImage.current.value !== ''){
        //     for(let i = 0; i < projectImage.current.files.length; i++){
        //         formData.append("coverPhoto", projectImage.current.files[i])
        //     }
        // }
        if(nearByIcon1.current.value !== ''){
            for(let i = 0; i < nearByIcon1.current.files.length; i++){
                formData.append("nearbyIcon1", nearByIcon1.current.files[i])
            }
        }
        if(nearByIcon2.current.value !== ''){
            for(let i = 0; i < nearByIcon2.current.files.length; i++){
                formData.append("nearbyIcon2", nearByIcon2.current.files[i])
            }
        }
        if(nearByIcon3.current.value !== ''){
            for(let i = 0; i < nearByIcon3.current.files.length; i++){
                formData.append("nearbyIcon3", nearByIcon3.current.files[i])
            }
        }
        const update = await Ajax({
            url: `/developer/${urlParams.DeveloperId}`,
            method: 'PUT',
            token: true,
            data: formData,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Developer Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
       
    }
        //Seelct State for City
        const chooseState = async(e) => {
            console.log(e.target,"target")
            var stateId = e.target.value
            const cityFetch = await GetCity(stateId)
            setCity(cityFetch)
            setSelectedState(stateId)
        }

        //Select Amenity
    const handleAmenity = (selectedAmenity) => {
        setaddedAmenity([...selectedAmenity])
    }

        const deleteProductImage = async(id) => {
            const deleteResponse = await Ajax({
                url: `/file/${id}`,
                method: 'DELETE',
                loader:true,
                token: true
            })
            if(deleteResponse.data.error){
                toast.error(deleteResponse.data.error)
            }
            if(deleteResponse.data.status === 'SUCCESS!'){
                if(addedProjectPhotos.includes(id)){
                 let filterAddedPhotos = addedProjectPhotos.filter((item)=>{
                          return item !== id
                    })
                 setAddedProjectPhotos(filterAddedPhotos)
                }
                toast.success('Image deleted successfully!')
                document.querySelector("[data-image-thumb='"+id+"']").remove()
            }
        }
    
        //Select City for Location
        const chooseCity = async(e) => {
            var cityId = e.target.value
            const locationFetch = await GetLocation(cityId)
            setLocation(locationFetch)
            setSelectedCity(cityId)
        }
    
        //Select Location for sector
        const chooseLocation = async(e) => {
            var locationId = e.target.value
            const sectorFetch = await GetSector(locationId)
            setSector(sectorFetch)
            setSelectedLocation(locationId)
        }
        const handleImageSize =(e)=>{
            const files = e.target.files || []
            if(files && files.length > 0){
                for(const file of files){
                if(file.size > 1024*1024) {
                    toast.error('Image size should not exceed 1 MB',{
                        toastId: 'iconRequired'
                    })
                    e.target.value = ''
                    return false
                }else{
                    return true;
                }
            }
            }
        }

        const uploadImage = async (e) =>{
            try{
                const name = e.target.name
                const imageSize = handleImageSize(e)
                if(imageSize){
                const formData = new FormData();
                if(name === "projectPhotos" && projectImage.current.value !== ''){
                    for(let i = 0; i < projectImage.current.files.length; i++){
                        formData.append("coverPhoto", projectImage.current.files[i])
                    }
                }
                if(name === "thumbnail" && dataIcon2.current.value !== ''){
                    for(let i = 0; i < dataIcon2.current.files.length; i++){
                        formData.append("thumbnail", dataIcon2.current.files[i])
                    }
                }
                const result = await Ajax({
                    url: `/imageUpload`,
                    method: 'POST',
                    data: formData,
                    loader:true,
                    token: true
                })
                if(result.data.error){
                    toast.error(result.data.error)
                }
                if(result.data.status === 'SUCCESS!'){
                    toast.success('Image upload successfully!')
                    const uploadFiles = result.data.result.files
                    if(name === "projectPhotos") setAddedProjectPhotos([...addedProjectPhotos,...uploadFiles])
                    else if (name === "thumbnail") setThumbanailBanner(...uploadFiles)
                }
                }
            }catch(error){
                console.log(error)
            }
           
        }

        const handleTagName = async (e,id) =>{
            const tagName = e.target.value
            const name = e.target.name
            if(name == "projectPhotos"){
                const projectPhotos = addedProjectPhotos.map((item)=>{
                    if(item._id == id) {
                        item.tagName = tagName
                    }
                    return item
                })
                setAddedProjectPhotos(projectPhotos)
            }
            else if(name == "thumbnail"){
                const updatedThumbnailBanner = { ...thumbnailBanner, tagName: tagName };
                setThumbanailBanner(updatedThumbnailBanner);
            }
        }
    return (
        <ContentLayout title="Edit Developer">
            <Helmet>
                <title>Edit Developer</title>
            </Helmet>
            <DataView title="Infomation">
            {infoDetail === null ? <DataLoader/> : 
                <Formik
                    initialValues={{ 
                        name: infoDetail.name ? infoDetail.name : '',
                        description: infoDetail.description ? infoDetail.description : '', 
                        bannerTitle:infoDetail.bannerTitle ? infoDetail.bannerTitle : '',
                        metaDescription: infoDetail.metaDescription ? infoDetail.metaDescription : '',
                        metaKeyword: infoDetail.metaKeyword ? infoDetail.metaKeyword : '',
                        metaTitle: infoDetail.metaTitle ? infoDetail.metaTitle : '',
                        title: infoDetail.title ? infoDetail.title : '',
                        location: infoDetail.location ? infoDetail.location : '',
                        city: infoDetail.city ? infoDetail.city : '',
                        about: infoDetail.about ? infoDetail.about : '',
                        youtube: infoDetail.youtube ? infoDetail.youtube : '',
                        map: infoDetail.map ? infoDetail.map : '',
                        state: infoDetail.state ? infoDetail.state : '',
                        sector: infoDetail?.sector ? infoDetail?.sector : '',
                        sortOrder: infoDetail.sortOrder ? infoDetail.sortOrder : 0,
                        active: infoDetail.active === true ? 1 : 0,
                        projectPhotos: productDetail?.projectPhotos ? productDetail?.projectPhotos : [],
                        drone:productDetail?.drone ? productDetail?.drone : "",
                        landmark: productDetail?.landmark ? productDetail?.landmark : "",
                        nearbyTitle1: productDetail.nearbyTitle1 ? productDetail.nearbyTitle1 : '',
                        nearbySubTitle1: productDetail.nearbySubTitle1 ? productDetail.nearbySubTitle1 : '',
                        nearbyTitle2: productDetail.nearbyTitle2 ? productDetail.nearbyTitle2 : '',
                        nearbySubTitle2: productDetail.nearbySubTitle2 ? productDetail.nearbySubTitle2 : '',
                        nearbyTitle3: productDetail.nearbyTitle3 ? productDetail.nearbyTitle3 : '',
                        nearbySubTitle3: productDetail.nearbySubTitle3 ? productDetail.nearbySubTitle3 : '', 
                        amenities:addedAmenity
                        }}
                    validationSchema={Yup.object({
                        //name: Yup.string().required('Field is Required'),
                        //description: Yup.string().required('Field is Required'),
                        //bannerTitle: Yup.string().required('Field is Required'),
                        //metaDescription: Yup.string().required('Field is Required'),
                        //metaKeyword: Yup.string().required('Field is Required'),
                        //metaTitle: Yup.string().required('Field is Required'),
                        //title: Yup.string().required('Field is Required'),
                        // location: Yup.string().required('Field is Required'),
                        // city: Yup.string().required('Field is Required'),
                        //about: Yup.string().required('Field is Required'),
                        //map: Yup.string().required('Field is Required'),
                        // state: Yup.string().required('Field is Required'),
                        // sector: Yup.string().required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        updateHome(values, event)
                    }}
                >
                <Form>
                    <ul className="nav nav-tabs">
                    <li className="nav-item"><a href="#tab-general" data-bs-toggle="tab" className="nav-link active">General</a></li>
                    <li className="nav-item"><a href="#tab-banner" data-bs-toggle="tab" className="nav-link ">Banner</a></li>
                    <li className="nav-item"><a href="#tab-developer" data-bs-toggle="tab" className="nav-link ">Developer</a></li>
                    <li className="nav-item"><a href="#tab-near-buy" data-bs-toggle="tab" className="nav-link">Near By</a></li>
                    <li className="nav-item"><a href="#tab-meta" data-bs-toggle="tab" className="nav-link ">Meta Data</a></li>
                    </ul>
                    <div className="tab-content">
                    <div className="tab-pane active" id="tab-general">
                            <div className='row'>
                            <div className='col-md-12'>
                            <FormGroup label="Title" required>
                                <Field placeholder='Title' name="title" className='form-control' />
                                <ErrorField><ErrorMessage name="title"/></ErrorField>
                            </FormGroup>
                            </div>
                           
                            </div>
                          
                            <div className='row'>
                                <div className='col-md-12'>
                                    <FormGroup label="Project Description" required>
                                        <Field as="textarea" placeholder='Description' name="about" className='form-control' />
                                        <ErrorField><ErrorMessage name="about"/></ErrorField>
                                    </FormGroup>
                                    <FormGroup label="Sort Order" required>
                                        <Field type="number" placeholder='Sort Order' name="sortOrder" className='form-control' />
                                        <ErrorField><ErrorMessage name="sortOrder"/></ErrorField>
                                    </FormGroup>
                                </div>
                               
                            </div>
                            <FormGroup label="Active" required>
                                <Field as="select" type="number" name="active" className='form-select'>
                                    <option value={1}>Enable</option>
                                    <option value={0}>Disable</option>
                                </Field>
                                <ErrorField><ErrorMessage name="active"/></ErrorField>
                            </FormGroup>
                            
                        </div>
                    
                        <div className="tab-pane " id="tab-banner">
                            <FormGroup label="Banner Image" required>
                                <div className='col-md-12'>
                                {infoDetail.file?
                                    <img src={infoDetail.file.path} alt="prcess icon" height="100" width="100" className='img-fluid' /> 
                                    :''}
                                    <input type="file" ref={dataIcon} onChange={handleImageSize} name="banner_image" className='form-control' />
                                </div>
                                <div className='col-md-3 mt-3'>
                                </div>
                            </FormGroup>
                            <FormGroup label="Banner Title" required>
                                <Field placeholder='Banner Title' name="bannerTitle" className='form-control' />
                                <ErrorField><ErrorMessage name="bannerTitle"/></ErrorField>
                            </FormGroup>
                          
                        </div>

                        
                        <div className="tab-pane " id="tab-developer">
                            <div className='row'>
                            <div className='col-md-6'>
                            <FormGroup label="Name" required>
                                <Field placeholder='Name' name="name" className='form-control' />
                                <ErrorField><ErrorMessage name="name"/></ErrorField>
                            </FormGroup>
                            </div>
                            <div className='col-md-6'>
                            
                                     <FormGroup label="Thumbnail Image" required>
                                    
                                    <input type="file" name="thumbnail" onChange={uploadImage} ref={dataIcon2} className='form-control' />
                            </FormGroup>
                            <div className='image-thumbs mb-3 gap-8'>
                            {thumbnailBanner ?
                                     <div className='single-thumb'>
                                    <img src={thumbnailBanner.path}  alt="prcess icon" width={97} height={67}  /><br/>
                                    <input type='text' value={thumbnailBanner.tagName} onChange={((event)=>handleTagName(event,thumbnailBanner._id))} name="thumbnail" style={{width:"115px",marginTop:"6px", borderRadius:"2px", border:"1px solid"}}/>
                                     </div>
                                    :''}
                                    </div>
                            </div>
                            <div className='col-md-6'>
                                    <FormGroup label="Project Photos" required>
                                        <input type="file" name="projectPhotos" onChange={uploadImage} ref={projectImage} className='form-control' multiple accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="projectPhotos"/></ErrorField>
                                    </FormGroup>
                                    {addedProjectPhotos && addedProjectPhotos.length > 0 && 
                                    <div className='image-thumbs mb-3 gap-8'>
                                        {addedProjectPhotos?.map((e)=>(
                                            <div key={e._id} className='single-thumb' data-image-thumb={e._id}>
                                                <button type="button" onClick={()=>deleteProductImage(e._id)} className="btn btn-danger">
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                                <img src={e.path} width={97} height={67} /><br/>
                                                <input type='text' value={e.tagName} onChange={((event)=>handleTagName(event,e._id))} name="projectPhotos" style={{width:"115px",marginTop:"6px", borderRadius:"2px", border:"1px solid"}}/>
                                            </div>
                                        ))}
                                    </div> }
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Drone Video (Youtube Id)" >
                                        <Field placeholder="id" name="drone" className='form-control' />
                                        <ErrorField><ErrorMessage name="drone"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-12'>
                                <FormGroup label="Project Videos (Youtube Id)" >
                                    <Field as="textarea" placeholder='Youtube Id' name="youtube" className='form-control'/>
                                    <ErrorField><ErrorMessage name="youtube"/></ErrorField>
                                </FormGroup>
                                </div>
                            <div className='col-md-3'>
                                    <FormGroup label="State" required>
                                        <select name="state"  className='form-select' value={selectedState} onChange={chooseState}>
                                            <option value="" disabled>Select</option>
                                            {states === null ? <option disabled>Loading</option> : states.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="state"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup label="City" required>
                                        <select name="city"  value={selectedCity} className='form-select' onChange={chooseCity}>
                                            <option value="" disabled>Select</option>
                                            {city === null ? <option disabled>Select State</option> : city.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="state"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup label="Location" required>
                                        <select name="locationId" value={selectedLocation} className='form-select' onChange={chooseLocation}>
                                            <option value="" disabled>Select</option>
                                            {location == null ? <option disabled>Loading</option> : location.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="locationId"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup label="Sector" >
                                        <select name="sector" value={selectedSector} className='form-select' onChange={(e)=>setSelectedSector(e.target.value)}>
                                            <option value="">None</option>
                                            {sector == null ? <option disabled>Loading</option> : sector.map((data)=>(
                                                <option key={data._id} value={data._id}>{data.name}</option>
                                            ))}
                                        </select>
                                        <ErrorField><ErrorMessage name="sector"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Landmark" >
                                        <Field name="landmark" placeholder="Landmark" className='form-control'/>
                                        <ErrorField><ErrorMessage name="landmark"/></ErrorField>
                                    </FormGroup>
                                    </div>
                                    <div className='col-md-6'>
                                    <FormGroup label="Select Amenity" required>
                                        <div className='col-md-12'>
                                            {amenities !== null ?
                                            <Select 
                                                value={addedAmenity}
                                                options={amenities} 
                                                onChange={handleAmenity}
                                                closeMenuOnSelect={false}
                                                isMulti
                                             /> : 'Loading...' }
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className='col-md-12'>
                                <FormGroup label="Google Map Iframe" required>
                                    <Field as="textarea" placeholder='Google Map Iframe' name="map" className='form-control' />
                                    <ErrorField><ErrorMessage name="map"/></ErrorField>
                                </FormGroup>
                                </div>
                                <div className='col-md-12'>
                                <FormGroup label="Open For Sell Flat In Project" required>
                                    <Field as="textarea" placeholder='Short Description' name="description" className='form-control' />
                                    <ErrorField><ErrorMessage name="description"/></ErrorField>
                                </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-near-buy">
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup label="Title" >
                                        <Field name="nearbyTitle1" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbyTitle1"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-7'>
                                    <FormGroup label="Sub Title" >
                                        <Field name="nearbySubTitle1" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbySubTitle1"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-2' >
                                    <FormGroup label="Icon" >
                                        <input type="file" name="nearbyIcon1" ref={nearByIcon1} onChange={handleImageSize} className='form-control' accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="nearbyIcon1"/></ErrorField>
                                    </FormGroup>
                                    {productDetail.nearbyIcon1 && 
                                    <img src={productDetail.nearbyIcon1.path} width={80} /> }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup label="Title" >
                                        <Field name="nearbyTitle2" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbyTitle2"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-7'>
                                    <FormGroup label="Sub Title" >
                                        <Field name="nearbySubTitle2" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbySubTitle2"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-2'>
                                    <FormGroup label="Icon" >
                                        <input type="file" name="nearbyIcon2" ref={nearByIcon2} onChange={handleImageSize} className='form-control' accept='.jpg, .jpeg, .png, .webp' />
                                        <ErrorField><ErrorMessage name="nearbyIcon2"/></ErrorField>
                                    </FormGroup>
                                    {productDetail.nearbyIcon2 && 
                                    <img src={productDetail.nearbyIcon2.path} width={80} /> }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup label="Title" >
                                        <Field name="nearbyTitle3" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbyTitle3"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-7'>
                                    <FormGroup label="Sub Title" >
                                        <Field name="nearbySubTitle3" className='form-control'/>
                                        <ErrorField><ErrorMessage name="nearbySubTitle3"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-2'>
                                    <FormGroup label="Icon" >
                                        <input type="file" name="nearbyIcon3" ref={nearByIcon3} onChange={handleImageSize} className='form-control' accept='.jpg, .jpeg, .png, .webp'/>
                                        <ErrorField><ErrorMessage name="nearbyIcon3"/></ErrorField>
                                    </FormGroup>
                                    {productDetail.nearbyIcon3 && 
                                    <img src={productDetail.nearbyIcon3.path} width={80} /> }
                                </div>
                            </div>
                        </div>
                        
                        <div className="tab-pane " id="tab-meta">
                            <div className='row'>
                                <div className='col-md-12'>
                                <FormGroup label="Meta Title" required>
                                <Field placeholder='Meta Title' name="metaTitle" className='form-control' />
                                <ErrorField><ErrorMessage name="metaTitle"/></ErrorField>
                            </FormGroup>
                            </div>

                            <div className='col-md-12'>
                                <FormGroup label="Meta Keyword" required>
                                <Field placeholder='Meta Keyword' name="metaKeyword" className='form-control' />
                                <ErrorField><ErrorMessage name="metaKeyword"/></ErrorField>
                            </FormGroup>
                            </div>

                            <div className='col-md-12'>
                                <FormGroup label="Meta Description" required>
                                <Field as="textarea" placeholder='Meta Description' name="metaDescription" className='form-control' />
                                <ErrorField><ErrorMessage name="metaDescription"/></ErrorField>
                            </FormGroup>
                            </div>

                            </div>
                        </div>
                        
                        
                       
                       
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik> 
            }
            </DataView>
        </ContentLayout>
    )
}
