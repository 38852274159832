import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AddNewAmenity() {
    const [productList,setInfoDetail] = useState(null)
    const urlParams = useParams()
    const navigate = useNavigate()
    const dataIcon = useRef()

    //get property list
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/product?limit=false`,
                token: true
              })
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result.productList)
              }
            })()
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])




    const addProcess = async (values, event) => {
        if(dataIcon.current.value === ''){
            toast.error('Icon is Required',{
                toastId: 'iconRequired'
            })
            return false
        }
        const formData = new FormData()
        if(dataIcon.current.value != ''){
            formData.append("file", dataIcon.current.files[0])
        }
        const data = {
            name: values.nearby_title, 
            productId: values.productId, 
            sortOrder : values.sortOrder , 
            active: values.active == 1 ? true : false, 
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/product/nearby`,
            method: 'POST',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Nearby Infra Added Successfully')
            setTimeout(function(){
                navigate('/admin/property-management/add-nearby')
            },500)
        }
    }
    return (
        <ContentLayout title="Add Nearby Infra">
            <Helmet>
                <title>Add Nearby Infra</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{ 
                        nearby_title: '', 
                        productId:'',
                        sortOrder:'',
                        active: '1',
                    }}
                    validationSchema={Yup.object({
                        nearby_title: Yup.string()
                        .required('Field is Required'),
                        productId: Yup.string()
                        .required('Field is Required'),
                        sortOrder: Yup.string()
                        .required('Field is Required')
                       
                    })}
                    onSubmit={(values, event) => {
                        addProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                    <div className='col-md-4'>
                    <FormGroup label="Property Name" required>
                        <Field as="select" name="productId" className='form-select'>
                           {productList === null? <option value='' disabled>Searching...</option> :  productList.map(e=>(
                                <option key={e._id} value={e._id}>{e.name+' ('+e.unitNumber+')'}</option>
                           ))}
                        </Field>
                        <ErrorField><ErrorMessage name="productId"/></ErrorField>
                    </FormGroup>
                    </div>
                        <div className='col-md-4'>
                            <FormGroup label="Nearby Infra Title" required>
                                <Field placeholder='Nearby Infra Title' name="nearby_title" className='form-control' />
                                <ErrorField><ErrorMessage name="nearby_title"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Sort Order" required>
                                <Field placeholder='Sort Order' name="sortOrder" type="number" className='form-control' />
                                <ErrorField><ErrorMessage name="sortOrder"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Nearby Infra Icon" required>
                                <input type="file" accept='.jpg, .png, jpeg, .webp' name="nearby_image" ref={dataIcon} className='form-control' />
                            </FormGroup>
                        </div>
                    </div>

                    
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add</Button>
                    </div>
                </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}
