import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../UI/Dashboard/DataView'
import Ajax from '../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../UI/Form/FormGroup'
import ErrorField from '../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../UI/Dashboard/DataLoader'
import Button from '../../../UI/Form/Button'
import ContentLayout from '../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from "react-router-dom"

export default function AmenityDetail() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()
    const dataIcon = useRef()
    const navigate = useNavigate()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/faq?_id=${urlParams.FaqId}`,
                token: true
              })
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }


           
            })()
          },100)    
        
         
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateProcess = async (values, event) => {
       
        const data = {
            description:values.description,
            heading:values.heading,
            type:values.type,
            active: values.active == 1 ? true : false, 
        }
       
        const update = await Ajax({
            url: `/faq/${urlParams.FaqId}`,
            method: 'PUT',
            token: true,
            data: data,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Faq Updated Successfully')
            setTimeout(function(){
                navigate(0)
            },500)
        }
    }
    return (
        <ContentLayout title="Edit Faq">
            <Helmet>
                <title>Edit Faq</title>
            </Helmet>
            <DataView title="Infomation">
              
            {infoDetail === null   ? <DataLoader/> : 
                <Formik
               
                    initialValues={{ 
                        description: infoDetail.description ? infoDetail.description: '', 
                        heading: infoDetail.heading ? infoDetail.heading : '', 
                        type: infoDetail.type ? infoDetail.type : '', 
                        active: infoDetail.active === true ? 1 : 2
                    }}
                    validationSchema={Yup.object({
                        description: Yup.string().required('Field is Required'),
                        type: Yup.string().required('Field is Required'),
                        heading: Yup.string().required('Field is Required'),
                        active: Yup.string().required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        updateProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'> 
                    
                    <div className='col-md-6'>
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="heading" className='form-control' />
                                <ErrorField><ErrorMessage name="heading"/></ErrorField>
                            </FormGroup>
                        </div>
                       
                       
                        <div className='col-md-6'>
                            <FormGroup label="Type" required>
                                <Field as="select" name="type"  className='form-select' >
                                    <option value='' disabled>Select Type</option>
                                    <option value='SELLER'>Seller</option>
                                    <option value='BUYER'>Buyer</option>
                                    <option value='HOME-LOAN'>VAS - Home Loan</option>
                                    <option value='HOME-INTERIOR'>VAS - Home Interior</option>
                                    <option value='UNDER-CONTRUCTION'>VAS - Under Construction</option>
                                    <option value='BEST-AREA'>VAS - Best Area</option>
                                </Field>
                                <ErrorField><ErrorMessage name="type"/></ErrorField>
                            </FormGroup>
                        </div>
                       
                        <div className='col-md-6'>
                        <FormGroup label="Active" required>
                        <Field as="select" name="active" className='form-select'>
                            <option value="1">Enable</option>
                            <option value="2">Disable</option>
                        </Field>
                        <ErrorField><ErrorMessage name="type"/></ErrorField>
                    </FormGroup>
                    </div>
                    <div className='col-md-12'>
                            <FormGroup label="Testimonial" required>
                                <Field as="textarea" name="description"  className='form-control' />
                                <ErrorField><ErrorMessage name="description"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                   
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik> }
            </DataView>
        </ContentLayout>
    )
}
