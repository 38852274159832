
import React, { useEffect, useState } from "react";
import JourneyAjax from "../../helpers/JourneyAjax";
import { toast } from "react-toastify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormGroup from "../UI/Form/FormGroup";
import ErrorField from "../UI/Form/ErrorField";
import Button from "../UI/Form/Button";
import Modal from 'react-modal';

export default function Notification({screen,url}) {
  const [screens,setScreens] = useState([{title:"Home",link:"/home"},{title:"Your Leads",link:"/leads"},{title:"Our Properties",link:"/projects"},{title:"Invoices & Reciepts",link:"/invoices"},{title:"Selected Property",link:"/selectedProperty"},{title:"Register",link:"/register"},{title:"Documentaion",link:"/documentation"},{title:"Profile Edit ",link:"/brokerDetails"},{title:"Login",link:"/login"}])
  const userValidToken = localStorage.getItem("userToken");
  const [isOpen, setIsOpen] = useState(false);
  const [notificationFilter, setNotificationFilter] = useState([])
  const [title, setTitle] = useState("")
  const [body, setBody] = useState("")
  const [redirectScreen, setRedirectScreen] = useState("/home")
   Modal.setAppElement('#root');

   useEffect(() => {
    if(screen){
      setRedirectScreen(screen)
    }
  }, []);
    const sendNotification = async (values) =>{
        if(!body || !title ){
          toast.error("Some Fields are Empty please check");
        }else{
        let filter = {
          where: {}
        }
        const data = {
          title:title,
          body:body,
          workFlowId:`${process.env.REACT_APP_NOTIFICATION_WORKFLOW}` ?? "",
          data:{
            branchLink:values.branchLink ?? "",
            screen: redirectScreen ?? "/home"
          }
        }
        const update = await JourneyAjax({
          url: url,
          "x-api-key": "houseeazy-dev",
          "x-user": userValidToken || null,
          method: "POST",
          data: data,
          // token: true,
        });
        if (update.data.error) {
          toast.error("Something Went Wrong Please Check");
        }
        if (update.status === 200) {
          toast.success(update.data.message)
          setIsOpen(false);
          setBody("")
          setTitle("")
          setNotificationFilter([])
          setRedirectScreen("/home")

        }
      }
      }
      const openModal = () => {
        setIsOpen(true);
      };
    
      const closeModal = () => {
        setIsOpen(false);
        setBody("")
        setTitle("")
        setNotificationFilter([])
        setRedirectScreen("/home")
      };
    
      const handleTitle = (e) =>{
        const msg = e.target.value
         if(msg.length < 50){
          setTitle(msg)
         }
      }
    
      const handleBody = (e) =>{
        const msg = e.target.value
        if(msg.length < 250){
          setBody(msg)
         }
      }

      const handleScreen = (e) =>{
        setRedirectScreen(e.target.value)
      }

  return (<div style={{ textAlign: 'right'}}>
                    <input type="button" onClick={openModal} value="Send notification" className="btn btn-primary" />
                    <Modal
                      isOpen={isOpen}
                      onRequestClose={closeModal}
                      contentLabel="Notification Popup"
                    >
                      <div className="container">
                      <div className="md-col-6">
                      <div style={{textAlign:'right'}}> 
                        <button onClick={closeModal}><i className="fas fa-times" style={{"font-size":"24px"}}></i></button>
                      </div>
                        <center><h1>Send notification</h1></center>
                        <div className='row d-flex align-items-center, justify-content-center'>
                          <div className='col-md-6'>
                          <Formik
                                initialValues={{
                                  title: "",
                                  body: "",
                                  screen:""
                                }}
                                onSubmit={(values, event) => {
                                  sendNotification(values, event);
                                }}
                              >
                                <Form>
                            <div className="row">
                              <div className="col-md-12">
                            <FormGroup label="Title" required>
                              <Field type="text" onChange={handleTitle} placeholder='Title' name="title" value={title}  className='form-control' />
                              <ErrorField><ErrorMessage name="title" /></ErrorField>
                            </FormGroup>
                            </div>
                            <div className="col-md-12" >
                            <FormGroup label="Body" required>
                              <Field type="text" placeholder='Body' onChange={handleBody} name="body" value={body}  className='form-control' />
                              <ErrorField><ErrorMessage name="body" /></ErrorField>
                            </FormGroup>
                            </div>
                            {!screen && 
                            <div className="col-md-12">
                                  <FormGroup label="Screen">
                                    <Field
                                      as="select"
                                      name="screen"
                                      className="form-select"
                                      onChange={handleScreen}
                                      value={redirectScreen}
                                    >
                                      <option value="">Select</option>
                                      {screens && screens.map((item)=>(
                                          <option value={item.link}>{item.title}</option>
                                      ))}
                                    </Field>
                                    <ErrorField>
                                      <ErrorMessage name="screen" />
                                    </ErrorField>
                                  </FormGroup>
                                </div>
                               }
                            </div>
                              <br/>
                              <div className="width-100% justify-content-center d-grid">
                                <Button type="submit">Send</Button>
                              </div>
                            </Form>
                         </Formik>
                          </div>
                        </div>
                      </div>
                      </div>
                    </Modal>
                  </div>
   )
}