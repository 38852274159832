import React from 'react'

export default function FormGroup({label, children, icon, full, required}) {
  return !full ? (
    <div className={`row mb-3 ${required ? 'required' : ''}`}>
        <label className="form-label text-start col-form-label">{label}</label>
        <div className="input-group">
            {icon && <div className="input-group-text"><i className={icon}></i></div> }
            {children}
        </div>
    </div>
  ) : (
    <div className={`row mb-3 ${required ? 'required' : ''}`}>
          <label className="col-sm-2 col-form-label">{label}</label>
          <div className="col-sm-10">
              <div className="input-group">
              {icon && <div className="input-group-text"><i className={icon}></i></div> }
              {children}
              </div>
          </div>
      </div>
  )
}
