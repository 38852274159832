import React, {useEffect, useState} from 'react'
import Ajax from '../../../../../helpers/Ajax'
import TableLoader from '../../../../UI/Dashboard/TableLoader';
import DataView from '../../../../UI/Dashboard/DataView';
import TableContainer from '../../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../../Common/ContentLayout';
import EditButton from '../../../../UI/Dashboard/EditButton';
import DeleteButton from '../../../../UI/Dashboard/DeleteButton';

export default function AmenityList() {
    const [dataList, setDataList] = useState(null)
    useEffect(()=>{
    const getResponse = setTimeout(function(){  
        (async()=>{ 
        const response = await Ajax({
            url: `/about/solution?limit=false`,
            token: true,
        })
        console.log(response)
        if(response.data.status === "SUCCESS!"){
            setDataList(response.data.result)
        }
       
        })()
    },100) 
    return()=>{
        clearInterval(getResponse)
    }
    },[])

    return (
    <ContentLayout title='Solution Superior' addNew='/admin/about/add-solutions-superior'>
        <div className="row">
        <Helmet>
            <title>Solution Superior</title>
        </Helmet>
        <DataView title="Solution Superior">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-center">Heading</td>
                        <td className="text-center">Type</td>
                        <td className="text-center">Sort Order</td>
                        <td className="text-center">Active</td>
                        <td className="text-center">Action</td>
                    </tr>
                </thead>
                <tbody>
                    {dataList == null ? 
                    <TableLoader cols={6}/> : dataList.length === 0 ? 
                    <tr>
                        <td colSpan={6} className="text-center">Data not available</td>
                    </tr> : dataList.map((data, index)=>(
                    <tr key={data._id} >
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{data.name?data.name:''}</td>
                        <td className="text-center">{data.type?data.type:''}</td>
                        <td className="text-center">{data.sortOrder?data.sortOrder:''}</td>
                        <td className="text-center">{data.active === true ? <span className="badge bg-success">Enable</span> : <span className="badge bg-danger">Disable</span>}</td>
                        <td className="text-center d-flex gap-2 justify-content-center">
                            <EditButton>{`/admin/about/solutions-superior/${data._id}`}</EditButton>
                            
                            <DeleteButton url="/about/solution" id={data._id} />
                        </td>
                       
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
        </DataView>
    </div>
    </ContentLayout>
    )
}
