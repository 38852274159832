import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function HomepageDetail() {
    const [homepageDetail, setHomepageDetail] = useState(null)
    const urlParams = useParams()
    const [homeBanner, setHomeBanner] = useState(null)
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/home/heading?_id=${urlParams.HomepageId}`,
                token: true
              })
              console.log(response)
              if(response.data.status === "SUCCESS!"){
                setHomepageDetail(response.data.result[0])
              }
            })()
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateHome = async (values, event) => {
        const formData = new FormData()
        if(homeBanner != null){
            formData.append("file", homeBanner)
        }
        const data = {
            bannerTitle: values.banner_title.trim(), 
            bannerDescription: values.banner_description.trim(), 
            aboutTitle: values.about_heading.trim(), 
            aboutDescription: values.about_description.trim(), 
            aboutBtnName: values.about_btn_name.trim(), 
            aboutBtnLink: values.about_btn_link.trim(), 
            counter1Value: values.counter_number_1.trim(), 
            counter1Prefix: values.counter_prefix_1 ? values.counter_prefix_1.trim() : ' ', 
            counter1Name: values.counter_text_1.trim(), 
            counter2Value: values.counter_number_2.trim(), 
            counter2Prefix: values.counter_prefix_2 ? values.counter_prefix_2.trim() : ' ', 
            counter2Name: values.counter_text_2.trim(), 
            counter3Value: values.counter_number_3.trim(), 
            counter3Prefix: values.counter_prefix_3 ? values.counter_prefix_3.trim() : ' ', 
            counter3Name: values.counter_text_3.trim(), 
            buyerTitle: values.buyer_title.trim(), 
            buyerDescription: values.buyer_description.trim(), 
            buyerBtnName: values.buyer_btn_name.trim(), 
            buyerBtnLink: values.buyer_btn_link.trim(), 
            stepTitle: values.process_title.trim(), 
            stepDescription: values.process_description.trim(), 
            testimonialTitle: values.testimonial_title.trim(), 
            testimonialDescription: values.testimonial_description.trim(), 
            ctaTitle: values.cta_title.trim(), 
            ctaDescription: values.cta_description.trim(), 
            ctaBtnName: values.cta_btn_name.trim(), 
            ctaBtnLink: values.cta_btn_link.trim(), 
            whyChooseTitle: values.why_title.trim(), 
            whyChooseDescription: values.why_description.trim(), 
            whyChooseBtnName: values.why_btn_name.trim(), 
            whyChooseBtnLink: values.why_btn_link.trim(), 
            valueAddedTitle: values.value_title.trim(), 
            valueAddedDescription: values.value_description.trim(), 
            faqTitle: values.faq_title.trim(), 
            faqDescription: values.faq_description.trim(), 
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
            if(data[key] === "" && key != 'counter1Prefix' && key != 'counter2Prefix' &&  key != 'counter3Prefix') {
                toast.error('Some Fields are Empty please check',{
                    toastId: 'EmptyFields'
                })
                return false
            }
        }
        const update = await Ajax({
            url: `/home/heading/${urlParams.HomepageId}`,
            method: 'PUT',
            token: true,
            data: formData,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Content Updated Successfully')
            setTimeout(function(){
                //window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Homepage Detail">
            <Helmet>
                <title>Homepage Detail</title>
            </Helmet>
            <DataView title="Infomation">
            {homepageDetail === null ? <DataLoader/> : 
                <Formik
                    initialValues={{ 
                        banner_title: homepageDetail.bannerTitle ? homepageDetail.bannerTitle : '', 
                        banner_description: homepageDetail.bannerDescription ? homepageDetail.bannerDescription : '', 
                        about_heading: homepageDetail.aboutTitle ? homepageDetail.aboutTitle : '', 
                        about_description: homepageDetail.aboutDescription ? homepageDetail.aboutDescription : '', 
                        about_btn_name: homepageDetail.aboutBtnName ? homepageDetail.aboutBtnName : '', 
                        about_btn_link: homepageDetail.aboutBtnLink ? homepageDetail.aboutBtnLink : '', 
                        counter_number_1: homepageDetail.counter1Value ? homepageDetail.counter1Value : '', 
                        counter_text_1: homepageDetail.counter1Name ? homepageDetail.counter1Name : '', 
                        counter_prefix_1: homepageDetail.counter1Prefix ? homepageDetail.counter1Prefix : '', 
                        counter_number_2: homepageDetail.counter2Value ? homepageDetail.counter2Value : '', 
                        counter_text_2: homepageDetail.counter2Name ? homepageDetail.counter2Name : '', 
                        counter_prefix_2: homepageDetail.counter2Prefix ? homepageDetail.counter2Prefix : '', 
                        counter_number_3: homepageDetail.counter3Value ? homepageDetail.counter3Value : '', 
                        counter_text_3: homepageDetail.counter3Name ? homepageDetail.counter3Name : '', 
                        buyer_title: homepageDetail.buyerTitle ? homepageDetail.buyerTitle : '', 
                        counter_prefix_3: homepageDetail.counter3Prefix ? homepageDetail.counter3Prefix : '', 
                        buyer_description: homepageDetail.buyerDescription ? homepageDetail.buyerDescription : '', 
                        buyer_btn_name: homepageDetail.buyerBtnName ? homepageDetail.buyerBtnName : '', 
                        buyer_btn_link: homepageDetail.buyerBtnLink ? homepageDetail.buyerBtnLink : '', 
                        process_title: homepageDetail.stepTitle ? homepageDetail.stepTitle : '', 
                        process_description: homepageDetail.stepDescription ? homepageDetail.stepDescription : '', 
                        testimonial_title: homepageDetail.testimonialTitle ? homepageDetail.testimonialTitle : '', 
                        testimonial_description: homepageDetail.testimonialDescription ? homepageDetail.testimonialDescription : '', 
                        cta_title: homepageDetail.ctaTitle ? homepageDetail.ctaTitle : '', 
                        cta_description: homepageDetail.ctaDescription ? homepageDetail.ctaDescription : '', 
                        cta_btn_name: homepageDetail.ctaBtnName ? homepageDetail.ctaBtnName : '', 
                        cta_btn_link: homepageDetail.ctaBtnLink ? homepageDetail.ctaBtnLink : '', 
                        why_title: homepageDetail.whyChooseTitle ? homepageDetail.whyChooseTitle : '', 
                        why_description: homepageDetail.whyChooseDescription ? homepageDetail.whyChooseDescription : '', 
                        why_btn_name: homepageDetail.whyChooseBtnName ? homepageDetail.whyChooseBtnName : '', 
                        why_btn_link: homepageDetail.whyChooseBtnLink ? homepageDetail.whyChooseBtnLink : '', 
                        value_title: homepageDetail.valueAddedTitle ? homepageDetail.valueAddedTitle : '', 
                        value_description: homepageDetail.valueAddedDescription ? homepageDetail.valueAddedDescription : '', 
                        faq_title: homepageDetail.faqTitle ? homepageDetail.faqTitle : '', 
                        faq_description: homepageDetail.faqDescription ? homepageDetail.faqDescription : '', 
                    }}
                    // validationSchema={Yup.object({
                    //     banner_title: Yup.string()
                    //     .required('Field is Required'),
                    //     banner_description: Yup.string()
                    //     .required('Field is Required'),
                    //     about_heading: Yup.string()
                    //     .required('Field is Required'),
                    //     about_description: Yup.string()
                    //     .required('Field is Required'),
                    //     about_btn_name: Yup.string()
                    //     .required('Field is Required'),
                    //     about_btn_link: Yup.string()
                    //     .required('Field is Required'),
                    //     counter_number_1: Yup.string()
                    //     .required('Field is Required'),
                    //     counter_text_1: Yup.string()
                    //     .required('Field is Required'),
                    //     counter_number_2: Yup.string()
                    //     .required('Field is Required'),
                    //     counter_text_2: Yup.string()
                    //     .required('Field is Required'),
                    //     counter_number_3: Yup.string()
                    //     .required('Field is Required'),
                    //     counter_text_3: Yup.string()
                    //     .required('Field is Required'),
                    //     buyer_title: Yup.string()
                    //     .required('Field is Required'),
                    //     buyer_description: Yup.string()
                    //     .required('Field is Required'),
                    //     buyer_btn_name: Yup.string()
                    //     .required('Field is Required'),
                    //     buyer_btn_link: Yup.string()
                    //     .required('Field is Required'),
                    //     process_title: Yup.string()
                    //     .required('Field is Required'),
                    //     process_description: Yup.string()
                    //     .required('Field is Required'),
                    //     testimonial_title: Yup.string()
                    //     .required('Field is Required'),
                    //     testimonial_description: Yup.string()
                    //     .required('Field is Required'),
                    //     cta_title: Yup.string()
                    //     .required('Field is Required'),
                    //     cta_description: Yup.string()
                    //     .required('Field is Required'),
                    //     cta_btn_name: Yup.string()
                    //     .required('Field is Required'),
                    //     cta_btn_link: Yup.string()
                    //     .required('Field is Required'),
                    //     why_title: Yup.string()
                    //     .required('Field is Required'),
                    //     why_description: Yup.string()
                    //     .required('Field is Required'),
                    //     why_btn_name: Yup.string()
                    //     .required('Field is Required'),
                    //     why_btn_link: Yup.string()
                    //     .required('Field is Required'),
                    //     value_title: Yup.string()
                    //     .required('Field is Required'),
                    //     value_description: Yup.string()
                    //     .required('Field is Required'),
                    //     faq_title: Yup.string()
                    //     .required('Field is Required'),
                    //     faq_description: Yup.string()
                    //     .required('Field is Required')
                    // })}
                    onSubmit={(values, event) => {
                        updateHome(values, event)
                    }}
                >
                <Form>
                    <ul className="nav nav-tabs">
                        <li className="nav-item"><a href="#tab-banner" data-bs-toggle="tab" className="nav-link active">Banner</a></li>
                        <li className="nav-item"><a href="#tab-about-us" data-bs-toggle="tab" className="nav-link">About Us</a></li>
                        <li className="nav-item"><a href="#tab-buyer-property" data-bs-toggle="tab" className="nav-link">Buyer Property</a></li>
                        <li className="nav-item"><a href="#tab-process" data-bs-toggle="tab" className="nav-link">Process</a></li>
                        <li className="nav-item"><a href="#tab-testimonial" data-bs-toggle="tab" className="nav-link">Testimonail</a></li>
                        <li className="nav-item"><a href="#tab-cta-strip" data-bs-toggle="tab" className="nav-link">CTA Strip</a></li>
                        <li className="nav-item"><a href="#tab-why-choose" data-bs-toggle="tab" className="nav-link">Why Choose Us</a></li>
                        <li className="nav-item"><a href="#tab-value-added" data-bs-toggle="tab" className="nav-link">Value Added</a></li>
                        <li className="nav-item"><a href="#tab-faq" data-bs-toggle="tab" className="nav-link">Faq</a></li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane active" id="tab-banner">
                            <FormGroup label="Banner Image">
                                <div className='col-md-12'>
                                    <input type="file" onChange={(e)=>setHomeBanner(e.target.files[0])} name="banner_image" className='form-control' />
                                </div>
                                <div className='col-md-3 mt-3'>
                                    <img src={homepageDetail.banner ? homepageDetail.banner.path : ''} alt="banner image" className='img-fluid' />
                                </div>
                            </FormGroup>
                            <FormGroup label="Banner Title" required>
                                <Field placeholder='Banner Title' name="banner_title" className='form-control' />
                                <ErrorField><ErrorMessage name="banner_title"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Banner Description" required>
                                <Field as="textarea" placeholder='Banner Description' name="banner_description" className='form-control' />
                                <ErrorField><ErrorMessage name="banner_description"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className="tab-pane" id="tab-about-us">
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="about_heading" className='form-control' />
                                <ErrorField><ErrorMessage name="about_heading"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Description" required>
                                <Field as="textarea" placeholder='Description' name="about_description" className='form-control' />
                                <ErrorField><ErrorMessage name="about_description"/></ErrorField>
                            </FormGroup>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <FormGroup label="Button Name" required>
                                        <Field placeholder='Button Name' name="about_btn_name" className='form-control' />
                                        <ErrorField><ErrorMessage name="about_btn_name"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Button Link" required>
                                        <Field placeholder='Button Link' name="about_btn_link" className='form-control' />
                                        <ErrorField><ErrorMessage name="about_btn_link"/></ErrorField>
                                    </FormGroup>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <FormGroup label="Counter 1" required>
                                                <Field placeholder='Counter Number' name="counter_number_1" className='form-control' />
                                                <ErrorField><ErrorMessage name="counter_number_1"/></ErrorField>
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-6'>
                                            <FormGroup label="Prefix">
                                                <Field placeholder='Prefix' name="counter_prefix_1" className='form-control' />
                                                <ErrorField><ErrorMessage name="counter_prefix_1"/></ErrorField>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <FormGroup label="Counter Text 1" required>
                                        <Field placeholder='Counter Text' name="counter_text_1" className='form-control' />
                                        <ErrorField><ErrorMessage name="counter_text_1"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <FormGroup label="Counter 2">
                                                <Field placeholder='Counter Number' name="counter_number_2" className='form-control' />
                                                <ErrorField><ErrorMessage name="counter_number_2"/></ErrorField>
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-6'>
                                            <FormGroup label="Prefix" required>
                                                <Field placeholder='Prefix' name="counter_prefix_2" className='form-control' />
                                                <ErrorField><ErrorMessage name="counter_prefix_2"/></ErrorField>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <FormGroup label="Counter Text 2">
                                        <Field placeholder='Counter Text' name="counter_text_2" className='form-control' />
                                        <ErrorField><ErrorMessage name="counter_text_2"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-4'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <FormGroup label="Counter 3" required>
                                                <Field placeholder='Counter Number' name="counter_number_3" className='form-control' />
                                                <ErrorField><ErrorMessage name="counter_number_3"/></ErrorField>
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-6'>
                                            <FormGroup label="Prefix" required>
                                                <Field placeholder='Prefix' name="counter_prefix_3" className='form-control' />
                                                <ErrorField><ErrorMessage name="counter_prefix_3"/></ErrorField>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <FormGroup label="Counter Text 3" required>
                                        <Field placeholder='Counter Text' name="counter_text_3" className='form-control' />
                                        <ErrorField><ErrorMessage name="counter_text_3"/></ErrorField>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-buyer-property">
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="buyer_title" className='form-control' />
                                <ErrorField><ErrorMessage name="buyer_title"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Description" required>
                                <Field as="textarea" placeholder='Description' name="buyer_description" className='form-control' />
                                <ErrorField><ErrorMessage name="buyer_description"/></ErrorField>
                            </FormGroup>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <FormGroup label="Button Name" required>
                                        <Field placeholder='Button Name' name="buyer_btn_name" className='form-control' />
                                        <ErrorField><ErrorMessage name="buyer_btn_name"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Button Link" required>
                                        <Field placeholder='Button Link' name="buyer_btn_link" className='form-control' />
                                        <ErrorField><ErrorMessage name="buyer_btn_link"/></ErrorField>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-process">
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="process_title" className='form-control' />
                                <ErrorField><ErrorMessage name="process_title"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Description" required>
                                <Field as="textarea" placeholder='Description' name="process_description" className='form-control' />
                                <ErrorField><ErrorMessage name="process_description"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className="tab-pane" id="tab-testimonial">
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="testimonial_title" className='form-control' />
                                <ErrorField><ErrorMessage name="testimonial_title"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Description" required>
                                <Field as="textarea" placeholder='Description' name="testimonial_description" className='form-control' />
                                <ErrorField><ErrorMessage name="testimonial_description"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className="tab-pane" id="tab-cta-strip">
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="cta_title" className='form-control' />
                                <ErrorField><ErrorMessage name="cta_title"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Description" required>
                                <Field placeholder='Description' name="cta_description" className='form-control' />
                                <ErrorField><ErrorMessage name="cta_description"/></ErrorField>
                            </FormGroup>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <FormGroup label="Button Name" required>
                                        <Field placeholder='Button Name' name="cta_btn_name" className='form-control' />
                                        <ErrorField><ErrorMessage name="cta_btn_name"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Button Link" required>
                                        <Field placeholder='Button Link' name="cta_btn_link" className='form-control' />
                                        <ErrorField><ErrorMessage name="cta_btn_link"/></ErrorField>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-why-choose">
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="why_title" className='form-control' />
                                <ErrorField><ErrorMessage name="why_title"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Description" required>
                                <Field as="textarea" placeholder='Description' name="why_description" className='form-control' />
                                <ErrorField><ErrorMessage name="why_description"/></ErrorField>
                            </FormGroup>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <FormGroup label="Button Name" required>
                                        <Field placeholder='Button Name' name="why_btn_name" className='form-control' />
                                        <ErrorField><ErrorMessage name="why_btn_name"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-6'>
                                    <FormGroup label="Button Link" required>
                                        <Field placeholder='Button Link' name="why_btn_link" className='form-control' />
                                        <ErrorField><ErrorMessage name="why_btn_link"/></ErrorField>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-value-added">
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="value_title" className='form-control' />
                                <ErrorField><ErrorMessage name="value_title"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Description" required>
                                <Field as="textarea" placeholder='Description' name="value_description" className='form-control' />
                                <ErrorField><ErrorMessage name="value_description"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className="tab-pane" id="tab-faq">
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="faq_title" className='form-control' />
                                <ErrorField><ErrorMessage name="faq_title"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Description" required>
                                <Field as="textarea" placeholder='Description' name="faq_description" className='form-control' />
                                <ErrorField><ErrorMessage name="faq_description"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik> }
            </DataView>
        </ContentLayout>
    )
}
