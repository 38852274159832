import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AmenityDetail() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()
    const dataIcon = useRef()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/product/bhk?_id=${urlParams.BhkId}`,
                token: true
              })
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }
            })()
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateProcess = async (values, event) => {
      
        const data = {
            name: values.bhk_title, 
            value: values.bhk_value, 
            active: values.active == 1 ? true : false, 
        }
       
        const update = await Ajax({
            url: `/product/bhk/${urlParams.BhkId}`,
            method: 'PUT',
            token: true,
            data: data,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Content Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Edit BHK Detail">
            <Helmet>
                <title>Edit BHK Detail</title>
            </Helmet>
            <DataView title="Infomation">
            {infoDetail === null ? <DataLoader/> : 
                <Formik
                    initialValues={{ 
                        bhk_title: infoDetail.name ? infoDetail.name : '', 
                        bhk_value: infoDetail.value ? infoDetail.value : '', 
                        active: infoDetail.active === true ? 1 : 2
                    }}
                    validationSchema={Yup.object({
                        bhk_title: Yup.string()
                        .required('Field is Required'),
                        bhk_value: Yup.string()
                        .required('Field is Required'),
                        active: Yup.string()
                        .required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        updateProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                    <div className='col-md-4'>
                            <FormGroup label="Bhk Title" required>
                                <Field placeholder='Bhk Title' name="bhk_title"  className='form-control' />
                                <ErrorField><ErrorMessage name="bhk_title"/></ErrorField>
                            </FormGroup>
                        </div>

                        <div className='col-md-4'>
                            <FormGroup label="Bhk Value" required>
                                <Field placeholder='Bhk Value' type="number" name="bhk_value" className='form-control' />
                                <ErrorField><ErrorMessage name="bhk_value"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                        <FormGroup label="Active" required>
                        <Field as="select" name="active" className='form-select'>
                            <option value="1">Enable</option>
                            <option value="2">Disable</option>
                        </Field>
                        <ErrorField><ErrorMessage name="type"/></ErrorField>
                    </FormGroup>
                    </div>
                    </div>
                   
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik> }
            </DataView>
        </ContentLayout>
    )
}
