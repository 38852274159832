import React, {useEffect, useState} from 'react'
import Ajax from '../../../../../helpers/Ajax'
import TableLoader from '../../../../UI/Dashboard/TableLoader';
import DataView from '../../../../UI/Dashboard/DataView';
import TableContainer from '../../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../../Common/ContentLayout'
import EditButton from '../../../../UI/Dashboard/EditButton';
import DeleteButton from '../../../../UI/Dashboard/DeleteButton';
import PaginationContainer from '../../../../UI/Dashboard/PaginationContainer';
import Pagination from '../../../../UI/Dashboard/Pagination';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';

export default function HowItWorkList() {
    const [dataList, setDataList] = useState(null)
    const [totalResult, setTotalResult] = useState(null)
    const [totalRow, setTotalRows] = useState(null)
    const [newpage, setNewPage] = useState(false)
    const [initialPage, setInitialPage] = useState(0)
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(searchParams.get('page'))
    const pageParams = searchParams.get('page')
    const navigate = useNavigate()
    useEffect(()=>{
    const getResponse = setTimeout(function(){  
        (async()=>{ 
        const response = await Ajax({
            url: `/howItWork${newpage !== false ? `?pagination=${newpage}` : ''}${pageParams && newpage === false ? `?pagination=${pageParams}` : ''}`,
            token: true,
            ...(pageParams && {loader: true})
        })
        if(response.data.status === "SUCCESS!"){
            setDataList(response.data.result)
            setTotalResult(response.data.countData)
            setTotalRows(response.data.countData / 10)
            setInitialPage(pageParams)
            setCurrentPage(searchParams.get('page'))
        }
       
        })()
    },100) 
    return()=>{
        clearInterval(getResponse)
    }
    },[location])

    return (
    <ContentLayout title='How It Work List' addNew='/admin/vas/add-how-it-work'>
        <div className="row">
        <Helmet>
            <title>How It Work List</title>
        </Helmet>
        <DataView title="How It Work List">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-start">Heading</td>
                        <td className="text-start">Image</td>
                        <td className="text-start">Type</td>
                        <td className="text-start">Sort Order</td>
                        <td className="text-center">Status</td>
                        <td className="text-center">Edit</td>
                        <td className="text-center">Delete</td>
                     
                    </tr>
                </thead>
                <tbody>
                    {dataList == null ? 
                    <TableLoader cols={8}/> : dataList.length === 0 ? 
                    <tr>
                        <td colSpan={8} className="text-center">Data not available</td>
                    </tr> : dataList.map((data, index)=>(
                    <tr key={data._id} >
                        <td className="text-center">{currentPage ? ((index + 1) + Number(currentPage) * 1 * 10) : index + 1}</td>
                        <td className="text-start ">{data.name?data.name:''}</td>
                        <td className="text-start">{data.file && <img src={data.file?data.file.thumbnail:''} /> }</td>
                         <td className="text-start ">{data.type?data.type:''}</td>
                         <td className="text-start ">{data.sortOrder?data.sortOrder:''}</td>
                        <td className="text-center">{data.active === true ? <span className="badge bg-success">Enable</span> : <span className="badge bg-danger">Disable</span>}</td>
                        <td className="text-center">
                            <EditButton>{`/admin/vas/how-it-work/${data._id}`}</EditButton>
                        </td>
                        <td className="text-center">
                            <DeleteButton url="/howItWork" id={data._id} />
                        </td>
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
            <PaginationContainer count={totalResult}>
                <Pagination totalPage={totalRow} url='/admin/vas/how-it-work' currentPage={initialPage}  />
            </PaginationContainer>
        </DataView>
    </div>
    </ContentLayout>
    )
}
