import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AddNewAmenity() {
    const [productList,setInfoDetail] = useState(null)
    const urlParams = useParams()
    const navigate = useNavigate()
    const dataIcon = useRef()

    //get property list
    useEffect(()=>{
        const getResponse = setTimeout(function(){
      
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])




    const addProcess = async (values, event) => {
        if(dataIcon.current.value === ''){
            toast.error('Icon is Required',{
                toastId: 'iconRequired'
            })
            return false
        }
        const formData = new FormData()
        if(dataIcon.current.value != ''){
            formData.append("file", dataIcon.current.files[0])
        }
        const data = {
            active: values.active == 1 ? true : false, 
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/career/gallery`,
            method: 'POST',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Gallery Added Successfully')
            setTimeout(function(){
                navigate('/admin/career/gallery')
            },500)
        }
    }
    return (
        <ContentLayout title="Add Gallery">
            <Helmet>
                <title>Add Gallery</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{ 
                    
                    }}
                    validationSchema={Yup.object({
                      
                       
                    })}
                    onSubmit={(values, event) => {
                        addProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Image" required>
                                <input type="file" accept='.jpg, .png, jpeg, .webp' name="nearby_image" ref={dataIcon} className='form-control' />
                            </FormGroup>
                        </div>
                    </div>
                   
                    
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add</Button>
                    </div>
                </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}
