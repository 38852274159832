import React, {useEffect, useState, useContext} from 'react'
import Ajax from '../../../../helpers/Ajax'
import Button from '../../../UI/Form/Button'
import FormGroup from '../../../UI/Form/FormGroup'
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import Pagination from '../../../UI/Dashboard/Pagination';
import TableLoader from '../../../UI/Dashboard/TableLoader';
import DataView from '../../../UI/Dashboard/DataView';
import TableContainer from '../../../UI/Dashboard/TableContainer';
import PaginationContainer from '../../../UI/Dashboard/PaginationContainer';
import ViewButton from '../../../UI/Dashboard/ViewButton';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import FilterOptions from '../../../../helpers/FilterOptions';
import FilterLayout from '../../../UI/Dashboard/FilterLayout';
import ContentLayout from '../../../Common/ContentLayout';
import DeleteButton from '../../../UI/Dashboard/DeleteButton';

export default function PropertyList() {
    const [service, setService] = useState(null)
    const [totalResult, setTotalResult] = useState(null)
    const [totalRow, setTotalRows] = useState(null)
    const [newpage, setNewPage] = useState(false)
    const [search, setSearch] = useState(false)
    const [initialPage, setInitialPage] = useState(0)
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const [currentPage, setCurrentPage] = useState(searchParams.get('page'))
    const pageParams = searchParams.get('page')
    const navigate = useNavigate()
    useEffect(()=>{
    const getResponse = setTimeout(function(){  
        (async()=>{ 
        const response = await Ajax({
            url: `/product/list${newpage !== false ? `?pagination=${newpage}` : ''}${pageParams && newpage === false ? `?pagination=${pageParams}` : ''}${Object.keys(filterKey).length === 0 ? '' : `&${new URLSearchParams(filterKey)}`}`,
            token: true,
            ...(pageParams && {loader: true})
        })
        if(response.data.status === "SUCCESS!"){
            setService(response.data.result.productList)
            setTotalResult(response.data.count)
            setTotalRows(response.data.count / 10)
            setInitialPage(pageParams)
            setCurrentPage(searchParams.get('page'))
        }
        
        })()
    },100) 
    return()=>{
        clearInterval(getResponse)
    }
    },[location])

    //Filters
    const [filterKey, setFilterKey] = useState({})
    const setFilter = (e) => {
        const fieldName = e.target.getAttribute("name")
        let updatedValue = {};
        if(e.target.value.trim().length === 0) {
            delete filterKey[fieldName]
            return false
        }
        updatedValue[fieldName] = e.target.value.trim()
        setFilterKey(oldValue => ({...oldValue, ...updatedValue}));
    }

    const filterHandler = async() => {
        const filterResponse = await Ajax({
            url: '/product/list?' + new URLSearchParams(filterKey),
            token: true,
            loader: true
        })
        if(filterResponse.data.result.length === 0){
            setService(filterResponse.data.result.productList)
            toast.error('Matching Record not found!',{
                autoClose: 1500
            })
            setSearch(true)
        }else{
            setCurrentPage(0)
            setInitialPage(0)
            setService(filterResponse.data.result.productList)
            setTotalRows(filterResponse.data.count / 10)
            setTotalResult(filterResponse.data.count)
            pageParams && navigate('/admin/home/service')
            return false
        }
    }

    return (
    <ContentLayout title='Property List' addNew='/admin/property-management/property/add-new'>
        <div className="row">
        <Helmet>
            <title>Property List</title>
        </Helmet>
        {service == null ? '' : (service.length > 0 || search === true) &&
        <FilterLayout>
            <div className='row'>
                <div className='col-md-3'>
                    <FormGroup label="Property Name">
                        <input type="text" name="search" placeholder="Property Name" onChange={setFilter} className="form-control" />
                    </FormGroup>
                </div>
            </div>
            <div className="col-md-2 d-grid">
                <Button type="button" icon="fas fa-filter" onclick={filterHandler}>Filter</Button>
            </div>
        </FilterLayout> }
        <DataView title="Property List">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-start">Name</td>
                        <td className="text-start">City</td>
                        <td className="text-center">Unit Number</td>
                        <td className="text-center">Area</td>
                        <td className="text-center">Size</td>
                        <td className="text-center">Price</td>
                        <td className="text-center">Status</td>
                        <td className="text-center">Edit</td> 
                        <td className="text-center">Delete</td> 
                    </tr>
                </thead>
                <tbody>
                    {service == null ? 
                    <TableLoader cols={10}/> : service.length === 0 ? 
                    <tr>
                        <td colSpan={10} className="text-center">Data not available</td>
                    </tr> : service.map((data, index)=>(
                    <tr key={data._id}>
                        <td className="text-center">{currentPage ? ((index + 1) + Number(currentPage) * 1 * 10) : index + 1}</td>
                        <td className="text-start">{data.name}</td>
                        <td className="text-start">{data.city ? data.city.name : 'NA'}</td>
                        <td className="text-center">{data.unitNumber}</td>
                        <td className="text-center">{data.area}</td>
                        <td className="text-center">{data.bhk ? data.bhk.name : 'NA'}</td>
                        <td className="text-center">{data.price}</td>
                        <td className="text-center">
                            {data.active === true ? 
                            <span className="badge bg-success">Active</span> : 
                            <span className="badge bg-danger">Disable</span>
                            }
                        </td> 
                        <td className="text-center">
                            <ViewButton>{`/admin/property-management/property/${data._id}`}</ViewButton>
                        </td>
                        <td className="text-center">
                            <DeleteButton url="/product" id={data._id}/>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
            <PaginationContainer count={totalResult}>
                <Pagination totalPage={totalRow} url='/admin/property-management/properties' currentPage={initialPage}  />
            </PaginationContainer>
        </DataView>
    </div>
    </ContentLayout>
    )
}
