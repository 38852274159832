import React from 'react'

export default function TableContainer({children}) {
  return (
    <div className="table-responsive">
        <table className="table table-bordered">
            {children}
        </table>
    </div>
  )
}
