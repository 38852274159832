import React, { useState, useEffect, useRef } from "react";
import DataView from "../../../../UI/Dashboard/DataView";
import Ajax from "../../../../../helpers/Ajax";
import { useParams, useNavigate } from "react-router-dom";
import FormGroup from "../../../../UI/Form/FormGroup";
import ErrorField from "../../../../UI/Form/ErrorField";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DataLoader from "../../../../UI/Dashboard/DataLoader";
import Button from "../../../../UI/Form/Button";
import ContentLayout from "../../../../Common/ContentLayout";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import JourneyAjax from "../../../../../helpers/JourneyAjax";

export default function AddAop() {
  const urlParams = useParams();
  const navigate = useNavigate();
  const userValidToken = localStorage.getItem("userToken");
  const dataIcon = useRef();
  const addBroker = async (values, event) => {
    const data = {
      name: values.name,
      value: values.value,
      status: values.status,
    };

    if (values.validTill)
      data.validTill = new Date(values.validTill).toISOString();

    const update = await JourneyAjax({
      url: `/aops`,
      "x-api-key": "houseeazy-dev",
      "x-user": userValidToken || null,
      method: "POST",
      data: data,
      token: true,
    });
    if (update.data.error) {
      toast.error("Something Went Wrong Please Check");
    }
    if (update.status === 200) {
      toast.success("RM Added Successfully");
      setTimeout(function() {
        navigate("/admin/property-journey/aops");
      }, 500);
    }
  };
  return (
    <ContentLayout title="Add Aop">
      <Helmet>
        <title>Add Aop</title>
      </Helmet>
      <DataView title="Infomation">
        <Formik
          initialValues={{
            name: "",
            value: 0,
            status: "active",
            validTill: "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            value: Yup.number().required("Value is Required"),
            status: Yup.string().required("Status is required"),
            validTill: Yup.string().required("Date is required"),
          })}
          onSubmit={(values, event) => {
            addBroker(values, event);
          }}
        >
          <Form>
            <div className="row">
              <div className="col-md-6">
                <FormGroup label="Name" required>
                  <Field
                    placeholder="Name"
                    name="name"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="name" />
                  </ErrorField>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup label="Value" required>
                  <Field
                    placeholder="Value"
                    type="number"
                    name="value"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="value" />
                  </ErrorField>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup label="status" required>
                  <Field as="select" name="status" className="form-select">
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Field>
                  <ErrorField>
                    <ErrorMessage name="status" />
                  </ErrorField>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup label="Valid Till" required>
                  <Field
                    type="date"
                    placeholder="Valid Till"
                    name="validTill"
                    className="form-control"
                  />
                  <ErrorField>
                    <ErrorMessage name="validTill" />
                  </ErrorField>
                </FormGroup>
              </div>
            </div>

            <div className="col-md-2 d-grid">
              <Button type="submit">Add</Button>
            </div>
          </Form>
        </Formik>
      </DataView>
    </ContentLayout>
  );
}
