import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AmenityDetail() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()
    const dataIcon = useRef()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/about/solution?_id=${urlParams.GalleryId}`,
                token: true
              })
           
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }


           
            })()
          },100)    
        
         
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateProcess = async (values, event) => {
     
        const data = {
            name:values.name,
            sortOrder:values.sortOrder,
            type:values.type,
            active: values.active == 1 ? true : false, 
        }
        
        const update = await Ajax({
            url: `/about/solution/${urlParams.GalleryId}`,
            method: 'PUT',
            token: true,
            data: data,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Gallery Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Edit Solution Superior">
            <Helmet>
                <title>Edit Solution Superior</title>
            </Helmet>
            <DataView title="Infomation">
              
            {infoDetail === null   ? <DataLoader/> : 
                <Formik
               
                    initialValues={{ 
                        active: infoDetail.active === true ? 1 : 2,
                        name: infoDetail.name ? infoDetail.name : '',
                        sortOrder: infoDetail.sortOrder ? infoDetail.sortOrder : '',
                        type: infoDetail.type ? infoDetail.type : '',
                       
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required('Field is Required'),
                        sortOrder: Yup.number().required('Field is Required'),
                        type: Yup.string().required('Field is Required'),
                        active: Yup.string().required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        updateProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'> 
                    <div className='col-md-6'>
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="name" className='form-control' />
                                <ErrorField><ErrorMessage name="name"/></ErrorField>
                            </FormGroup>
                        </div>

                        <div className='col-md-6'>
                            <FormGroup label="Sort Order" required>
                                <Field placeholder='Sort Order' type="number" name="sortOrder" className='form-control' />
                                <ErrorField><ErrorMessage name="sortOrder"/></ErrorField>
                            </FormGroup>
                        </div>
                      
                      
                        <div className='col-md-6'>
                            <FormGroup label="Type" required>
                                <Field as="select" name="type"  className='form-select' >
                                <option value='' disable>Select Type</option>
                                <option value='SELLER'>Seller</option>
                                <option value='BUYER'>Buyer</option>
                             
                                </Field>
                                <ErrorField><ErrorMessage name="type"/></ErrorField>
                            </FormGroup>
                        </div>

                     
                        <div className='col-md-6'>
                        <FormGroup label="Active" required>
                        <Field as="select" name="active" className='form-select'>
                            <option value="1">Enable</option>
                            <option value="2">Disable</option>
                        </Field>
                        <ErrorField><ErrorMessage name="type"/></ErrorField>
                    </FormGroup>
                    </div>
                       
                       
                   
                    </div>
                   
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik> }
            </DataView>
        </ContentLayout>
    )
}
