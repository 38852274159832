import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../../UI/Form/FormGroup'
import ErrorField from '../../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../../UI/Dashboard/DataLoader'
import Button from '../../../../../UI/Form/Button'
import ContentLayout from '../../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AddNewOffer() {
    const [productList,setInfoDetail] = useState(null)
    const urlParams = useParams()
    const navigate = useNavigate()
    const dataIcon = useRef()

    //get property list
    useEffect(()=>{
        const getResponse = setTimeout(function(){
      
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])




    const addData = async (values) => {
        if(dataIcon.current.value === ''){
            toast.error('Image is Required',{
                toastId: 'iconRequired'
            })
            return false
        }
        const formData = new FormData()
        if(dataIcon.current.value != ''){
            formData.append("file", dataIcon.current.files[0])
        }
        const data = {
            name: values.heading,
            roi: values.roi,
            processingFee: values.processingFee,
            tenure: values.tenure,
            tenureAmount: values.tenureAmount,
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/finance`,
            method: 'POST',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
            return false
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Data Added Successfully')
            setTimeout(function(){
                navigate('/admin/vas/home-loan-offers')
            },500)
        }
    }
    return (
        <ContentLayout title="Add New Loan Offer">
            <Helmet>
                <title>Add New Loan Offer</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{ 
                        heading:'',
                        roi:'',
                        processingFee:'',
                        tenure:'',
                        tenureAmount:''
                    }}
                    validationSchema={Yup.object({
                        heading: Yup.string().required('Field is Required'),
                        roi: Yup.string().required('Field is Required'),
                        processingFee: Yup.string().required('Field is Required'),
                        tenure: Yup.string().required('Field is Required'),
                        tenureAmount: Yup.string().required('Field is Required'),
                    })}
                    onSubmit={(values) => {
                        addData(values)
                    }}
                >
                <Form>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="heading" className='form-control' />
                                <ErrorField><ErrorMessage name="heading"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Rate of Interest" required>
                                <Field placeholder='Rate of Interest' name="roi" className='form-control' />
                                <ErrorField><ErrorMessage name="roi"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Processing Fee" required>
                                <Field placeholder='Processing Fee' name="processingFee" className='form-control' />
                                <ErrorField><ErrorMessage name="processingFee"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Tenure" required>
                                <Field placeholder='Tenure' name="tenureAmount" className='form-control' />
                                <ErrorField><ErrorMessage name="tenureAmount"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Tenure Description" required>
                                <Field placeholder='Tenure Description' name="tenure" className='form-control' />
                                <ErrorField><ErrorMessage name="tenure"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Image" required>
                                <input type="file" accept='.jpg, .png, jpeg, .webp' name="nearby_image" ref={dataIcon} className='form-control' />
                            </FormGroup>
                        </div>
                    </div>
                    
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add</Button>
                    </div>
                </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}