import React, { useState, useEffect } from "react";
import ReactDom from "react-dom";

const Overlay = (props) => {
  const closePopup = () => {
    props.close(false);
  };
  return (
    <>
      {props.open && (
        <>
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100vw",
              height: "100vh",
              background: "rgb(0,0,0,0.6)",
              zIndex: "2000",
            }}
            onClick={closePopup}
          ></div>
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "40%",
              background: "white",
              padding: "1rem",
              zIndex: "2000",
              borderRadius: "10px",
              width: "20%",
              overflow: "auto",
              maxHeight: "90vh",
            }}
            id="popup-1"
          >
            <div
              type="button"
              style={{
                position: "absolute",
                right: "10px",
                width: "25px",
                height: "25px",
              }}
              onClick={closePopup}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="black"
              >
                <path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path>
              </svg>
            </div>
            {props.content}
          </div>
        </>
      )}
    </>
  );
};
function Popup(props) {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);
  return (
    mounted &&
    ReactDom.createPortal(
      <Overlay
        content={props.children}
        close={props.close}
        open={props.open}
        size={props.size}
        mobile={props.mobile ? props.mobile : ""}
      />,
      document.querySelector("#popup-container")
    )
  );
}

export default Popup;
