import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AddNewAmenity() {
    const urlParams = useParams()
    const navigate = useNavigate()

    const dataIcon = useRef()
    const addProcess = async (values, event) => {
        if(dataIcon.current.value === ''){
            toast.error('Pdf is Required',{
                toastId: 'iconRequired'
            })
            return false
        }
        const formData = new FormData()
        if(dataIcon.current.value != ''){
            formData.append("file", dataIcon.current.files[0])
        }
        const data = {
            position: values.position, 
            location: values.location, 
            description: values.description, 
            active: values.active == 1 ? true : false, 
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/career`,
            method: 'POST',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Job Added Successfully')
            setTimeout(function(){
                navigate('/admin/career/job')
            },500)
        }
    }
    return (
        <ContentLayout title="Add New Job">
            <Helmet>
                <title>Add New Job</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{ 
                        position: '', 
                        location: '', 
                        description: '', 
                        active: '1',
                    }}
                    validationSchema={Yup.object({
                        position: Yup.string().required('Field is Required'),
                        location: Yup.string().required('Field is Required'),
                        description: Yup.string().required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        addProcess(values, event)
                    }}
                >
                <Form>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Position" required>
                                <Field placeholder='Position' name="position" className='form-control' />
                                <ErrorField><ErrorMessage name="position"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Location" required>
                                <Field placeholder='Location' name="location" className='form-control' />
                                <ErrorField><ErrorMessage name="location"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Pdf" required>
                                <input type="file" accept='.pdf' name="amenity_image" ref={dataIcon} className='form-control' />
                            </FormGroup>
                        </div>
                        <div className='col-md-12'>
                            <FormGroup label="Description" required>
                                <Field as="textarea" name="description"  className='form-control' />
                                <ErrorField><ErrorMessage name="description"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>

                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add</Button>
                    </div>
                </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}
