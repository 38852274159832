import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../UI/Dashboard/DataView'
import Ajax from '../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../UI/Form/FormGroup'
import ErrorField from '../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../UI/Dashboard/DataLoader'
import Button from '../../../UI/Form/Button'
import ContentLayout from '../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import GetBlogType from '../../../../helpers/GetBlogType'
import { CKEditor } from 'ckeditor4-react';

export default function EditEvent() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()
    const navigate = useNavigate()

    //get property list
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
                const response = await Ajax({
                    url: `/blog/interview?_id=${urlParams.EventId}`,
                    token: true
                })
                if(response.data.status === "SUCCESS!"){
                    setInfoDetail(response.data.result[0])
                }
            })()
        },100)
      
        return()=>{
            clearInterval(getResponse)
        }
    },[])




    const addData = async (values) => {
        const data = {
            title: values.title, 
            youtube: values.youtube, 
            active: values.active == 1 ? true : false, 
        }
        const update = await Ajax({
            url: `/blog/interview/${urlParams.EventId}`,
            method: 'PUT',
            token: true,
            data: data,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Event Added Successfully')
            setTimeout(function(){
                navigate('/admin/events')
            },500)
        }
    }
    return (
        <ContentLayout title="Edit Event">
            <Helmet>
                <title>Edit Event</title>
            </Helmet>
            <DataView title="Infomation">
                {infoDetail === null ? <DataLoader/> : 
                <Formik
                    initialValues={{ 
                        title: infoDetail.title ? infoDetail.title : '', 
                        youtube: infoDetail.youtube ? infoDetail.youtube : '',
                        active: infoDetail.active === true ? '1' : '2',
                    }}
                    validationSchema={Yup.object({
                        title: Yup.string().required('Field is Required'),
                        youtube: Yup.string().required('Field is Required'),
                    })}
                    onSubmit={(values, event) => {
                        addData(values, event)
                    }}
                >
                <Form>
                    <FormGroup label="Title" required>
                        <Field placeholder='Title' name="title" className='form-control' />
                        <ErrorField><ErrorMessage name="title"/></ErrorField>
                    </FormGroup>
                    <FormGroup label="Youtube Video Id" required>
                        <Field placeholder='Youtube Video Id' name="youtube" className='form-control' />
                        <ErrorField><ErrorMessage name="youtube"/></ErrorField>
                    </FormGroup>
                    <FormGroup label="Active" required>
                        <Field as="select" name="active" className='form-select'>
                            <option value="1">Enable</option>
                            <option value="2">Disable</option>
                        </Field>
                        <ErrorField><ErrorMessage name="type"/></ErrorField>
                    </FormGroup>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Add</Button>
                    </div>
                </Form>
                </Formik> }
            </DataView>
        </ContentLayout>
    )
}
