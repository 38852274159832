import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from "react-router-dom"
import EditButton from '../../../../UI/Dashboard/EditButton'

export default function Homefest() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()
    const dataIcon = useRef()
    const navigate = useNavigate()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/landingPage/heading`,
                token: true
              })
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }
            })()
          },100)    
        
         
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateData = async (values, event) => {
        const formData = new FormData()
        if(dataIcon.current.value != ''){
            formData.append("file", dataIcon.current.files[0])
        }
        const data = {
            bannerTitle: values.bannerTitle,
            bannerDate: values.bannerDate,
            phone: values.phone,
            formHeading: values.formHeading,
            readyTitle: values.readyTitle,
            readyDescription: values.readyDescription,
            bonanzaTitle: values.bonanzaTitle,
            projectTitle: values.projectTitle,
            reviewTitle: values.reviewTitle
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
        }
        const update = await Ajax({
            url: `/landingPage/heading/${infoDetail._id}`,
            method: 'PUT',
            token: true,
            data: formData,
            loader: true
        })
        if(update.data.error){
            toast.error('Something Went Wrong Please Check')
            return false
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Data Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
    }
    return (
        <ContentLayout title="Homefest">
            <Helmet>
                <title>Edit  Homefest</title>
            </Helmet>
            <DataView title="Infomation">
              
            {infoDetail === null   ? <DataLoader/> : 
                <Formik
                initialValues={{ 
                    bannerTitle: infoDetail.bannerTitle ? infoDetail.bannerTitle : '',
                    bannerDate: infoDetail.bannerDate ? infoDetail.bannerDate : '',
                    phone: infoDetail.phone ? infoDetail.phone : '',
                    formHeading: infoDetail.formHeading ? infoDetail.formHeading : '',
                    readyTitle: infoDetail.readyTitle ? infoDetail.readyTitle : '',
                    readyDescription: infoDetail.readyDescription ? infoDetail.readyDescription : '',
                    bonanzaTitle: infoDetail.bonanzaTitle ? infoDetail.bonanzaTitle : '',
                    projectTitle: infoDetail.projectTitle ? infoDetail.projectTitle : '',
                    reviewTitle: infoDetail.reviewTitle ? infoDetail.reviewTitle : ''
                }}
                validationSchema={Yup.object({
                    bannerTitle: Yup.string().required('Field is Required'),
                    bannerDate: Yup.string().required('Field is Required'),
                    phone: Yup.string().required('Field is Required'),
                    formHeading: Yup.string().required('Field is Required'),
                    readyTitle: Yup.string().required('Field is Required'),
                    readyDescription: Yup.string().required('Field is Required'),
                    bonanzaTitle: Yup.string().required('Field is Required'),
                    projectTitle: Yup.string().required('Field is Required'),
                    reviewTitle: Yup.string().required('Field is Required')
                   
                })}
                onSubmit={(values) => {
                    updateData(values)
                }}
            >
            <Form>
                <FormGroup label="Banner Title" full required>
                    <Field placeholder='Banner Title' name="bannerTitle" className='form-control' />
                    <ErrorField><ErrorMessage name="bannerTitle"/></ErrorField>
                </FormGroup>
                <FormGroup label="Banner Date" full required>
                    <Field placeholder='Banner Date' name="bannerDate" className='form-control' />
                    <ErrorField><ErrorMessage name="bannerDate"/></ErrorField>
                </FormGroup>
                <FormGroup label="Banner Phone" full required>
                    <Field placeholder='Banner Phone' name="phone" className='form-control' />
                    <ErrorField><ErrorMessage name="phone"/></ErrorField>
                </FormGroup>
                <FormGroup label="Form and CTA Heading" full required>
                    <Field placeholder='Form and CTA Heading' name="formHeading" className='form-control' />
                    <ErrorField><ErrorMessage name="formHeading"/></ErrorField>
                </FormGroup>
                <FormGroup label="Page Heading" full required>
                    <Field placeholder='Page Heading' name="readyTitle" className='form-control' />
                    <ErrorField><ErrorMessage name="readyTitle"/></ErrorField>
                </FormGroup>
                <FormGroup label="Page Description" full required>
                    <Field as="textarea" placeholder='Page Description' name="readyDescription" className='form-control' />
                    <ErrorField><ErrorMessage name="readyDescription"/></ErrorField>
                </FormGroup>
                <FormGroup label="Feature Title" full required>
                    <Field placeholder='Feature Title' name="bonanzaTitle" className='form-control' />
                    <ErrorField><ErrorMessage name="bonanzaTitle"/></ErrorField>
                </FormGroup>
                <FormGroup label="Table Title" full required>
                    <Field placeholder='Table Title' name="projectTitle" className='form-control' />
                    <ErrorField><ErrorMessage name="projectTitle"/></ErrorField>
                </FormGroup>
                <FormGroup label="Prject Title" full required>
                    <Field placeholder='Prject Title' name="reviewTitle" className='form-control' />
                    <ErrorField><ErrorMessage name="reviewTitle"/></ErrorField>
                </FormGroup>
                <FormGroup full label="Image" required>
                    <input type="file" accept='.jpg, .png, jpeg, .webp' name="nearby_image" ref={dataIcon} className='form-control' />
                </FormGroup>
                <div class="col-md-2 offset-md-2 bg-dark p-4">
                    {infoDetail.file &&
                    <img src={infoDetail.file.path} alt="prcess icon" height="100" width="100" className='img-fluid mb-4' /> }
                </div>
                <div className='col-md-2 offset-md-2 d-grid mt-4 p-2'>
                    <Button type="submit">Update</Button>
                </div>
            </Form>
            </Formik> }
            </DataView>
        </ContentLayout>
    )
}
