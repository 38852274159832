import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from "react-router-dom"

export default function AboutDetail() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()
    const dataIcon = useRef()
    const dataIcon2 = useRef()
    const dataIcon3 = useRef()
    const navigate = useNavigate()
    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/about/heading?_id=${urlParams.AboutPageId}`,
                token: true
              })
              console.log(response)
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }
            })()
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateHome = async (values, event) => {
        const formData = new FormData()
        if(dataIcon.current.value != ''){
            formData.append("blog", dataIcon.current.files[0])
        }
        if(dataIcon2.current.value != ''){
            formData.append("thumbnail", dataIcon2.current.files[0])
        }
        if(dataIcon3.current.value != ''){
            formData.append("coverPhoto", dataIcon3.current.files[0])
        }
        const data = {
            weareTitle: values.who_title, 
            weareDescription: values.who_description, 
            solutionTitle: values.solution_heading, 
            solutionDescription: values.solution_description, 
            teamTitle: values.team_title, 
            teamDescription: values.team_description, 
            newsTitle: values.news_title, 
            newsDescription: values.news_description,
            youtube1: values.youtube1,
            youtube2: values.youtube2,
            youtube3: values.youtube3,
            youtubeTitle1: values.youtubeTitle1,
            youtubeTitle2: values.youtubeTitle2,
            youtubeTitle3: values.youtubeTitle3,
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
            // if(data[key] === "") {
            //     toast.error('Some Fields are Empty please check',{
            //         toastId: 'EmptyFields'
            //     })
            //     return false
            // }
        }
        const update = await Ajax({
            url: `/about/heading/${urlParams.AboutPageId}`,
            method: 'PUT',
            token: true,
            data: formData,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Content Updated Successfully')
            setTimeout(function(){
                navigate(0);
            },500)
        }
       
    }
    return (
        <ContentLayout title="About Us Detail">
            <Helmet>
                <title>About Us Detail</title>
            </Helmet>
            <DataView title="Infomation">
            {infoDetail === null ? <DataLoader/> : 
                <Formik
                    initialValues={{ 
                        who_title: infoDetail.weareTitle ? infoDetail.weareTitle : '', 
                        who_description: infoDetail.weareDescription ? infoDetail.weareDescription : '', 
                        solution_heading: infoDetail.solutionTitle ? infoDetail.solutionTitle : '', 
                        solution_description: infoDetail.solutionDescription ? infoDetail.solutionDescription : '', 
                        team_title: infoDetail.teamTitle ? infoDetail.teamTitle : '', 
                        team_description: infoDetail.teamDescription ? infoDetail.teamDescription : '', 
                        news_title: infoDetail.newsTitle ? infoDetail.newsTitle : '', 
                        news_description: infoDetail.newsDescription ? infoDetail.newsDescription : '', 
                        youtube1: infoDetail.youtube1 ? infoDetail.youtube1 : '', 
                        youtube2: infoDetail.youtube2 ? infoDetail.youtube2 : '', 
                        youtube3: infoDetail.youtube3 ? infoDetail.youtube3 : '', 
                        youtubeTitle1: infoDetail.youtubeTitle1 ? infoDetail.youtubeTitle1 : '', 
                        youtubeTitle2: infoDetail.youtubeTitle2 ? infoDetail.youtubeTitle2 : '', 
                        youtubeTitle3: infoDetail.youtubeTitle3 ? infoDetail.youtubeTitle3 : '', 
                    }}
                    // validationSchema={Yup.object({
                    //     banner_title: Yup.string()
                    //     .required('Field is Required'),
                    //     banner_description: Yup.string()
                    //     .required('Field is Required'),
                    //     about_heading: Yup.string()
                    //     .required('Field is Required'),
                    //     about_description: Yup.string()
                    //     .required('Field is Required'),
                    //     about_btn_name: Yup.string()
                    //     .required('Field is Required'),
                    //     about_btn_link: Yup.string()
                    //     .required('Field is Required'),
                    //     counter_number_1: Yup.string()
                    //     .required('Field is Required'),
                    //     counter_text_1: Yup.string()
                    //     .required('Field is Required'),
                    //     counter_number_2: Yup.string()
                    //     .required('Field is Required'),
                    //     counter_text_2: Yup.string()
                    //     .required('Field is Required'),
                    //     counter_number_3: Yup.string()
                    //     .required('Field is Required'),
                    //     counter_text_3: Yup.string()
                    //     .required('Field is Required'),
                    //     buyer_title: Yup.string()
                    //     .required('Field is Required'),
                    //     buyer_description: Yup.string()
                    //     .required('Field is Required'),
                    //     buyer_btn_name: Yup.string()
                    //     .required('Field is Required'),
                    //     buyer_btn_link: Yup.string()
                    //     .required('Field is Required'),
                    //     process_title: Yup.string()
                    //     .required('Field is Required'),
                    //     process_description: Yup.string()
                    //     .required('Field is Required'),
                    //     testimonial_title: Yup.string()
                    //     .required('Field is Required'),
                    //     testimonial_description: Yup.string()
                    //     .required('Field is Required'),
                    //     cta_title: Yup.string()
                    //     .required('Field is Required'),
                    //     cta_description: Yup.string()
                    //     .required('Field is Required'),
                    //     cta_btn_name: Yup.string()
                    //     .required('Field is Required'),
                    //     cta_btn_link: Yup.string()
                    //     .required('Field is Required'),
                    //     why_title: Yup.string()
                    //     .required('Field is Required'),
                    //     why_description: Yup.string()
                    //     .required('Field is Required'),
                    //     why_btn_name: Yup.string()
                    //     .required('Field is Required'),
                    //     why_btn_link: Yup.string()
                    //     .required('Field is Required'),
                    //     value_title: Yup.string()
                    //     .required('Field is Required'),
                    //     value_description: Yup.string()
                    //     .required('Field is Required'),
                    //     faq_title: Yup.string()
                    //     .required('Field is Required'),
                    //     faq_description: Yup.string()
                    //     .required('Field is Required')
                    // })}
                    onSubmit={(values, event) => {
                        updateHome(values, event)
                    }}
                >
                <Form>
                    <ul className="nav nav-tabs">
                        <li className="nav-item"><a href="#tab-who-we-are" data-bs-toggle="tab" className="nav-link active">Who We Are</a></li>
                        <li className="nav-item"><a href="#tab-solutions" data-bs-toggle="tab" className="nav-link">Solutions</a></li>
                        <li className="nav-item"><a href="#tab-team" data-bs-toggle="tab" className="nav-link">Team</a></li>
                        <li className="nav-item"><a href="#tab-news" data-bs-toggle="tab" className="nav-link">News & Media</a></li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane active" id="tab-who-we-are">
                            <FormGroup label="Title" required>
                                <Field placeholder='Title' name="who_title" className='form-control' />
                                <ErrorField><ErrorMessage name="who_title"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Description" required>
                                <Field as="textarea" placeholder='Description' name="who_description" className='form-control' />
                                <ErrorField><ErrorMessage name="who_description"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className="tab-pane" id="tab-solutions">
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="solution_heading" className='form-control' />
                                <ErrorField><ErrorMessage name="solution_heading"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Description" required>
                                <Field as="textarea" placeholder='Description' name="solution_description" className='form-control' />
                                <ErrorField><ErrorMessage name="solution_description"/></ErrorField>
                            </FormGroup>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <FormGroup label="Youtube Link 1" required>
                                        <Field placeholder='Youtube Link 1' name="youtube1" className='form-control' />
                                        <ErrorField><ErrorMessage name="youtube1"/></ErrorField>
                                    </FormGroup>
                                </div>
                                <div className='col-md-3'>
                                    <FormGroup label="Youtube Title 1" required>
                                        <Field placeholder='Youtube Title 1' name="youtubeTitle1" className='form-control' />
                                        <ErrorField><ErrorMessage name="youtubeTitle1"/></ErrorField>
                                    </FormGroup>
                                </div>
                            <div className='col-md-6'>
                        {infoDetail.youtube1image?
                        <img src={infoDetail.youtube1image.path} alt="prcess icon" height="100" width="100" className='img-fluid' /> 
                        :''}
                            <FormGroup label="Youtube Image 1" required>
                            <input type="file" accept='.jpg, .png, jpeg, .webp' name="amenity_image" ref={dataIcon} className='form-control' />
                            </FormGroup>
                        </div>
                        <div className='col-md-3'>
                            <FormGroup label="Youtube Link 2" required>
                                <Field placeholder='Youtube Link 2' name="youtube2" className='form-control' />
                                <ErrorField><ErrorMessage name="youtube1"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-3'>
                            <FormGroup label="Youtube Title 2" required>
                                <Field placeholder='Youtube Title 2' name="youtubeTitle2" className='form-control' />
                                <ErrorField><ErrorMessage name="youtubeTitle2"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                        {infoDetail.youtube2image?
                        <img src={infoDetail.youtube2image.path} alt="prcess icon" height="100" width="100" className='img-fluid' /> 
                        :''}
                            <FormGroup label="Youtube Image 2" required>
                            <input type="file" accept='.jpg, .png, jpeg, .webp' name="amenity_image" ref={dataIcon2} className='form-control' />
                            </FormGroup>
                        </div>
                            <div className='col-md-3'>
                            <FormGroup label="Youtube Link 3" required>
                                <Field placeholder='Youtube Link 3' name="youtube3" className='form-control' />
                                <ErrorField><ErrorMessage name="youtube1"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-3'>
                            <FormGroup label="Youtube Title 2" required>
                                <Field placeholder='Youtube Title 2' name="youtubeTitle3" className='form-control' />
                                <ErrorField><ErrorMessage name="youtubeTitle3"/></ErrorField>
                            </FormGroup>
                        </div>

                            <div className='col-md-6'>
                        {infoDetail.youtube3image?
                        <img src={infoDetail.youtube3image.path} alt="prcess icon" height="100" width="100" className='img-fluid' /> 
                        :''}
                            <FormGroup label="Youtube Image 3" required>
                            <input type="file" accept='.jpg, .png, jpeg, .webp' name="amenity_image" ref={dataIcon3}  className='form-control' />
                            </FormGroup>
                        </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="tab-team">
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="team_title" className='form-control' />
                                <ErrorField><ErrorMessage name="team_title"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Description" required>
                                <Field as="textarea" placeholder='Description' name="team_description" className='form-control' />
                                <ErrorField><ErrorMessage name="team_description"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className="tab-pane" id="tab-news">
                            <FormGroup label="Heading" required>
                                <Field placeholder='Heading' name="news_title" className='form-control' />
                                <ErrorField><ErrorMessage name="news_title"/></ErrorField>
                            </FormGroup>
                            <FormGroup label="Description" required>
                                <Field as="textarea" placeholder='Description' name="news_description" className='form-control' />
                                <ErrorField><ErrorMessage name="news_description"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik> }
            </DataView>
        </ContentLayout>
    )
}
