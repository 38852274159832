import React, {useRef, useState} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import {useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function AddNewWhy() {
    const navigate = useNavigate()
    const whyIcon = useRef(null)
    const whyBg = useRef(null)
    const [iconError, setIconError] = useState()
    const [bgError, setBgError] = useState()
    const addProcess = async (values, event) => {
        if(whyIcon.current.value === ''){
            setIconError('Field is Required')
            return false
        }
        if(whyBg.current.value === ''){
            setBgError('Field is Required')
            return false
        }
        const formData = new FormData()
        const data = {
            name: values.why_title, 
            description: values.why_description, 
            file: whyIcon.current.files[0], 
            banner: whyBg.current.files[0], 
            sortOrder: values.sort_order, 
            type: values.type, 
        }
        for(var key in data) {
            if(data[key] !== "") {
                formData.append(key, data[key])
            }
            if(data[key] === "") {
                toast.error('Some Fields are Empty please check',{
                    toastId: 'EmptyFields'
                })
                return false
            }
        }
        const update = await Ajax({
            url: `/home/whyChoose`,
            method: 'POST',
            token: true,
            data: formData,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Content Added Successfully')
            setTimeout(function(){
                navigate('/admin/home/why-choose')
            },500)
        }
        console.log(update)
    }
    return (
        <ContentLayout title="Add New Why Choose">
            <Helmet>
                <title>Add New Why Choose Us</title>
            </Helmet>
            <DataView title="Infomation">
                <Formik
                    initialValues={{ 
                        why_title: '', 
                        why_description: '', 
                        sort_order: '',
                        type: '',
                    }}
                    validationSchema={Yup.object({
                        why_title: Yup.string()
                        .required('Field is Required'),
                        why_description: Yup.string()
                        .required('Field is Required'),
                        sort_order: Yup.string()
                        .required('Field is Required'),
                        type: Yup.string()
                        .required('Field is Required')
                    })}
                    onSubmit={(values, event) => {
                        addProcess(values, event)
                    }}
                >
                <Form>
                    <FormGroup label="Title" required>
                        <Field placeholder='Title' name="why_title" className='form-control' />
                        <ErrorField><ErrorMessage name="why_title"/></ErrorField>
                    </FormGroup>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Icon" required>
                                <input type="file" ref={whyIcon} name="why_icon" className='form-control' />
                                <ErrorField>{iconError}</ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Background" required>
                                <input type="file" ref={whyBg} name="why_bg" className='form-control' />
                                <ErrorField>{bgError}</ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                    <FormGroup label="Description" required>
                        <Field as="textarea" placeholder='Description' name="why_description" className='form-control' />
                        <ErrorField><ErrorMessage name="why_description"/></ErrorField>
                    </FormGroup>
                    <div className='row'>
                        <div className='col-md-6'>
                            <FormGroup label="Type" required>
                                <Field as="select" name="type" className='form-select'>
                                    <option value="" disabled>Select</option>
                                    <option value="SELLER">Seller</option>
                                    <option value="BUYER">Buyer</option>
                                    <option value="PRODUCT">Product</option>
                                    <option value="HOME-LOAN">Home Loan</option>
                                    <option value="HOME-INTERIOR">Home Interior</option>
                                    <option value="UNDER-CONTRUCTION">Under Construction</option>
                                    <option value="BEST-AREA">Best Area</option>
                                </Field>
                                <ErrorField><ErrorMessage name="type"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Sort Order" required>
                                <Field type="number" placeholder='Sort Order' name="sort_order" className='form-control' />
                                <ErrorField><ErrorMessage name="sort_order"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik>
            </DataView>
        </ContentLayout>
    )
}
