import Ajax from "./Ajax"
import { toast } from "react-toastify"
const Logout = async () => {
    const data = {
        url: '/user/logout',
        token: true,
        loader: true
    }
    const response = await Ajax(data)
    if(response.status === 200){
        document.cookie = `token=demo;path=/;expires=Thu, 18 Dec 2013 12:00:00 UTC`;
        toast.success("Logout successfully!")
        return true
    }else{
        toast.error("Something Wrong!")
        return false
    }
}
export default Logout