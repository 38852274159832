import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams, useNavigate } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function Setting() {
    const [infoDetail,setInfoDetail] = useState(null)
    const urlParams = useParams()
    const navigate = useNavigate()
    const dataIcon = useRef()

    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
              const response = await Ajax({
                url: `/setting/getSetting`,
                token: true
              })
              if(response.data.status === "SUCCESS!"){
                setInfoDetail(response.data.result[0])
              }
            })()
          },100)
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateSetting = async (values) => {
        const data = {
            email: values.email,
            telephone: values.telephone,
            footer_note: values.footer_note,
            facebook: values.facebook,
            linkedin: values.linkedin,
            instagram: values.instagram,
            youtube: values.youtube,
            whatsapp: values.whatsapp,
            copywrite: values.copywrite,
            leadGenText: values.leadGenText,
            store_name: 'HouseEazy',
            google_iframe:values.google_iframe,
            address:values.address
        }
        const addMeta = await Ajax({
            url: `/setting/637e1d00e020842ca4df7bfd`,
            method: 'PUT',
            token: true,
            data: data,
            loader: true
        })
        if(addMeta.data.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(addMeta.data.status === "SUCCESS!"){
            toast.success('Setting Updated Successfully')
            setTimeout(function(){
                navigate('/admin/setting/general')
            },500)
        }
    }
    return (
        <ContentLayout title="General Setting">
            <Helmet>
                <title>General Setting</title>
            </Helmet>
            <DataView title="Infomation">
            {infoDetail === null ? <DataLoader/> :
                <Formik
                    initialValues={{ 
                        email: infoDetail.email ? infoDetail.email : '',
                        telephone: infoDetail.telephone ? infoDetail.telephone : '',
                        footer_note: infoDetail.footer_note ? infoDetail.footer_note : '',
                        facebook: infoDetail.facebook ? infoDetail.facebook : '',
                        linkedin: infoDetail.linkedin ? infoDetail.linkedin : '',
                        instagram: infoDetail.instagram ? infoDetail.instagram : '',
                        youtube: infoDetail.youtube ? infoDetail.youtube : '',
                        whatsapp: infoDetail.whatsapp ? infoDetail.whatsapp : '',
                        copywrite: infoDetail.copywrite ? infoDetail.copywrite : '',
                        leadGenText: infoDetail.leadGenText ? infoDetail.leadGenText : '',
                        google_iframe: infoDetail.google_iframe ? infoDetail.google_iframe : '',
                        address: infoDetail.address ? infoDetail.address : ''
                    }}
                    validationSchema={Yup.object({
                        email: Yup.string()
                        .email('Invalid email address')
                        .required('Field is Required'),
                        telephone: Yup.string()
                        .required('Field is Required'),
                        footer_note: Yup.string()
                        .required('Field is Required'),
                        facebook: Yup.string()
                        .required('Field is Required'),
                        linkedin: Yup.string()
                        .required('Field is Required'),
                        instagram: Yup.string()
                        .required('Field is Required'),
                        youtube: Yup.string()
                        .required('Field is Required'),
                        whatsapp: Yup.string()
                        .required('Field is Required'),
                        copywrite: Yup.string()
                        .required('Field is Required'),
                        leadGenText: Yup.string()
                        .required('Field is Required'),
                        google_iframe: Yup.string()
                        .required('Field is Required'),
                         address: Yup.string()
                        .required('Field is Required'),
                    })}
                    onSubmit={(values) => {
                        updateSetting(values)
                    }}
                >
                <Form>
                    <div className='row'>
                        <div className='col-md-4'>
                            <FormGroup label="Phone" required>
                                <Field placeholder="Phone" name="telephone" className='form-control' />
                                <ErrorField><ErrorMessage name="telephone"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Email" required>
                                <Field placeholder="Email" name="email" className='form-control' />
                                <ErrorField><ErrorMessage name="email"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Whatsapp" required>
                                <Field placeholder="Whatsapp" name="whatsapp" className='form-control' />
                                <ErrorField><ErrorMessage name="whatsapp"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-12'>
                            <FormGroup label="Footer About Us" required>
                                <Field as="textarea" placeholder="Footer About Us" name="footer_note" className='form-control' />
                                <ErrorField><ErrorMessage name="footer_note"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Facebook" required>
                                <Field placeholder="Facebook" name="facebook" className='form-control' />
                                <ErrorField><ErrorMessage name="facebook"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Linkedin" required>
                                <Field placeholder="Linkedin" name="linkedin" className='form-control' />
                                <ErrorField><ErrorMessage name="linkedin"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Instagram" required>
                                <Field placeholder="Instagram" name="instagram" className='form-control' />
                                <ErrorField><ErrorMessage name="instagram"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-4'>
                            <FormGroup label="Youtube" required>
                                <Field placeholder="Youtube" name="youtube" className='form-control' />
                                <ErrorField><ErrorMessage name="youtube"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Copyright" required>
                                <Field placeholder="Copyright" name="copywrite" className='form-control' />
                                <ErrorField><ErrorMessage name="copywrite"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="Lead Gen Heading" required>
                                <Field placeholder="Lead Gen Heading" name="leadGenText" className='form-control' />
                                <ErrorField><ErrorMessage name="leadGenText"/></ErrorField>
                            </FormGroup>
                        </div>

                        <div className='col-md-6'>
                            <FormGroup label="Google Iframe" required>
                                <Field placeholder="Google Iframe" name="google_iframe" className='form-control' />
                                <ErrorField><ErrorMessage name="google_iframe"/></ErrorField>
                            </FormGroup>
                        </div>
                        <div className='col-md-6'>
                            <FormGroup label="address" required>
                                <Field placeholder="address" name="address" className='form-control' />
                                <ErrorField><ErrorMessage name="address"/></ErrorField>
                            </FormGroup>
                        </div>
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik>}
            </DataView> 
        </ContentLayout>
    )
}
