import React from "react";
import CustLoader from "./CustLoader";
import isPDFUrl from "../../helpers/CheckPDF";
export default function UploadDoc({docUrl, name,uploadStatus,handleDocUpload}) {
  return (
    <>
      <div className="custom-file-upload-wrapper" style={{width:'100%'}}>
        <span>Choose File</span>
        <label htmlFor={name} className="custom-file-upload">
          {docUrl ? docUrl : "No File Chosen"}
        </label>
        <div className="custom-file-preview">
          {docUrl && (
            <a href={docUrl ? docUrl : ""} target="_blank">
              {uploadStatus === "Pending" && <CustLoader />}

              {!isPDFUrl(docUrl ? docUrl : "") ? (
                <img src={docUrl ? docUrl : ""} width={200} />
              ) : (
                <img
                  src="https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png"
                  width={40}
                />
              )}
            </a>
          )}
        </div>
      </div>
      <input
        id={name}
        type="file"
        name={name}
        onChange={(e) => handleDocUpload(e, name)}
        style={{ display: "none" }}
        accept=".jpg, .jpeg, .png, .webp, application/pdf"
      />
    </>
  );
}
