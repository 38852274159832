import React, {useEffect, useState} from 'react'
import Ajax from '../../../../../helpers/Ajax'
import TableLoader from '../../../../UI/Dashboard/TableLoader';
import DataView from '../../../../UI/Dashboard/DataView';
import TableContainer from '../../../../UI/Dashboard/TableContainer';
import { Helmet } from 'react-helmet-async';
import ContentLayout from '../../../../Common/ContentLayout';
import EditButton from '../../../../UI/Dashboard/EditButton';
import DeleteButton from '../../../../UI/Dashboard/DeleteButton';

export default function AmenityList() {
    const [dataList, setDataList] = useState(null)
    useEffect(()=>{
    const getResponse = setTimeout(function(){  
        (async()=>{ 
        const response = await Ajax({
            url: `/career/list?limit=false`,
            token: true,
        })
        console.log(response)
        if(response.data.status === "SUCCESS!"){
            setDataList(response.data.result)
        }
        })()
    },100) 
    return()=>{
        clearInterval(getResponse)
    }
    },[])

    return (
    <ContentLayout title='Job Opening' addNew='/admin/career/add-job'>
        <div className="row">
        <Helmet>
            <title>Job Opening</title>
        </Helmet>
        <DataView title="Job Opening">
            <TableContainer>
                <thead>
                    <tr>
                        <td className="text-center">S.No</td>
                        <td className="text-start">Title</td>
                        <td className="text-start">Location</td>
                        <td className="text-start">Description</td>
                        <td className="text-start">Icon</td>
                        <td className="text-center">Active</td>
                        <td className="text-center">Edit</td>
                        <td className="text-center">Delete</td>
                    </tr>
                </thead>
                <tbody>
                    {dataList == null ? 
                    <TableLoader cols={8}/> : dataList.length === 0 ? 
                    <tr>
                        <td colSpan={8} className="text-center">Data not available</td>
                    </tr> : dataList.map((data, index)=>(
                    <tr key={data._id}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-start text-capitalize">{data.position}</td>
                        <td className="text-start">{data.location}</td>
                        <td className="text-start">{data.description}</td>
                        <td className="text-start">
                        {data.file ? 
                        <a href={data.file.path} target="_blank">
                        <i className="fa fa-file-pdf fa-4x" aria-hidden="true"></i>
                        </a> : ''}
                           
                            </td>
                        <td className="text-center">{data.active === true ? <span className="badge bg-success">Enable</span> : <span className="badge bg-danger">Disable</span>}</td>
                        <td className="text-center">
                            <EditButton>{`/admin/career/job/${data._id}`}</EditButton>
                        </td>
                        <td className="text-center">
                            <DeleteButton url="/career" id={data._id} />
                        </td>
                    </tr>
                    ))}
                </tbody>
            </TableContainer>
        </DataView>
    </div>
    </ContentLayout>
    )
}
