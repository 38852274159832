import React from 'react'

export default function DataView({title, children}) {
  return (
    <div className="col-lg-12 col-md-12">
        <div className="card">
            <div className="card-header"><i className="fas fa-list"></i> {title}</div>
            <div className="card-body">
                {children}
            </div>
        </div>
    </div>
  )
}
