import React, {useState, useEffect, useRef} from 'react'
import DataView from '../../../../UI/Dashboard/DataView'
import Ajax from '../../../../../helpers/Ajax'
import { useParams } from 'react-router-dom'
import FormGroup from '../../../../UI/Form/FormGroup'
import ErrorField from '../../../../UI/Form/ErrorField'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import DataLoader from '../../../../UI/Dashboard/DataLoader'
import Button from '../../../../UI/Form/Button'
import ContentLayout from '../../../../Common/ContentLayout'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet-async'

export default function EditProjectArea() {
    const [infoDetail, setInfoDetail] = useState(null)
    const urlParams = useParams()

    useEffect(()=>{
        const getResponse = setTimeout(function(){
            (async()=>{
                const response = await Ajax({
                    url: `/project/ProjectArea?_id=${urlParams.ProjectAreaId}`,
                    token: true
                  })
                  if(response.data.status === "SUCCESS!"){
                    setInfoDetail(response.data.result[0])
                  } 
            })()
          },100)
      
          return()=>{
            clearInterval(getResponse)
          }
    },[])

    const updateHome = async (values, event) => {
        const data = {
            projectId: values.projectId,
            average: values.average, 
            area: values.area, 
            active: values.active == 1 ? true : false, 
        }
        // for(var key in data) {
        //     if(data[key] !== "") {
        //         formData.append(key, data[key])
        //     }
        //     if(data[key] === "") {
        //         toast.error('Some Fields are Empty please check',{
        //             toastId: 'EmptyFields'
        //         })
        //         return false
        //     }
        // }

        const update = await Ajax({
            url: `/project/ProjectArea/${urlParams.ProjectAreaId}`,
            method: 'PUT',
            token: true,
            data: data,
            loader: true
        })
        if(update.error){
            toast.error('Something Went Wrong Please Check')
        }
        if(update.data.status === "SUCCESS!"){
            toast.success('Project Area Updated Successfully')
            setTimeout(function(){
                window.location.reload()
            },500)
        }
       
    }

    return (
        <ContentLayout title="Edit Project Area">
            <Helmet>
                <title>Edit Project Area</title>
            </Helmet>
            <DataView title="Infomation">
            {infoDetail === null ? <DataLoader/> : 
                <Formik
                    initialValues={{ 
                        projectId: infoDetail.projectId ? infoDetail.projectId._id : '',
                        name: infoDetail.projectId ? infoDetail.projectId.name : '',
                        average: infoDetail.average ? infoDetail.average : '', 
                        area:infoDetail.area ? infoDetail.area : '',
                        active: infoDetail.active === true ? 1 : 0
                    }}

                    onSubmit={(values, event) => {
                        updateHome(values, event)
                    }}
                >
                <Form>
                    <ul className="nav nav-tabs">
                    <li className="nav-item"><a href="#tab-general" data-bs-toggle="tab" className="nav-link active">General</a></li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane active" id="tab-general">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <FormGroup label="Project Name" required>
                                        <Field placeholder='Project Name' name="name" className='form-control' />
                                        <ErrorField><ErrorMessage name="name"/></ErrorField>
                                    </FormGroup>
                                </div>

                                <div className='col-md-12'>
                                    <FormGroup label="Average (in Lakhs)" required>
                                        <Field placeholder='Average' name="average" className='form-control' />
                                        <ErrorField><ErrorMessage name="average"/></ErrorField>
                                    </FormGroup>
                                </div>

                                <div className='col-md-12'>
                                    <FormGroup label="Area (sq.ft)" required>
                                        <Field placeholder='Area' name="area" className='form-control' />
                                        <ErrorField><ErrorMessage name="area"/></ErrorField>
                                    </FormGroup>
                                </div>
                            </div>
                                
                            <FormGroup label="Active" required>
                                <Field as="select" type="number" name="active" className='form-select'>
                                    <option value={1}>Enable</option>
                                    <option value={0}>Disable</option>
                                </Field>
                                <ErrorField><ErrorMessage name="active"/></ErrorField>
                            </FormGroup>     
                        </div>                    
                    </div>
                    <div className='col-md-2 d-grid'>
                        <Button type="submit">Update</Button>
                    </div>
                </Form>
                </Formik> 
            }
            </DataView>
        </ContentLayout>
    )
}
