import React, { useContext, useState, useEffect } from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import PageRoutes from './components/Routes';
import FormLoader from './components/UI/Form/FormLoader';
import AuthContext from './store/auth';
import TitleContext from './store/title';
import TokenValid from './helpers/TokenValid';
import PreLoader from './components/UI/Dashboard/PreLoader';
import {useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import GetCookie from './helpers/GetCookie';
import Ajax from './helpers/Ajax';
import moment from 'moment/moment';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const auth = useContext(AuthContext)
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  useEffect(()=>{

    (async()=>{
      if(!GetCookie('token')){
        navigate('/')
        setIsLoading(true)
      }
      if(GetCookie('token')){
        const tokenVaid = await TokenValid()
        setIsLoggedIn(tokenVaid)
        auth.isLoggedIn = true
        setIsLoading(true)
        //navigate('/admin/dashboard')

        if(searchParams.get('code') && !GetCookie('houseeazy')){
          const getAccessToken = await Ajax({
            url: `/product/generateDocusignToken?token=${searchParams.get('code')}`,
            token: true
          })
          if(getAccessToken.data.status === "SUCCESS!"){
              const todays = new Date()
              todays.setHours(todays.getHours() + 8);
              document.cookie = `houseeazy=${getAccessToken.data.result.access_token};path=/;expires=${todays}`;
              navigate('/admin/dashboard')
          }
        //   const srtkey = btoa(`${process.env.REACT_APP_DOCUSIGN_INTEGRATION_KEY}:${process.env.REACT_APP_DOCUSIGN_SECRET_KEY}`)
        //   console.log(srtkey)
        //   console.log(searchParams.get('code'))
        //   var urlencoded = new URLSearchParams();
        //   urlencoded.append("code",searchParams.get('code'));
        //   urlencoded.append("grant_type", "authorization_code");
        //   axios({
        //     headers: {
        //         'Authorization': 'Basic '+srtkey+'',
        //         "Content-Type": "application/x-www-form-urlencoded",
        //         "Access-Control-Allow-Origin": "*",
        //         "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        //         'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE'
        //     },
        //     method: 'POST',
        //     url: 'https://cors-anywhere.herokuapp.com/https://account-d.docusign.com/oauth/token',
        //     //url: 'https://account-d.docusign.com/oauth/token',
        //     data: urlencoded,
        //     redirect: 'follow',
        //     mode: 'no-cors'
        //   })
        //   .then(function (response) {
        //     const today = new Date()
        //     const tomorrow = new Date()
        //     tomorrow.setDate(today.getDate() + Number(process.env.REACT_APP_SESSION_TIME))
        //     document.cookie = `houseeazy=${response.data.access_token};path=/;expires=${tomorrow}`;
        //     navigate('/admin/dashboard')
        //   })
        //   .catch(function (error) {
        //     if (error.response) {
        //       console.log(error.response)
        //     }
        // }); 
        }
      }
    })()
  },[])
  return (
    <div className="App">
      {isLoading ?
      <AuthContext.Provider
        value={{
          isLoggedIn: isLoggedIn,
          setLogin: setIsLoggedIn
        }}
      >
        <PageRoutes/>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          />
        <FormLoader/>
      </AuthContext.Provider> : <PreLoader/> }
    </div>
  );
}

export default App;
