import React from 'react'
import LogoImage from '../../assets/images/logo.png'
export default function AccountLayout({title, children}) {
  return (
    <div id="content">
        <div className="container-fluid pt-5">
            <div className="row justify-content-sm-center">
                <div className="col-sm-4 col-md-4">
                    <div className="text-center mb-4">
                        <img alt="logo" src={LogoImage} className="px-5 logo-brand" />
                    </div>
                    <div className="card">
                        <div className="card-header"><i className="fas fa-lock"></i> {title}</div>
                        <div className="card-body">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
