import React, {useRef} from 'react'

export default function Specification({data, target, action, label, value}) {
    const deleteRow = () => {
        let deleteConfirm = window.confirm("Are you sure want to delete ?")
        if(deleteConfirm === true) {
            action(current =>
                current.filter(data => {
                    return data.key !== target;
                })
            )
        }
    }
    return (
        <tr>
            <td className="text-start">
                <input type="text" name="specification_title" defaultValue={label ? label : ''} placeholder="Title" className="form-control" />
            </td>
            <td className="text-start">
                <div className="input-group">
                    <input type="text" name="specification_value" defaultValue={value ? value : ''} placeholder="Value" className="form-control" />
                </div>
            </td>
            <td className="text-end">
                <button type="button" onClick={deleteRow} className="btn btn-danger">
                    <i className="fas fa-minus-circle"></i>
                </button>
            </td>
        </tr>
    )
}
